// External
import React, { useEffect, useState } from "react";
import makeAnimated from "react-select/animated";
import CreatableSelect from "react-select/creatable";
// Internal
import styles from "./SelectField.module.scss";

const animatedComponents = makeAnimated();

/**
 * A select input component
 * @class
 * @property {object} options: The options that will be in the select menu. Should be a list of objects with 'label' and 'value' attributes
 * @property {object} defaultValue: One of the options in the options list (object with 'label' and 'value' attributes)
 * @property {boolean} selectMultiple: Whether users should be able to select multiple items
 * @property {function} onChange: A function that will be called when the user changes the selection. The value of the option will be returned.
 * @returns {React.ReactElement} Select Field
 *
 * @example
 * <SelectField options={options} selectMultiple={false} />
 */
export const SelectField = ({
  options,
  label,
  value,
  defaultValue,
  selectMultiple,
  onChange,
  placeholder,
  disable,
  isClearable,
  empty,
  noCreate,
  customStyles,
  height,
  onInputChange,
  isSearchable
}) => {
  const [newValue, setValue] = useState(value);
  const [newOptions, setOptions] = useState();

  useEffect(() => {
    if (options) {
      let temp = [...options];
      if (temp  && temp.length > 0) {
        if (!("label" in temp[0])) {
          for (let i = 0; i < temp.length; i++) {
            if (!("value" in temp[i]) && temp[i].name) {
              temp[i].label = temp[i].name;
              temp[i].value = temp[i].name;
            } else {
              temp[i].label = temp[i].name;
            }
          }
        }
        setOptions(temp);
      }
    }
  }, [options]);

  useEffect(() => setValue(value), [value]);

  const handleChange = (option) => {
    setValue(option);
    if (onChange) {
      onChange(option);
    }
    // console.log(val);
  };
  // console.log(newValue)

  return (
    <div className={styles.select}>
      {label && <div className={styles.label}>{label}</div>}

      <CreatableSelect
        styles={{
          control: (base) => ({
            ...base,
            border: 0,
            borderRadius: ".5em",
            // This line disable the blue border
            boxShadow: "none",
            cursor: "pointer",
            color: "#616565",
            minHeight: height ? height : "",
            height: height ? height : "",
          }),
          option: (styles) => ({
            ...styles,
            cursor: "pointer",
            // height: height ? height : "",
          }),
          singleValue: (provided) => ({
            ...provided,
            color: "#616565",
            // height: height ? height : "",
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            height: height ? height : "",
            padding: "0 6px",
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
            height: height ? height : "",
          }),
          // height: height ? height : "",
          ...customStyles,
        }}
        theme={(theme) => ({
          ...theme,
          cursor: "pointer",
          height: height ? height : "",
          colors: {
            ...theme.colors,
            primary25: "#EAEFF2",
            primary: "#7FCFD3",
          },
        })}
        className={styles.selectStyle}
        options={newOptions}
        value={!empty ? newValue : ""}
        defaultValue={defaultValue}
        isMulti={selectMultiple}
        onChange={handleChange}
        components={animatedComponents}
        placeholder={placeholder}
        isDisabled={disable}
        isClearable={isClearable}
        formatCreateLabel={noCreate ? () => undefined : undefined}
        onInputChange={onInputChange}
        isSearchable={isSearchable ? isSearchable : true}
        // createOptionPosition={"undefined"}
      />
    </div>
  );
};
