import React from "react";
import Modal from "components/ReactModal/ReactModal";
import styles from "./LogicAndFlow.module.scss";
import { QuestionLogic } from "components/QuestionConstruction/QuestionLogic/QuestionLogic";
import EditTags from "components/QuestionConstruction/EditTags/EditTags";
import Triggers from "components/QuestionConstruction/Triggers/Triggers";

function LogicAndFlow({
  allQuestions,
  show,
  setShow,
  saveQuestion,
  question,
  ref,
  showTags,
  setShowTags,
  showTriggers,
  setShowTriggers,
}) {
  return (
    <Modal
      show={show || showTags || showTriggers}
      onClose={() => {
        if (show) {
          setShow(false);
        }
        if (showTags) {
          setShowTags(false);
        }
        if (showTriggers) {
          setShowTriggers(false);
        }
      }}
      modalStyle={{
        width: "fit-content",
        padding: "1rem",
        borderRadius: "1rem",
        overflow: "visible",
      }}
      //   rightStyle
      dark
      ref={ref}
    >
      {show && (
        <QuestionLogic
          question={question}
          saveQuestion={saveQuestion}
          //   close={() => setShowLogic(false)}
          allQuestions={allQuestions}
          open={false}
        ></QuestionLogic>
      )}
      {showTags && (
        <EditTags
          question={question}
          saveQuestion={saveQuestion}
          allQuestions={allQuestions}
          addNew={showTags}
          setAddNew={setShowTags}
        />
      )}
      {showTriggers && (
        <Triggers
          question={question}
          saveQuestion={saveQuestion}
          // close={() => setShowTriggers(false)}
        />
      )}{" "}
    </Modal>
  );
}

export default LogicAndFlow;
