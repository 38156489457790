import styles from "../SettingsAccordion.module.scss";
import { HorizBarType, LineType } from "../../QuestionChart";
import { Label } from "components/layouts/Label/Label";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";
import { splitting } from "../SettingsAccordion";
import { getSortedArray } from "assets/functions/ArrayFunctions";
import TextEditorPlain from "components/inputs/input_fields/TextEditorPlain/TextEditorPlain";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import { generateAxisTitles } from "./SplitsNPivots";
import { genStatAxisTitles } from "./StatSplitsNPivots";
import { useState } from "react";
import { PaletteColorPicker } from "components/ColorPalette/EditColors/PaletteColorPicker";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import { fonts } from "assets/functions/Variables";
import NumberInputWithSlider from "components/NumberInputWithSlider/NumberInputWithSlider";
import Button from "components/Button/Button";
import ColorPickerContainer from "components/inputs/input_fields/ColorPicker/ColorPickerContainer";

export const Axes = ({
  viz,
  title,
  changeSettingsField,
  visible,
  setVisible,
  labels,
  updateViz,
}) => {
  const autoStyle = {
    color: "#616565",
    display: "flex",
    gap: "5px",
    alignItems: "center",
    cursor: "pointer",
  };

  function Auto({ onClick }) {
    return (
      <div style={autoStyle} onClick={onClick}>
        <i
          className="bi bi-arrow-counterclockwise"
          style={{ color: "#15bcc7" }}
        ></i>
        auto
      </div>
    );
  }

  const toggleAutoGenAxis = (val) => {
    let newViz = { ...viz };
    newViz.designSettings.autoGenAxisTitles = val;
    if (val) {
      if (newViz.dataMethod === "survey") {
        genStatAxisTitles(newViz);
      } else {
        generateAxisTitles(newViz);
      }
    }
    updateViz(newViz);
  };

  function onAxisLabelChange(axis, newLabel) {
    if (axis.origLabel && newLabel === axis.origLabel) {
      let copy = { ...viz.designSettings?.axisLabelReplacements };
      delete copy[axis.orig];
      changeSettingsField("axisLabelReplacements", copy);
    } else {
      let replacement = { ...viz.designSettings?.axisLabelReplacements };
      replacement[axis.orig] = newLabel;
      changeSettingsField("axisLabelReplacements", replacement);
    }
  }

  const hidingLabels =
    viz.type === HorizBarType
      ? viz.designSettings.hideYticks
      : viz.designSettings.hideXticks;

  return (
    <>
      <div
        key={viz.id}
        className={`${styles.header} ${visible ? styles.headervisible : ""}`}
        onClick={!visible ? setVisible : undefined}
      >
        <FlexRow
          onClick={visible ? setVisible : undefined}
          style={{ cursor: "pointer" }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
            {title}{" "}
            <i style={{ fontSize: "1.2em" }} className="bi-graph-up"></i>
          </div>
          <span className={styles.accordionicon}>
            <i className="bi bi-caret-left-fill"></i>
          </span>
        </FlexRow>

        {visible && (
          <>
            <CombinedInput
              select
              label="Axis Label Fonts"
              options={fonts}
              value={
                viz?.designSettings?.AxisLabelFont
                  ? fonts.find(
                      (v) => v.value === viz?.designSettings?.AxisLabelFont
                    )
                  : fonts.find((v) => v.value === "Poppins, sans-serif")
              }
              onChange={(v) => changeSettingsField("AxisLabelFont", v.value)}
              icon={"bi-chevron-down"}
            />

            <ColorPickerContainer
              defaultColor={viz?.designSettings?.AxisLabelFontColor}
              onChange={(val) => changeSettingsField("AxisLabelFontColor", val)}
              textColors
              title={"Label Font Color"}
              style={{
                backgroundColor: "transparent",
                padding: "0",
                margin: "0",
              }}
            />

            <div className={styles.setting}>
              {/* <div
                className={styles.label6}
                style={{
                  margin: "0",
                  width: "100%"
                }}
              >
                Axis Labels
              </div> */}
              <div className={styles.setting2}>
                <ToggleSwitch
                  startChecked={!viz.designSettings.hideXticks}
                  handleCheck={(val) => changeSettingsField("hideXticks", !val)}
                ></ToggleSwitch>
                <div
                  className={styles.label6}
                  style={{
                    margin: "0",
                  }}
                >
                  X Axis Labels
                </div>
                <Button
                  style={{ padding: "0 0px", minWidth: 30 }}
                  height={30}
                  width={30}
                  active={viz?.designSettings?.XAxisLabelFontWeight === "bold"}
                  onClick={() =>
                    viz?.designSettings?.XAxisLabelFontWeight === "bold"
                      ? changeSettingsField("XAxisLabelFontWeight", "")
                      : changeSettingsField("XAxisLabelFontWeight", "bold")
                  }
                >
                  <i
                    className="bi-type-bold"
                    style={{ fontSize: "1.1rem" }}
                  ></i>
                </Button>
              </div>
              <div className={styles.setting2}>
                <ToggleSwitch
                  startChecked={!viz.designSettings.hideYticks}
                  handleCheck={(val) => changeSettingsField("hideYticks", !val)}
                ></ToggleSwitch>
                <div
                  className={styles.label6}
                  style={{
                    margin: "0",
                  }}
                >
                  Y Axis Labels
                </div>
                <Button
                  style={{ padding: "0 0px", minWidth: 30 }}
                  height={30}
                  width={30}
                  active={viz?.designSettings?.YAxisLabelFontWeight === "bold"}
                  onClick={() =>
                    viz?.designSettings?.YAxisLabelFontWeight === "bold"
                      ? changeSettingsField("YAxisLabelFontWeight", "")
                      : changeSettingsField("YAxisLabelFontWeight", "bold")
                  }
                >
                  <i
                    className="bi-type-bold"
                    style={{ fontSize: "1.1rem" }}
                  ></i>
                </Button>
              </div>
            </div>

            {labels && !hidingLabels && (
              <div
                className={styles.setting}
                style={{ alignItems: "flex-start" }}
              >
                <div
                  className={styles.label6}
                  style={{
                    margin: "0",
                  }}
                >
                  Labels
                </div>

                {splitting(viz) && (
                  <div className={styles.segLabels}>
                    {getSortedArray(
                      labels.axisLabels,
                      (a, b) => a.sorted - b.sorted
                    ).map((seg) => (
                      <SegLabel
                        seg={seg}
                        onTextChange={onAxisLabelChange}
                        viz={viz}
                      />
                    ))}
                  </div>
                )}

                {!splitting(viz) && (
                  <SegLabelEditor
                    viz={viz}
                    changeSettingsField={changeSettingsField}
                    labels={labels}
                  />
                )}
              </div>
            )}
            <CombinedInput
              select
              label="Axis Title Fonts"
              options={fonts}
              value={
                viz?.designSettings?.AxisTitleFont
                  ? fonts.find(
                      (v) => v.value === viz?.designSettings?.AxisTitleFont
                    )
                  : fonts.find((v) => v.value === "Poppins, sans-serif")
              }
              onChange={(v) => changeSettingsField("AxisTitleFont", v.value)}
              icon={"bi-chevron-down"}
            />
            <ColorPickerContainer
              defaultColor={viz?.designSettings?.AxisTitleFontColor}
              onChange={(val) => changeSettingsField("AxisTitleFontColor", val)}
              textColors
              title={"Title Font Color"}
              style={{
                backgroundColor: "transparent",
                padding: "0",
                margin: "0",
              }}
            />

            <div className={styles.setting}>
              <div className={styles.setting2}>
                <ToggleSwitch
                  startChecked={viz.designSettings.hasAxisTitles}
                  handleCheck={(val) =>
                    changeSettingsField("hasAxisTitles", val)
                  }
                ></ToggleSwitch>
                <div
                  className={styles.label6}
                  style={{
                    margin: "0",
                  }}
                >
                  Axis Titles
                </div>
              </div>
              {viz.designSettings.hasAxisTitles && (
                <>
                  <div className={styles.setting2}>
                    <ToggleSwitch
                      startChecked={viz.designSettings.autoGenAxisTitles}
                      handleCheck={toggleAutoGenAxis}
                    ></ToggleSwitch>
                    <div
                      className={styles.label6}
                      style={{
                        margin: "0",
                      }}
                    >
                      Auto-Generate
                    </div>
                  </div>

                  <div
                    className={styles.setting2}
                    style={{ paddingTop: "5px" }}
                  >
                    <div
                      className={styles.label6}
                      style={{
                        width: "fit-content",
                      }}
                    >
                      X:
                    </div>
                    <div className={styles.segLabelContainer}>
                      <TextEditorPlain
                        text={viz.designSettings.XAxisTitle}
                        onSave={(val) => changeSettingsField("XAxisTitle", val)}
                        onEnter={(e) => e.target.blur()}
                        editable
                        extraClass={styles.segLabelEditor}
                      />
                    </div>
                    <Button
                      style={{ padding: "0 0px", minWidth: 30 }}
                      height={30}
                      width={30}
                      active={
                        viz?.designSettings?.XAxisTitleFontWeight === "bold"
                      }
                      onClick={() =>
                        viz?.designSettings?.XAxisTitleFontWeight === "bold"
                          ? changeSettingsField("XAxisTitleFontWeight", "")
                          : changeSettingsField("XAxisTitleFontWeight", "bold")
                      }
                    >
                      <i
                        className="bi-type-bold"
                        style={{ fontSize: "1.1rem" }}
                      ></i>
                    </Button>
                  </div>
                  <div className={styles.setting2}>
                    <div
                      className={styles.label6}
                      style={{
                        width: "fit-content",
                      }}
                    >
                      Y:
                    </div>
                    <div className={styles.segLabelContainer}>
                      <TextEditorPlain
                        text={viz.designSettings.YAxisTitle}
                        onSave={(val) => changeSettingsField("YAxisTitle", val)}
                        onEnter={(e) => e.target.blur()}
                        editable
                        extraClass={styles.segLabelEditor}
                      />
                    </div>
                    <Button
                      style={{ padding: "0 0px", minWidth: 30 }}
                      height={30}
                      width={30}
                      active={
                        viz?.designSettings?.YAxisTitleFontWeight === "bold"
                      }
                      onClick={() =>
                        viz?.designSettings?.YAxisTitleFontWeight === "bold"
                          ? changeSettingsField("YAxisTitleFontWeight", "")
                          : changeSettingsField("YAxisTitleFontWeight", "bold")
                      }
                    >
                      <i
                        className="bi-type-bold"
                        style={{ fontSize: "1.1rem" }}
                      ></i>
                    </Button>
                  </div>

                  {/* <div
                    className={styles.setting}
                    style={{ paddingBottom: "20px" }}
                  >
                    <Label
                      italics
                      style={{
                        fontWeight: "500",
                        width: "fit-content",
                        fontSize: ".9em",
                      }}
                    >
                      Font Size{" "}
                    </Label>
                    <NumberInput
                      startNumber={viz.designSettings.axisTitleFontSize}
                      handleNumberChange={(val) =>
                        changeSettingsField("axisLabelTitleSize", val)
                      }
                    ></NumberInput>
                  </div> */}
                </>
              )}
            </div>

            <div className={styles.setting}>
              <Label
                style={{
                  fontSize: ".9em",
                  padding: "0px 0px 5px 0px",
                }}
              >
                Lines
              </Label>

              <div className={styles.setting2}>
                <ToggleSwitch
                  startChecked={!viz.designSettings.hideBorder}
                  handleCheck={(val) => changeSettingsField("hideBorder", !val)}
                ></ToggleSwitch>
                <Label
                  style={{
                    fontWeight: "400",
                    width: "fit-content",
                    fontSize: ".8em",
                    whiteSpace: "nowrap",
                  }}
                >
                  Axis Border
                </Label>
              </div>
              <div className={styles.setting2}>
                <ToggleSwitch
                  startChecked={viz.designSettings.hideTicks}
                  handleCheck={(val) => changeSettingsField("hideTicks", val)}
                ></ToggleSwitch>
                <Label
                  style={{
                    fontWeight: "400",
                    width: "fit-content",
                    fontSize: ".8em",
                    whiteSpace: "nowrap",
                  }}
                >
                  Hide All Lines
                </Label>
              </div>

              {!viz.designSettings.hideTicks && (
                <>
                  <div
                    className={styles.setting2}
                    style={{ paddingLeft: "10px", marginTop: "5px" }}
                  >
                    <ToggleSwitch
                      startChecked={viz.designSettings.drawXLines}
                      handleCheck={(val) =>
                        changeSettingsField("drawXLines", val)
                      }
                    ></ToggleSwitch>
                    <Label
                      italics
                      style={{
                        fontWeight: "400",
                        width: "fit-content",
                        fontSize: ".8em",
                        whiteSpace: "nowrap",
                      }}
                    >
                      X Axis
                    </Label>
                  </div>
                  <div
                    className={styles.setting2}
                    style={{ paddingLeft: "10px" }}
                  >
                    <ToggleSwitch
                      startChecked={viz.designSettings.drawYLines}
                      handleCheck={(val) =>
                        changeSettingsField("drawYLines", val)
                      }
                    ></ToggleSwitch>
                    <Label
                      italics
                      style={{
                        fontWeight: "400",
                        width: "fit-content",
                        fontSize: ".8em",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Y Axis
                    </Label>
                  </div>
                  <div
                    className={styles.setting2}
                    style={{ marginTop: "5px", paddingLeft: "10px" }}
                  >
                    <div className={styles.inputContainer}>
                      <NumberInput
                        startNumber={viz.designSettings.lineWidth}
                        handleNumberChange={(val) =>
                          changeSettingsField("lineWidth", val)
                        }
                        min={1}
                      ></NumberInput>
                    </div>
                    <Label
                      italics
                      style={{
                        fontWeight: "400",
                        width: "fit-content",
                        fontSize: ".8em",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Line Width
                    </Label>
                  </div>
                </>
              )}
            </div>

            <div className={styles.settingsComponent}>
              <Label
                style={{
                  width: "fit-content",
                  fontSize: ".8em",
                  whiteSpace: "nowrap",
                  padding: "5px 0px",
                }}
              >
                {`${viz.type === HorizBarType ? "X" : "Y"} Axis Scale`}
              </Label>
              <div
                className={styles.settingContainer}
                style={{ paddingRight: "10px" }}
              >
                <div className={styles.setting}>
                  <div
                    className={styles.setting2}
                    style={{ width: "fit-content" }}
                  >
                    <Label
                      italics
                      style={{
                        fontWeight: "400",
                        width: "fit-content",
                        fontSize: ".8em",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Min{" "}
                    </Label>
                    <div className={styles.inputContainer}>
                      <NumberInput
                        startNumber={viz.designSettings.graphMin}
                        onSave={(val) => changeSettingsField("graphMin", val)}
                        max={
                          viz.designSettings.graphMax
                            ? viz.designSettings.graphMax - 1
                            : undefined
                        }
                        min={
                          viz.designSettings.answerType === "nps score"
                            ? -100
                            : 0
                        }
                      ></NumberInput>
                    </div>
                  </div>
                  {viz.designSettings.graphMin !== null && (
                    <Auto
                      onClick={() => changeSettingsField("graphMin", null)}
                    />
                  )}
                </div>

                <div className={styles.setting}>
                  <div
                    className={styles.setting2}
                    style={{ width: "fit-content" }}
                  >
                    <Label
                      italics
                      style={{
                        fontWeight: "400",
                        width: "fit-content",
                        fontSize: ".8em",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Max{" "}
                    </Label>
                    <div className={styles.inputContainer}>
                      <NumberInput
                        startNumber={viz.designSettings.graphMax}
                        onSave={(val) => changeSettingsField("graphMax", val)}
                        min={
                          viz.designSettings.graphMin
                            ? viz.designSettings.graphMin + 1
                            : undefined
                        }
                      ></NumberInput>
                    </div>
                  </div>
                  {viz.designSettings.graphMax !== null && (
                    <Auto
                      onClick={() => changeSettingsField("graphMax", null)}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export function SegLabelEditor({ viz, changeSettingsField, labels }) {
  const [wasPalette, setWasPalette] = useState(
    !viz.designSettings.colors && !viz.designSettings.gradient
  );

  function editText(seg, newLabel) {
    if (seg.origLabel && newLabel === seg.origLabel) {
      let copy = { ...viz.designSettings?.segLabelReplacements };
      delete copy[seg.orig];
      changeSettingsField("segLabelReplacements", copy);
    } else {
      let replacement = { ...viz.designSettings?.segLabelReplacements };
      replacement[seg.orig] = newLabel;
      changeSettingsField("segLabelReplacements", replacement);
    }
  }

  function changeSingle(seg, val) {
    let origOrder = getSortedArray(labels.segLabels, (a, b) => a.orig - b.orig);
    let newColors = origOrder.map((seg) => seg.color);
    newColors[seg.orig] = val;
    changeSettingsField("colors", newColors);
  }

  function applySingle() {
    if (viz.designSettings.gradient) {
      changeSettingsField("gradient", undefined);
      // officially remove the gradient.
    }
    setWasPalette(false);
  }

  function cancelSingle(seg, orig) {
    if (wasPalette || viz.designSettings.gradient) {
      changeSettingsField("colors", undefined);
    } else {
      changeSingle(seg, orig);
    }
  }

  return (
    <div className={styles.segLabels}>
      {getSortedArray(labels.segLabels, (a, b) => a.sorted - b.sorted).map(
        (seg) => (
          <SegLabel
            seg={seg}
            onTextChange={editText}
            viz={viz}
            onColorChange={changeSingle}
            onApply={applySingle}
            onCancel={cancelSingle}
          />
        )
      )}
    </div>
  );
}

export function SegLabel({
  onTextChange,
  seg,
  viz,
  onColorChange,
  onApply,
  onCancel,
}) {
  const [orig, setOrig] = useState(seg.color);

  function sortingConflict() {
    let sort = viz.designSettings.sortData;
    if (sort == AtoZ || sort == ZtoA) {
      return true;
    }
    return false;
  }

  function startTextChange(val) {
    if (val !== seg.label) {
      onTextChange(seg, val);
    }
    if (!val && seg.origLabel) {
      // If they delete a previously changed label, go back to the original in the placeholder
      onTextChange(seg, seg.origLabel);
    }
  }

  function handleCancel() {
    onCancel(seg, orig);
  }

  function onConfirm() {
    setOrig(seg.color);
    onApply();
  }

  return (
    <div className={styles.segLabelContainer}>
      {seg.color &&
        !(
          viz.type === LineType &&
          viz.designSettings.hasUniformPointColor &&
          !splitting(viz)
        ) && (
          <PaletteColorPicker
            color={seg.color}
            orig={orig}
            onChange={(color) => onColorChange(seg, color)}
            onCancel={handleCancel}
            onApply={onConfirm}
          />
        )}
      <TextEditorPlain
        text={seg.label}
        onSave={startTextChange}
        onEnter={(e) => e.target.blur()}
        editable
        extraClass={styles.segLabelEditor}
      />
    </div>
  );

  // {
  //   sortingConflict() && (
  //     <div className={styles.sortWarning}>May be sorted differently</div>
  //   );
  // }
}
