/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

import { useQueryClient } from "react-query";
import { gql } from "graphql-request";

import { useGqlQuery, useGqlMutation } from "api/Api";

// GRAPHQL API REACTION SERVER localhost:4000/graphql

export const useFetchQuestionsGql = (projectId) => {
  const query = gql`
    query {
      QuestionByProjectID(projectId: "${projectId}") {
        id
        questionText
        type
        createdAt
        updatedAt
        projectId
        description
        isScore
        hasBuckets
        buckets
        responses
        choiceQuestion {
          id
          isMultiSelect
          isRandomized
          hasOtherOption
          otherOptionLabel
          isRanking
          limit
          choices
        }
        scaleQuestion {
          id
          min
          minDescription
          max
          maxDescription
          step
        }
        textQuestion {
          id
          bucket {
            id
            name
            properties
          }
        }
        matrixQuestion {
          id
          options
          type
        }
      }
    }
  `;
  return useGqlQuery(["questions in project:" + projectId], query, {});
};

export const useFetchQuestionsSimpleGql = (projectId) => {
  const query = gql`
    query {
      questions: QuestionByProjectID(projectId: "${projectId}") {
        id
        questionText
        type
        pageOrderIndex
        pageNumber
        conditionGroups
        triggers
        choiceQuestion {
          id
          isMultiSelect
          isRandomized
          hasOtherOption
          otherOptionLabel
          isRanking
          choices
        }
        scaleQuestion {
          id
          min
          minDescription
          max
          maxDescription
          step
        }
        textQuestion {
          id
          placeholder
        }

      }
    }
  `;
  return useGqlQuery(["projects", projectId, "questions"], query, {});
};

export const useFetchQuestionsCountGql = (projectId) => {
  const query = gql`
    query {
      count: QuestionByProjectIDCount(projectId: "${projectId}")
    }
  `;
  return useGqlQuery(
    ["projectsCount:" + projectId, projectId, "questions"],
    query
  );
};

export const useHasQuestionBeenAnswered = (questionId) => {
  const query = gql`
    query {
      doesQuestionHaveAnswers(id: "${questionId}")
    }
  `;
  return useGqlQuery(["answered at all? " + questionId], query);
};

export const useGetQuestionsMutationGql = () => {
  const mutation = gql`
    mutation Mutation(
      $projectId: String!
      $pageNumber: Float!
      $getAll: Boolean!
    ) {
      QuestionByProjectPage(
        projectId: $projectId
        pageNumber: $pageNumber
        getAll: $getAll
      ) {
        id
        questionText
        description
        pageOrderIndex
        pageNumber
        type
        description
        encoding
        descriptionEncoding
        buckets
        hasBuckets
        choiceQuestion {
          id
          isMultiSelect
          isRandomized
          hasOtherOption
          otherOptionLabel
          isRanking
          choices
        }
        scaleQuestion {
          id
          min
          minDescription
          max
          maxDescription
          step
        }
        textQuestion {
          id
          placeholder
          maxLength
        }
        answer {
          id
          buckets
        }
        buckets
        hasBuckets
        questionText
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("questions");
    },
    // onSuccess: (data) => {
    //   console.log("Heck Yeah!");
    //   console.log(data);
    // },
  };

  return useGqlMutation(mutation, options);
};

export const useGetQsAndSections = () => {
  const mutation = gql`
    mutation QsAndSectionsInSurvey($projectId: String!) {
      QsAndSectionsInSurvey(projectId: $projectId) {
        organizationId
        sections {
          id
          bodyText
          encoding
          headerText
          pageOrderIndex
          pageNumber
          type
          sectionSettings
          sectionFields
          conditionGroups
        }
        questions {
          id
          questionText
          description
          pageOrderIndex
          pageNumber
          type
          description
          encoding
          descriptionEncoding
          buckets
          hasBuckets
          conditionGroups
          triggers
          isComment
          isScore
          choiceQuestion {
            id
            isMultiSelect
            isRandomized
            hasOtherOption
            otherOptionLabel
            isRanking
            choices
            limit
          }
          scaleQuestion {
            id
            min
            minDescription
            max
            maxDescription
            step
          }
          textQuestion {
            id
            placeholder
            maxLength
          }
          matrixQuestion {
            id
            options
            heading
            type
            settings
            paired
            pairedWith
          }
          buckets
          hasBuckets
          questionText
          index
        }
      }
    }
  `;

  return useGqlMutation(mutation);
};

export const useGetQsAndSectionsForSurveyBuilder = () => {
  const mutation = gql`
    mutation QsAndSectionsInSurvey($projectId: String!) {
      QsAndSectionsInSurvey(projectId: $projectId) {
        organizationId
        sections {
          id
          bodyText
          encoding
          headerText
          pageOrderIndex
          pageNumber
          type
          sectionSettings
          sectionFields
          conditionGroups
        }
        questions {
          id
          questionText
          description
          pageOrderIndex
          pageNumber
          type
          description
          encoding
          descriptionEncoding
          buckets
          hasBuckets
          conditionGroups
          triggers
          isComment
          isScore
          choiceQuestion {
            id
            isMultiSelect
            isRandomized
            hasOtherOption
            otherOptionLabel
            isRanking
            choices
            limit
          }
          scaleQuestion {
            id
            min
            minDescription
            max
            maxDescription
            step
          }
          textQuestion {
            id
            placeholder
            maxLength
            autoBucket
            bucket {
              id
              properties
              name
            }
          }
          matrixQuestion {
            id
            options
            heading
            type
            settings
            paired
            pairedWith
          }
          answer {
            id
            buckets
          }
          questionTag {
            color
            description
            id
            name
          }
          buckets
          hasBuckets
          questionText
          index
        }
      }
    }
  `;

  return useGqlMutation(mutation);
};

export const useFetchQuestionsMutationQuery = () => {
  const mutation = gql`
    query QuestionByProjectID($projectId: String!) {
      Qs: QuestionByProjectID(projectId: $projectId) {
        id
        questionText
        type
        createdAt
        updatedAt
        projectId
        description
        isScore
        hasBuckets
        buckets
        choiceQuestion {
          id
          isMultiSelect
          isRandomized
          hasOtherOption
          otherOptionLabel
          isRanking
          limit
          choices
        }
        scaleQuestion {
          id
          min
          minDescription
          max
          maxDescription
          step
        }
        textQuestion {
          id
          bucket {
            id
            name
            properties
          }
        }
        matrixQuestion {
          id
          options
          type
        }
      }
    }
  `;
  return useGqlMutation(mutation);
};

export const useMovePages = () => {
  const mutation = gql`
    mutation MovePages(
      $overPageNum: Float!
      $activePageNum: Float!
      $projectId: String!
    ) {
      movePages(
        overPageNum: $overPageNum
        activePageNum: $activePageNum
        projectId: $projectId
      )
    }
  `;

  return useGqlMutation(mutation);
};

export const useCreateQuestionGql = () => {
  const mutation = gql`
    mutation Mutation($data: QuestionInput!) {
      createQuestion(data: $data) {
        id
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("questionByPage");
    },
    // onSuccess: (data) => {
    //   console.log("Heck Yeah!");
    //   console.log(data);
    // },
  };

  return useGqlMutation(mutation, options);
};

export const useDeleteQuestion = () => {
  const mutation = gql`
    mutation Mutation($id: String!) {
      deleteQuestion(id: $id)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("questions");
    },
    // onSuccess: (data) => {
    //   console.log("Heck Yeah!");
    //   console.log(data);
    // },
  };

  return useGqlMutation(mutation, options);
};

export const useFetchQuestionCount = (id) => {
  const query = gql`
  query {
    countQuestions(id: "${id}")
  }
  `;
  return useGqlQuery(["projects", id, "contQuestions"], query, {});
};

export const useUpdateQuestionNAOption = () => {
  const mutation = gql`
    mutation UpdateQuestion($id: ID!, $naOption: Boolean) {
      updateQuestion(id: $id, naOption: $naOption) {
        id
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("questions");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useUpdateQuestionGql = () => {
  const mutation = gql`
    mutation Mutation($id: String!, $data: QuestionInput!) {
      updateQuestion(id: $id, data: $data) {
        questionText
        description
        encoding
        updatedAt
      }
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("questions");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useDeletePageGql = () => {
  const mutation = gql`
    mutation Mutation(
      $projectId: String!
      $pageNumber: Float!
      $designSettings: String!
    ) {
      deletePage(
        projectId: $projectId
        pageNumber: $pageNumber
        designSettings: $designSettings
      ) {
        id
      }
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("questions");
    },
  };

  return useGqlMutation(mutation, options);
};

export const updateQsChangeType = () => {
  const mutation = gql`
    mutation Mutation(
      $commentQId: String!
      $scoreQId: String!
      $surveyId: String!
    ) {
      updated: updateQsChangeType(
        commentQId: $commentQId
        scoreQId: $scoreQId
        surveyId: $surveyId
      )
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("questions");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useUpdateTextQ = () => {
  const mutation = gql`
    mutation Mutation(
      $data: TextQuestionUpdate!
      $id: String!
    ) {
      updateTextQuestion(data: $data, id: $id) {
        id
        autoBucket
      }
    }
  `;

  return useGqlMutation(mutation);
};
