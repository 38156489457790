import React, { useState } from "react";

import { Loading } from "components/Loading/Loading";
import styles from "./EditLogo.module.scss";
import { useGetProjectLogo } from "api/resources/projects/images";
import { SurveyLogo } from "components/Survey/SurveyLogo";
import {
  useCreateProjectImage,
  useGetSignedURL,
} from "api/resources/projects/images";
import axios from "axios";
import EditableLogo from "./EditableLogo";

export default function EditLogo({
  settings,
  setSettings,
  projId,
  active,
  setActive,
  close,
}) {
  const getProjectLogo = useGetProjectLogo(projId);

  const getSignedURL = useGetSignedURL();
  const createImage = useCreateProjectImage();

  function checkForClickOutside(e) {
    let container = document.getElementById("editLogo");
    if (container && !container.contains(e.target)) {
      let root = document.getElementById("root");
      if (root && !root.contains(e.target)) {
        // It's a color picker - or some modal like it
        return;
      }

      document.removeEventListener("click", checkForClickOutside, true);
      // setActive("");
      close();
    }
  }

  function onClick(e) {
    document.addEventListener("click", checkForClickOutside, true);
    setActive("editLogo");
  }

  function onSaveNewImage(file) {
    getSignedURL.mutate(
      {
        filename: file.name,
        filetype: file.type,
      },
      {
        onSuccess: async (data) => {
          await uploadToS3(file, data.getSignedURL.signedRequest);
          createImage.mutate(
            {
              url: data.getSignedURL.url,
              projectId: projId,
            },
            {
              onSuccess: async (data) => {
                console.log(data);
                getProjectLogo.refetch();
                // let copy = { ...settings };  // Does it work okay? / Make it re-render  - maybe do a re-draw
                // copy.logoSize = 50;
                // setSettings(copy);
              },
            }
          );
        },
      }
    );
  }

  async function uploadToS3(file, signedRequest) {
    const options = {
      headers: {
        "Content-Type": file.type,
      },
    };
    const instance = axios.create({
      baseURL: "https://reaction.health/",
      headers: { "Access-Control-Allow-Origin": "*" },
    });
    instance.defaults.withCredentials = true;
    let res = await instance.put(signedRequest, file, options);

    console.log(res);
  }

  function combFileName(fileName) {
    let index = fileName?.lastIndexOf("\\");
    if (index == -1) {
      index = fileName?.lastIndexOf("/");
    }
    let shortName = fileName?.substring(index + 1);
    if (shortName?.length > 15) {
      shortName = shorten(shortName);
    }
    return shortName;
  }

  function shorten(fileName) {
    let dotInd = fileName.lastIndexOf(".");
    if (dotInd == -1) {
      return fileName.substring(0, 15) + "...";
    }
    let suffix = fileName.substring(dotInd);
    fileName = fileName.substring(0, 15);
    return fileName + "..." + suffix;
  }

  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      {getProjectLogo.isLoading && <Loading />}
      {getProjectLogo.isError && <div>Error...</div>}
      {getProjectLogo.isSuccess && (
        <div
          className={`${styles.logoContainer} ${
            active ? styles.activeLogoContainer : ""
          }`}
          // onClick={active ? null : onClick}
          style={{
            // cursor: !active ? "pointer" : "",
            // border:
            //   active || isHovered
            //     ? "2px solid #15bcc7"
            //     : "2px solid " + settings.logoBackgroundColor
            //     ? "2px solid " + settings.logoBackgroundColor
            //     : "2px solid #f9fbfc",
            boxShadow: settings?.logoBoxShadow ? settings?.logoBoxShadow : "",
            borderRadius: settings?.logoRadius ? settings?.logoRadius + "rem" : "",
          }}
          id="editLogo"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <SurveyLogo
            settings={settings}
            source={
              getProjectLogo.data.getProjectLogo != null
                ? getProjectLogo.data.getProjectLogo.imageURL
                : "/static/media/reactionlogodark.7d895fefebc952458ad6.png"
            }
            edit
          />

          {/* {active && (
            <EditableLogo
              settings={settings}
              source={
                getProjectLogo.data.getProjectLogo != null
                  ? getProjectLogo.data.getProjectLogo.imageURL
                  : "/static/media/reactionlogodark.7d895fefebc952458ad6.png"
              }
              setSettings={setSettings}
              projectLogo={
                getProjectLogo.data.getProjectLogo != null
                  ? getProjectLogo.data.getProjectLogo
                  : "/static/media/reactionlogodark.7d895fefebc952458ad6.png"
              }
              // rightCorner,
              onSaveNewImage={onSaveNewImage}
            ></EditableLogo>
          )} */}

          {/* {active && (
            <div className={styles.logoSettings}>
              <SurveyLogoSettings
                settings={settings}
                setSettings={setSettings}
              ></SurveyLogoSettings>
            </div>
          )} */}
        </div>
      )}
    </>
  );
}
