/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2024 ReactionData. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson

*/
import React, { useState, useRef, useEffect } from "react";
import styles from "./ContactTable.module.scss";
import Button from "components/Button/Button";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";
import { Loading } from "components/Loading/Loading";
import { BasicTable } from "components/tables/BasicTable/BasicTable";
import { PaginationContainer } from "components/tables/BasicTable/PaginationContainer";
import { Pagination } from "components/tables/BasicTable/Pagination";
import { TabPill } from "components/layouts";
import Modal from "components/ReactModal/ReactModal";
import { ContactCleaner } from "components/ContactCleaner2.0/ContactCleaner";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import { Manual } from "./NewContacts/manual/Manual";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import Filters from "./Filters";
import {
  useUpdateContactGql,
  usefetchContactsFilters,
} from "api/resources/contacts/contacts";
import FieldAdjuster from "./FieldAdjuster";
import salesforce from "assets/images/Salesforce.png";
import ExternalImport from "../ExternalImport/ExternalImport";
import { parseCustomFields } from "assets/functions/ObjectFunctions";
import { Player } from "@lottiefiles/react-lottie-player";
import ContactDetails from "components/Popout/ContactProfile";
import {
  useCancelSelectedSends,
  useSearchDeliveries,
} from "api/resources/projects/deliveries";
import axios from "axios";
import { forEach } from "assets/functions/ArrayFunctions";
import { useNavigate } from "react-router-dom";
import { useRemoveContactsFromAudience } from "api/resources/contacts/audiences";
import { shortId } from "components/tables/EditableTable/utils";


function ContactTable({
  headers,
  setHeaders,
  onRowClick,
  sort,
  setSort,
  currPage,
  setCurrPage,
  perPage,
  setPerPage,
  maxDataQuery,
  maxDataQueryAccessor,
  pageSkip,
  setPageSkip,
  searchString,
  setSearchString,
  searchPlaceholder,
  user,
  hideSearchButton,
  emptyMessage,
  chosenFilters,
  setChosenFilters,
  orgId,
  organization,
  audienceId,
  external,
  editFields,
  setEditFields,
  customFields,
  roles,
  audience,
  updateAudience,
  saveAudience,
  hidebuttons,
  delivery,
  email,
  active,
  setActive,
  setExternalImporting,
  externalImporting,
  externalType,
  setExternalType,
}) {
  const [imports, setShowImports] = useState(false);
  const [exports, setShowExports] = useState(false);
  const [updates, setShowUpdates] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectType, setSelectType] = useState(false);
  const [importFile, setImportFile] = useState(false);
  const [importExternal, setExternal] = useState(false);
  const [externalMax, setExternalMax] = useState(0);
  const [sortBy, setSortBy] = useState(false);
  const [showSelected, setShowSelected] = useState(false);
  const [adjustFields, setAdjustFields] = useState(false);
  const [changedContacts, setChangedContacts] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [data, setData] = useState();
  const [refetch, setRefetch] = useState(false);
  const [doSelected, setDoSelected] = useState(false);
  const [manualEntry, setManualEntry] = useState(false);
  const [showDynamic, setShowDynamic] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [showColumns, setShowColumns] = useState(false);
  useEffect(() => {
    if (external && (importFile || doSelected)) {
      setExternalImporting(importFile || doSelected);
    }
  }, [importFile, doSelected]);

  useEffect(() => {
    if (importFile) {
      setImportFile(externalImporting);
      // setDoSelected(externalImporting);
    }
    if (doSelected) {
      setDoSelected(externalImporting);
    }
  }, [externalImporting]);

  function onSearch(val) {
    setSearchString(val);
    if (currPage !== 1) {
      setCurrPage(1);
    }
    if (pageSkip) {
      setPageSkip(0);
    }
  }

  const options = !delivery
    ? [
        { value: "createdAt", name: "When Added" },
        // { value: "updatedAt", name: "Last Modified" },
        { value: "email", name: "Email" },
        { value: "firstName", name: "First Name" },
        { value: "lastName", name: "Last Name" },
        { value: "unsubscribed", name: "Unsubscribed" },
        { value: "participationCount", name: "Survey Invites" },
      ]
    : [
        { value: "date", name: "Date" },
        { value: "status", name: "Status" },
        { value: "email", name: "Email" },
        { value: "firstName", name: "First Name" },
        { value: "lastName", name: "Last Name" },
      ];

  const selectedOptions = delivery
    ? [
        { value: "selectpage", name: "Select Page" },
        { value: "removeselected", name: "Remove Selected" },
        { value: "cancel", name: "Cancel Send" },
      ]
    : audienceId
    ? [
        { value: "selectpage", name: "Select Page" },
        { value: "removeselected", name: "Remove Selected" },
        {
          value: "removeselectedfromaudience",
          name: "Remove Selected From Audience",
        },
      ]
    : [
        { value: "selectpage", name: "Select Page" },
        { value: "removeselected", name: "Remove Selected" },
      ];
  function handleSelect(item, val) {
    let items = [...selected];
    if (val) {
      items.push(item);
    } else {
      let index = items.findIndex((c) => c.id === item?.id);
      items.splice(index, 1);
    }

    setSelected(items);
  }

  function handleSelectPage() {
    let toadd = [];
    let firstIndex = 100;
    for (let c of data) {
      let index = selected.findIndex((i) => i.id === c.id);
      if (index < 0) {
        toadd.push(c);
      } else if (index < firstIndex) {
        firstIndex = index;
      }
    }
    let items = [...selected, ...toadd];
    if (toadd.length === 0) {
      items.splice(firstIndex, data.length);
    }

    setSelected(items);
  }

  function handleCancelSelectedSends() {
    let items = JSON.stringify(selected.map((item) => item.id));
    cancelDeliveries.mutate(
      {
        deliveries: items,
        distId: email?.id,
      },
      {
        onSuccess: () => {
          setRefetch(!refetch);
          setSelected([]);
        },
      }
    );
  }

  const removeContacts = useRemoveContactsFromAudience();

  function handleRemoveSelectedFromAudience() {
    if (selected.length > 0) {
      removeContacts.mutate(
        {
          id: audienceId,
          contactArray: {
            contactIds: selected.map((c) => {
              return c?.id;
            }),
          },
        },
        {
          onSuccess: () => {
            setRefetch(!refetch);
            setSelected([]);
          },
        }
      );
    }
  }

  function clickOutside(e) {
    if (ref.current && !ref.current?.contains(e.target)) {
      setShowImports(false);
      setShowExports(false);
      setShowUpdates(false);
      setSortBy(false);
      setSelectType(false);
      setShowSelected(false);
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  }, []);

  const ref = useRef(null);

  const fetchContacts = usefetchContactsFilters();
  const searchDeliveries = useSearchDeliveries();
  const cancelDeliveries = useCancelSelectedSends();

  useEffect(() => {
    setLoadData(true);
    if (!external && !delivery) {
      fetchContacts.mutate(
        {
          search: searchString,
          page: pageSkip,
          perPage: perPage,
          sort: sort,
          filters: JSON.stringify(chosenFilters),
          audienceId: audienceId ? audienceId : "",
          audienceFilters:
            audienceId && audience?.filters
              ? JSON.stringify(audience?.filters)
              : "[]",
        },
        {
          onSuccess: (data) => {
            setData(parseCustomFields(data?.contacts));
            setLoadData(false);
          },
        }
      );
    } else if (delivery) {
      searchDeliveries.mutate(
        {
          search: searchString,
          page: pageSkip,
          perPage: perPage,
          sort: sort,
          projectId: email?.projectId,
          distId: email?.id,
          send: active + 1,
        },
        {
          onSuccess: (data) => {
            setData(data?.deliveries);
            setLoadData(false);
          },
        }
      );
    } else if (external) {
      const url = process.env.REACT_APP_SALESFORCE_QUERY;
      let data = {};
      let columns = [];
      if (headers) {
        forEach(headers, (h) => {
          if (h.enabled) {
            columns.push(h?.sfName ? h?.sfName : h?.name);
          }
        });
      }

      if (searchString) {
        data = {
          queries: [{ column: "email", operator: 2, values: [searchString] }],
          columns: columns,
        };
      } else {
        data = {
          // queries: [{ column: "FirstName", operator: 2, values: ["a"] }],
          queries: chosenFilters,
          columns: columns,
        };
        // debugger;
      }

      const config = {
        withCredentials: true,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
        params: {
          offset: currPage - 1,
          page_size: perPage,
        },
      };
      axios
        .post(url, data, config)
        .then((response) => {
          let found = [];
          for (let row of response?.data?.data) {
            // debugger
            let newrow = {
              ...row,
              account: row.Account,
              email: row.Email,
              firstName: row.FirstName,
              lastName: row.LastName,
              id: row?.Id ? row?.Id : shortId(),
            };
            delete newrow.LastName;
            delete newrow.FirstName;
            delete newrow.Email;
            delete newrow.Account;

            if (newrow?.account) {
              forEach(Object.keys(newrow?.account), (key) => {
                // debugger;
                let newkey = key[0].toLowerCase() + key.slice(1);
                newrow["account " + newkey] = newrow?.account[key];
              });
            }
            delete newrow["account attributes"];
            // delete newrow["account name"]
            found.push(newrow);
          }
          setData(found);
          let newselect = [];
          for (let c of selected) {
            newselect.push(found.find((f) => f?.id === c?.id));
          }
          setSelected(newselect);
          setExternalMax(response?.data?.num_records);
          setLoadData(false);
        })
        .catch((error) => {
          console.error(error);

          setData([]);
        });
    } else {
      setData([]);
      setLoadData(false);
    }
  }, [
    chosenFilters,
    perPage,
    pageSkip,
    sort,
    refetch,
    searchString,
    audience,
    active,
    headers,
  ]);

  function exportFile(type, select) {
    if (select) {
      let finalContacts = [];
      if (!delivery) {
        for (let contact of selected) {
          let fields = JSON.parse(contact.customField);

          let newcontact = { ...contact, ...fields };
          if (newcontact?.customField) {
            delete newcontact?.customField;
          }
          finalContacts.push(newcontact);
        }
      } else {
        finalContacts = selected;
      }
      if (type === "excel") {
        exportToExcel(finalContacts, "contacts");
      }
      if (type === "csv") {
        exportToCSV(finalContacts, "contacts");
      }
    } else if (!delivery) {
      fetchContacts.mutate(
        {
          search: "",
          page: 0,
          perPage: 0,
          sort: sort,
          filters: chosenFilters ? JSON.stringify(chosenFilters) : "",
          audienceFilters:
            audienceId && audience?.filters
              ? JSON.stringify(audience?.filters)
              : "[]",
          audienceId: audienceId ? audienceId : "",
        },
        {
          onSuccess: (data) => {
            // console.log(data);
            let finalContacts = [];
            for (let contact of data?.contacts) {
              let fields = JSON.parse(contact.customField);

              let newcontact = { ...contact, ...fields };
              if (newcontact?.customField) {
                delete newcontact?.customField;
              }
              finalContacts.push(newcontact);
            }
            if (type === "excel") {
              exportToExcel(finalContacts, "contacts");
            }
            if (type === "csv") {
              exportToCSV(finalContacts, "contacts");
            }
          },
        }
      );
    } else if (delivery) {
      searchDeliveries.mutate(
        {
          search: searchString,
          page: pageSkip,
          perPage: perPage,
          sort: sort,
          projectId: email?.projectId,
          distId: email?.id,
          send: active + 1,
        },
        {
          onSuccess: (data) => {
            // console.log(data);
            // let finalContacts = [];
            // for (let contact of data?.contacts) {
            //   let fields = JSON.parse(contact.customField);

            //   let newcontact = { ...contact, ...fields };
            //   if (newcontact?.customField) {
            //     delete newcontact?.customField;
            //   }
            //   finalContacts.push(newcontact);
            // }
            if (type === "excel") {
              exportToExcel(data?.deliveries, "contacts");
            }
            if (type === "csv") {
              exportToCSV(data?.deliveries, "contacts");
            }
          },
        }
      );
    }
  }

  const fileTypeExcel =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8";
  const fileTypeCSV = "text/csv;charset=utf-8";
  const fileExtension = "xlsx";

  const exportToExcel = async (dataExcel, fileName) => {
    const ws = XLSX.utils.json_to_sheet(dataExcel);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileTypeExcel });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const exportToCSV = async (dataExcel, fileName) => {
    const ws = XLSX.utils.json_to_sheet(dataExcel);
    const csv = XLSX.utils.sheet_to_csv(ws);
    const data = new Blob([csv], { type: fileTypeCSV });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const updateContact = useUpdateContactGql();
  const [saving, setSaving] = useState("Save Changes");
  const [loading, setLoading] = useState(false);
  const [activeContact, setActiveContact] = useState(false);

  const player1 = useRef(null);
  function onSaveChanges() {
    setSaving(
      <div className={styles.saving}>
        Saving...{" "}
        <Loading height={25} width={25}>
          {" "}
        </Loading>
      </div>
    );
    setLoading(true);

    for (let i = 0; i < changedContacts.length; i++) {
      let contact = changedContacts[i];
      let id = `${contact.id}`;
      delete contact.id;
      updateContact.mutate(
        {
          data: contact,
          id: id,
        },
        {
          onSuccess: (data) => {
            if (i === changedContacts.length - 1) {
              setSaving(
                <div className={styles.saving}>
                  Saved{" "}
                  <Player
                    autoplay
                    onEvent={(e) => {
                      if (e === "play") {
                        setTimeout(() => {
                          player1?.current?.pause();
                        }, 2000);
                      }
                    }}
                    ref={player1}
                    src={require("assets/animations/checkmarkgreen.json")}
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </div>
              );
              setTimeout(() => {
                setSaving("Save Changes");
                setChangedContacts([]);
                setLoading(false);
              }, 3000);
              setRefetch(!refetch);
              maxDataQuery.refetch();
            }
          },
        }
      );
    }
  }
  const navigate = useNavigate();

  function getHeight() {
    if (external) {
      return "40px";
    }

    if (audienceId) {
      return 40 + 40 * (user?.linkedAccounts?.length + 1) + "px";
    }
    return 40 + 40 * (user?.linkedAccounts?.length + 1) + "px";
  }

  useEffect(() => {
    if (
      !showDynamic &&
      (audience?.filters?.length === 0 || !audience?.filters) &&
      updateAudience
    ) {
      updateAudience({
        ...audience,
        dynamic: true,
      });
    }
  }, [showDynamic]);

  return (
    <>
      {!external && (
        <Modal
          show={importFile || manualEntry || importExternal}
          onClose={() => {
            if (importFile) {
              setImportFile(false);
            }
            if (manualEntry) {
              setManualEntry(false);
            }
            if (importExternal) {
              setExternal(false);
            }
          }}
          modalStyle={{
            borderRadius: "1em",
            height: importExternal ? "95%" : "800px",
            width: "1400px",
            minWidth: manualEntry ? "fit-content" : "",
            paddingTop: "1em",
            overflow: manualEntry ? "auto" : "",
          }}
          dark
        >
          {importFile && (
            <ContactCleaner
              user={user}
              audienceId={audienceId ? audienceId : ""}
              salesforce={external}
              organizationId={orgId}
              customFields={customFields}
            ></ContactCleaner>
          )}
          {manualEntry && (
            <Manual
              isAudience={audienceId}
              audienceId={audienceId ? audienceId : ""}
            ></Manual>
          )}
          {importExternal && !external && (
            <ExternalImport
              organization={organization}
              user={user}
              type={importExternal}
              audience={audience}
            />
          )}
        </Modal>
      )}
      {activeContact && !delivery && (
        <ContactDetails
          onClose={() => {
            setActiveContact(null);
          }}
          contact={activeContact}
          editable={roles?.canCreateEditOrgContacts}
          roles={roles}
        ></ContactDetails>
      )}

      {adjustFields && (
        <FieldAdjuster
          show={adjustFields}
          setShow={setAdjustFields}
          orgId={orgId}
          user={user}
        ></FieldAdjuster>
      )}

      {external && (importFile || doSelected) && (
        <ContactCleaner
          user={user}
          audienceId={audienceId ? audienceId : ""}
          salesforce={external}
          numImport={doSelected ? selected.length : externalMax}
          audience={audience}
          toAudience={importFile === "audience"}
          selected={selected}
          doSelected={doSelected && !importFile}
          doFilter={external && !doSelected}
          setImporting={(val) => {
            if (doSelected) {
              setDoSelected(val);
            } else {
              setImportFile(val);
            }
            setExternalImporting(val);
          }}
          initHeaders={headers}
          initData={doSelected ? selected : data}
          organizationId={orgId}
          customFields={customFields}
        ></ContactCleaner>
      )}
      {(external ? !importFile && !doSelected : true) && (
        <div className={styles.page} style={{ padding: delivery ? "0em" : "" }}>
          {/* The Header*/}
          {!external && audience && audience?.dynamic && showDynamic && (
            <div className={styles.showDy}>
              <Filters
                chosenFilters={audience?.filters ? audience?.filters : []}
                setChosenFilters={(filters) =>
                  updateAudience({
                    ...audience,
                    filters: filters,
                  })
                }
                saveAudience={saveAudience}
                audience
                open={externalType === "Filters"}
                chosenColumns={headers}
                setShowDynamic={setShowDynamic}
              />
            </div>
          )}

          {showFilters && audience && (
            <div className={styles.showDy}>
              <Filters
                chosenFilters={chosenFilters}
                setChosenFilters={setChosenFilters}
                chosenColumns={headers}
                setChosenColumns={setHeaders}
                external={external}
                open
                orgId={organization?.id}
                setShowDynamic={setShowFilters}
              />
            </div>
          )}
          {showColumns && audience && (
            <div className={styles.showDy}>
              <Filters
                chosenFilters={chosenFilters}
                setChosenFilters={setChosenFilters}
                chosenColumns={headers}
                setChosenColumns={setHeaders}
                external={external}
                open
                orgId={organization?.id}
                setShowDynamic={setShowColumns}
                columnsOnly
              />
            </div>
          )}
          <div
            className={styles.middleContent}
            style={{ padding: delivery ? "0em" : "" }}
          >
            {!delivery && !audience && (
              <>
                <Filters
                  chosenFilters={chosenFilters}
                  setChosenFilters={setChosenFilters}
                  chosenColumns={headers}
                  setChosenColumns={setHeaders}
                  external={external}
                  open={externalType === "Filters"}
                  orgId={organization?.id}
                />
                {!external && audienceId && audience && audience?.dynamic && (
                  <Filters
                    chosenFilters={audience?.filters ? audience?.filters : []}
                    setChosenFilters={(filters) =>
                      updateAudience({ ...audience, filters: filters })
                    }
                    saveAudience={saveAudience}
                    audience
                    open={externalType === "Filters"}
                    chosenColumns={headers}
                  />
                )}
              </>
            )}

            <div
              className={styles.tableContainer}
              style={{ padding: delivery ? "0em" : "" }}
            >
              <div className={styles.topContainer}>
                {!hideSearchButton && (
                  <div className={styles.search}>
                    {audience && (
                      <div className={styles.filterIcons}>
                        <div
                          className={styles.filterIcon}
                          onClick={() => {
                            setShowDynamic(true);
                            if (!audience?.dynamic) {
                              updateAudience({
                                ...audience,
                                dynamic: true,
                              });
                            }
                          }}
                        >
                          <i
                            className={`bi-person-lines-fill ${styles.iconCount}`}
                          ></i>
                          <div className={styles.count}>
                            {audience?.filters?.length}
                          </div>
                        </div>
                        <div
                          className={styles.filterIcon}
                          onClick={() => setShowFilters(true)}
                        >
                          <i className={`bi-funnel ${styles.iconCount}`}></i>
                          <div
                            className={styles.count}
                            style={{ marginLeft: "-3px" }}
                          >
                            {chosenFilters?.length}
                          </div>
                        </div>
                        <div
                          className={styles.filterIcon}
                          onClick={() => setShowColumns(true)}
                        >
                          <i
                            className={`bi-layout-text-window-reverse ${styles.iconCount}`}
                          ></i>
                        </div>
                      </div>
                    )}
                    <NavTextField
                      value={searchString}
                      setValue={onSearch}
                      placeholder={
                        searchPlaceholder ? searchPlaceholder : "Search..."
                      }
                    />
                  </div>
                )}
                <div className={styles.search} style={{ paddingBottom: "0px" }}>
                  {maxDataQuery?.isSuccess && (
                    <PillContainer
                      maxItems={
                        !external
                          ? maxDataQuery?.isSuccess &&
                            maxDataQuery.data[maxDataQueryAccessor]
                          : externalMax
                      }
                      active={active}
                      updateActive={(a) => setActive(a)}
                      delivery={delivery}
                      external={external}
                    />
                  )}
                </div>
              </div>
              <div className={styles.topContainer2}>
                <div className={styles.buttons} style={{ gap: "1.5em" }}>
                  {(!externalType || externalType === "Select") && (
                    <div
                      className={styles.sortingContent}
                      onClick={() => setShowSelected(true)}
                    >
                      <span style={{ width: "120px" }}>
                        Selected {selected?.length}{" "}
                        <i className="bi-chevron-down"></i>
                        {showSelected && (
                          <div
                            className={styles.settingsBox}
                            ref={ref}
                            style={{
                              overflow: "auto",
                              height:
                                selectedOptions.length * 40 > 160
                                  ? 160
                                  : selectedOptions.length * 40,
                              top: "30px",
                              left: "0px",
                              width: "fit-content",
                            }}
                          >
                            {selectedOptions.map((item, i) => (
                              <React.Fragment key={i}>
                                {sort.item != item.value && (
                                  <div
                                    className={styles.menuItem}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (item.value === "selectpage") {
                                        handleSelectPage();
                                      }
                                      if (item.value === "removeselected") {
                                        setSelected([]);
                                      }
                                      if (
                                        item.value ===
                                        "removeselectedfromaudience"
                                      ) {
                                        handleRemoveSelectedFromAudience();
                                      }
                                      if (
                                        item.value === "cancel" &&
                                        selected.length > 0
                                      ) {
                                        handleCancelSelectedSends();
                                      }
                                    }}
                                  >
                                    {item.name}
                                  </div>
                                )}
                              </React.Fragment>
                            ))}
                          </div>
                        )}
                      </span>
                    </div>
                  )}
                  <div className={styles.sortingContent}>
                    Sort By{" "}
                    <span onClick={() => setSortBy(true)}>
                      {options.find((item) => item?.value === sort?.item)?.name}
                      <i className="bi-chevron-down"></i>
                      {sortBy && (
                        <div
                          className={styles.settingsBox}
                          ref={ref}
                          style={{
                            overflow: "auto",
                            height:
                              (options.length * 40 > 160
                                ? 160
                                : options.length * 40) + "px",
                            top: "30px",
                            width: "100%",
                          }}
                        >
                          {options.map((item, i) => (
                            <React.Fragment key={i}>
                              {sort.item != item.value && (
                                <div
                                  className={styles.menuItem}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSort({ ...sort, item: item.value });
                                    setSortBy(false);
                                  }}
                                >
                                  {item.name}
                                </div>
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                      )}
                    </span>
                  </div>
                </div>
                {!hidebuttons && (
                  <div className={styles.buttons}>
                    {editFields && (
                      <div className={styles.editableFields}>
                        Can Edit Fields <i className="bi-check"></i>
                      </div>
                    )}
                    {!audienceId && !external && !delivery && (
                      <div style={{ position: "relative" }}>
                        <Button
                          shadow
                          style={{
                            gap: ".5em",
                            padding: "4px 10px",
                            height: "fit-content",
                          }}
                          onClick={() => {
                            setShowExports(false);
                            setShowImports(false);
                            setShowUpdates(!updates);
                          }}
                        >
                          <i className="bi-pencil"></i> Edit
                          <i className="bi-chevron-down"></i>
                        </Button>
                        {updates && (
                          <div
                            className={styles.settingsBox}
                            ref={ref}
                            style={{ height: "120px" }}
                          >
                            {/* <div
                              className={styles.menuItem}
                              onClick={() => setImportFile(true)}
                            >
                              <i className="bi-filetype-csv"></i>
                              .csv
                            </div>
                            <div
                              className={styles.menuItem}
                              onClick={() => setImportFile(true)}
                            >
                              <i
                                className="bi-file-earmark-excel"
                                style={{ color: "#C1E08D" }}
                              ></i>
                              Excel
                            </div> */}
                            <div
                              className={styles.menuItem}
                              onClick={() => setAdjustFields(true)}
                            >
                              <i
                                className="bi-newspaper"
                                style={{ color: "#5A8181" }}
                              ></i>
                              Column Editor
                            </div>
                            {!editFields && !external && (
                              <div
                                className={styles.menuItem}
                                onClick={() => setEditFields(true)}
                              >
                                <i
                                  className="bi-person-vcard"
                                  style={{ color: "#5A8181" }}
                                ></i>
                                Enable Edit Mode
                              </div>
                            )}
                            <div
                              className={styles.menuItem}
                              // onClick={() => setEditFields(true)}
                            >
                              <i
                                className="bi-people"
                                style={{ color: "#5A8181" }}
                              ></i>
                              Create Audience
                            </div>
                            {editFields && !external && (
                              <div
                                className={styles.menuItem}
                                onClick={() => {
                                  setEditFields(false);
                                  setRefetch(!refetch);
                                  setChangedContacts([]);
                                }}
                              >
                                <i
                                  className="bi-person-vcard"
                                  style={{ color: "#FF8878" }}
                                ></i>
                                Disable Edit Mode
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}

                    {!delivery && !external && (
                      <div style={{ position: "relative" }}>
                        <Button
                          shadow
                          style={{
                            gap: ".5em",
                            padding: "4px 10px",
                            height: "fit-content",
                          }}
                          onClick={() => {
                            setShowUpdates(false);
                            setShowExports(false);
                            setShowImports(!imports);
                          }}
                        >
                          <i className="bi-cloud-arrow-up"></i> Import{" "}
                          {!external && "from"}
                          <i className="bi-chevron-down"></i>
                        </Button>
                        {imports && (
                          <div
                            className={styles.settingsBox}
                            ref={ref}
                            style={{
                              height: getHeight(),
                              overflow: "visible",
                            }}
                          >
                            {!external && (
                              <div
                                className={styles.menuItem}
                                onClick={() => {
                                  if (audienceId) {
                                    navigate(
                                      `/import-contacts/csv/${audienceId}`
                                    );
                                  } else {
                                    navigate("/import-contacts/csv");
                                  }
                                }}
                                style={{
                                  borderTopLeftRadius: ".5em",
                                  borderTopRightRadius: ".5em",
                                }}
                              >
                                <i className="bi-filetype-csv"></i>
                                File
                              </div>
                            )}

                            {/* {external && !audienceId && (
                              <div
                                className={styles.menuItem}
                                style={{
                                  borderTopLeftRadius: ".5em",
                                  borderTopRightRadius: ".5em",
                                }}
                                onClick={() => setImportFile(true)}
                              >
                                <i
                                  className="bi-people"
                                  style={{ color: "#5A8181" }}
                                ></i>
                                All
                              </div>
                            )} */}
                            {external && (
                              <div
                                className={styles.menuItem}
                                onClick={() => setImportFile("audience")}
                                style={{
                                  borderTopLeftRadius: ".5em",
                                  borderTopRightRadius: ".5em",
                                }}
                              >
                                <i
                                  className="bi-funnel"
                                  style={{ color: "#5A8181" }}
                                ></i>
                                <span>Current View</span>{" "}
                                {!external
                                  ? maxDataQuery?.isSuccess &&
                                    maxDataQuery.data[maxDataQueryAccessor]
                                  : externalMax}
                              </div>
                            )}
                            {external && (
                              <div
                                className={styles.menuItem}
                                onClick={() => {
                                  if (!external) {
                                    setImportFile(true);
                                  } else {
                                    setDoSelected(true);
                                  }
                                }}
                                style={{
                                  borderBottomLeftRadius: external
                                    ? ".5em"
                                    : "",
                                  borderBottomRightRadius: external
                                    ? ".5em"
                                    : "",
                                }}
                              >
                                <i
                                  className="bi-person-check"
                                  style={{ color: "#5A8181" }}
                                ></i>
                                <span>Selected </span>
                                {selected.length}
                              </div>
                            )}
                            {/* {audienceId && !external && (
                              <div
                                className={styles.menuItem}
                                onClick={() =>
                                  updateAudience({
                                    ...audience,
                                    dynamic: !audience.dynamic,
                                  })
                                }
                                style={{
                                  color: audience?.dynamic ? "#15bcc7" : "",
                                }}
                              >
                                <i
                                  className="bi-person-up"
                                  style={{
                                    color: audience?.dynamic
                                      ? "#15bcc7"
                                      : "#5A8181",
                                  }}
                                ></i>
                                Dynamic Contacts
                              </div>
                            )} */}

                            {!external && (
                              <div
                                className={styles.menuItem}
                                onClick={() => {
                                  if (audienceId) {
                                    navigate(
                                      `/import-contacts/manual/${audienceId}`
                                    );
                                  } else {
                                    navigate(
                                      "/import-contacts/manual"
                                    );
                                  }
                                }}
                                style={{
                                  borderBottomLeftRadius:
                                    user?.linkedAccounts?.length === 0
                                      ? ".5em"
                                      : "",
                                  borderBottomRightRadius:
                                    user?.linkedAccounts?.length === 0
                                      ? ".5em"
                                      : "",
                                }}
                              >
                                <i
                                  className="bi-pen"
                                  style={{ color: "#5A8181" }}
                                ></i>
                                Manual Input
                              </div>
                            )}

                            {!external &&
                              user?.linkedAccounts?.length > 0 &&
                              user?.linkedAccounts?.map((account, i) => (
                                <div
                                  className={styles.menuItem}
                                  onClick={() => {
                                    if (account.accountType === "Salesforce") {
                                      if (audienceId) {
                                        navigate(
                                          `/import-contacts/salesforce/${audienceId}`
                                        );
                                      } else {
                                        navigate(
                                          "/import-contacts/salesforce"
                                        );
                                      }
                                    }
                                  }}
                                  key={account?.id}
                                  style={{
                                    borderBottomLeftRadius:
                                      user?.linkedAccounts?.length - 1 === i
                                        ? ".5em"
                                        : "",
                                    borderBottomRightRadius:
                                      user?.linkedAccounts?.length - 1 === i
                                        ? ".5em"
                                        : "",
                                  }}
                                >
                                  {account.accountType === "Salesforce" && (
                                    <>
                                      {" "}
                                      <img src={salesforce} width={30}></img>
                                      Salesforce
                                    </>
                                  )}
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    )}

                    <div style={{ position: "relative" }}>
                      <Button
                        shadow
                        style={{
                          gap: ".5em",
                          padding: "4px 10px",
                          height: "fit-content",
                        }}
                        onClick={() => {
                          setShowUpdates(false);
                          setShowImports(false);
                          setShowExports(!exports);
                        }}
                      >
                        <i className="bi-cloud-arrow-down"></i> Export{" "}
                        <i className="bi-chevron-down"></i>
                      </Button>
                      {exports && (
                        <div
                          className={styles.settingsBox}
                          style={{
                            width: "150px",
                            height: "120px",
                            overflow: "visible",
                          }}
                          ref={ref}
                        >
                          <div
                            className={styles.menuItem}
                            onClick={() =>
                              !loadData ? exportFile("csv") : undefined
                            }
                            style={{
                              borderTopLeftRadius: ".5em",
                              borderTopRightRadius: ".5em",
                            }}
                          >
                            <i className="bi-filetype-csv"></i>
                            .csv
                          </div>
                          <div
                            className={styles.menuItem}
                            onClick={() =>
                              !loadData ? exportFile("excel") : undefined
                            }
                          >
                            <i
                              className="bi-file-earmark-excel"
                              style={{ color: "#C1E08D" }}
                            ></i>
                            Excel
                          </div>
                          <div
                            className={styles.menuItem}
                            style={{
                              position: "relative",
                              overflow: "visible",
                              borderBottomLeftRadius: ".5em",
                              borderBottomRightRadius: ".5em",
                            }}
                            onClick={() => setSelectType(true)}
                          >
                            <i
                              className="bi-person-check"
                              style={{ color: "#5A8181" }}
                            ></i>
                            Selected
                            <i
                              className="bi-chevron-down"
                              style={{ color: "#5A8181" }}
                            ></i>
                            {selectType && (
                              <div
                                className={styles.settingsBox}
                                style={{
                                  width: "150px",
                                  height: "80px",
                                  right: "152px",
                                  top: "0em",
                                }}
                                ref={ref}
                              >
                                <div
                                  className={styles.menuItem}
                                  onClick={() =>
                                    !loadData
                                      ? exportFile(
                                          "csv",
                                          true
                                          // queryData.data[queryDataAccessor]
                                        )
                                      : undefined
                                  }
                                >
                                  <i className="bi-filetype-csv"></i>
                                  .csv
                                </div>
                                <div
                                  className={styles.menuItem}
                                  onClick={() =>
                                    !loadData
                                      ? exportFile(
                                          "excel",
                                          true
                                          // queryData.data[queryDataAccessor]
                                        )
                                      : undefined
                                  }
                                >
                                  <i
                                    className="bi-file-earmark-excel"
                                    style={{ color: "#C1E08D" }}
                                  ></i>
                                  Excel
                                </div>{" "}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {(maxDataQuery?.isLoading || loadData) && (
                <div style={{ height: "1000px" }}>
                  <Loading></Loading>
                </div>
              )}
              {maxDataQuery?.isError && <p>Error</p>}
              {maxDataQuery?.isSuccess && !loadData && (
                <TableContainer
                  headers={headers}
                  data={data}
                  setData={setData}
                  onRowClick={(contact) => {
                    if (!external) {
                      if (delivery) {
                        onRowClick(contact);
                      } else {
                        setActiveContact(contact);
                      }
                    }
                  }}
                  sort={sort}
                  setSort={setSort}
                  id="ajsdfpoij"
                  emptyMessage={emptyMessage}
                  style={{
                    margin: "0em",
                    boxShadow: "none",
                    borderTopLeftRadius: "0px",
                    borderTopRightRadius: "0px",
                  }}
                  select={!externalType || externalType === "Select"}
                  selected={selected}
                  changeSelected={handleSelect}
                  editableFields={editFields}
                  overflowVisible={editFields}
                  moveableColumns
                  changedContacts={changedContacts}
                  setChangedContacts={setChangedContacts}
                  editFields={editFields}
                  customFields={customFields}
                  chosenFilters={chosenFilters}
                  refetching={maxDataQuery?.isRefetching}
                  page={pageSkip}
                  perPage={perPage}
                  audienceId={audienceId}
                  parseCustomFields={parseCustomFields}
                  external={external}
                />
              )}
              <HorizontalBar
                height={"2px"}
                width={"100%"}
                style={{ margin: "0em" }}
              ></HorizontalBar>

              <PaginationContainer
                style={{
                  padding: "1em 1em",
                  position: "relative",
                  maxHeight: "fit-content",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {editFields && (
                  <div
                    style={{
                      display: "flex",
                      gap: ".5em",
                      pointerEvents: loading ? "none" : "",
                    }}
                  >
                    <>
                      <Button
                        shadow
                        seafoam
                        style={{ fontSize: ".8em" }}
                        onClick={() => {
                          setChangedContacts([]);
                          setRefetch(!refetch);
                        }}
                        disable={changedContacts.length === 0}
                      >
                        Cancel
                      </Button>
                      <Button
                        shadow
                        style={{ fontSize: ".8em" }}
                        onClick={onSaveChanges}
                        disable={changedContacts.length === 0}
                      >
                        {saving}
                      </Button>
                    </>
                  </div>
                )}
                {!editFields && (external || audience || delivery || email) && (
                  <div></div>
                )}

                {!editFields &&
                  !external &&
                  !audience &&
                  !delivery &&
                  !email && (
                    <div
                      className={styles.link}
                      onClick={() => {
                        navigate("/previous-imports");
                      }}
                    >
                      View Import Jobs
                    </div>
                  )}
                {maxDataQuery?.isSuccess && !loadData && (
                  <Pagination
                    pageNum={currPage}
                    setPageNum={setCurrPage}
                    maxPage={
                      !external
                        ? maxDataQuery.data[maxDataQueryAccessor] / perPage < 1
                          ? 1
                          : Math.ceil(
                              maxDataQuery.data[maxDataQueryAccessor] / perPage
                            )
                        : externalMax / perPage < 1
                        ? 1
                        : Math.ceil(externalMax / perPage)
                    }
                    length={data?.length}
                    searchString={searchString}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    setPageSkip={setPageSkip}
                    maxItems={
                      !external
                        ? maxDataQuery?.isSuccess &&
                          maxDataQuery.data[maxDataQueryAccessor]
                        : externalMax
                    }
                  ></Pagination>
                )}
              </PaginationContainer>
            </div>
          </div>
        </div>
      )}
      {external && !doSelected && !importFile && (
        <div className={styles.footer}>
          <Button
            onClick={(e) => {
              setChosenFilters([]);
              setSelected([]);
              setExternalType(null);
            }}
            shadow
            // disable={!file && !salesforce}
            link
          >
            Previous
          </Button>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={(e) => {
                if (externalType === "Select") {
                  setDoSelected(true);
                } else {
                  setImportFile(audienceId ? "audience" : true);
                }
              }}
              shadow
              disable={externalType === "Select" && selected.length === 0}
            >
              Next
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default ContactTable;

function TableContainer({
  onRowClick,
  sort,
  setSort,
  emptyMessage,
  select,
  selected,
  style,
  moveableColumns,
  editFields,
  changedContacts,
  setChangedContacts,
  customFields,
  headers,
  changeSelected,
  data,
  setData,
  external,
}) {
  function saveContact(value, header, rowdata, i) {
    let fields = { ...rowdata };

    let field = customFields.find((f) => f.name === header.accessor);
    if (field?.delimiter) {
      fields[header.accessor] = "";
      for (let option of value) {
        if (fields[header.accessor]) {
          fields[header.accessor] += field.delimiter;
        }
        fields[header.accessor] +=
          option && typeof option === "object" ? option?.value : option;
      }
    } else {
      fields[header.accessor] =
        value && typeof value === "object" ? value?.value : value;
    }

    let contact = {
      firstName: fields.firstName,
      lastName: fields.lastName,
      unsubscribed:
        fields?.unsubscribed && typeof fields.unsubscribed === "object"
          ? fields?.unsubscribed?.value
          : fields.unsubscribed,
      email: fields.email,
      id: rowdata.id,
    };

    delete fields.firstName;
    delete fields.lastName;
    delete fields.email;
    delete fields.participationCount;
    delete fields.unsubscribed;
    delete fields.updatedAt;
    delete fields.createdAt;
    delete fields.color;
    delete fields.customField;
    delete fields.id;
    contact.customField = JSON.stringify(fields);
    let changed = [...changedContacts];
    let index = changed.findIndex((c) => c.id === rowdata.id);
    if (index >= 0) {
      changed[index] = contact;
    } else {
      changed.push(contact);
    }
    setChangedContacts(changed);

    let tempData = [...data];
    tempData[i] = { ...rowdata, ...fields, ...contact };
    setData(tempData);
  }
  return (
    <>
      <BasicTable
        initheaders={headers}
        data={data}
        onRowClick={editFields ? undefined : onRowClick}
        sort={sort}
        setSort={setSort}
        id="ajsdfpoij"
        emptyMessage={emptyMessage}
        style={style}
        select={select}
        selected={selected}
        changeSelected={changeSelected}
        onRowClickSelect={external}
        editableFields={editFields}
        // onChangeEditableCell={(value, header, rowdata, i) =>
        //   console.log(value, header, rowdata, i)
        // }
        onSaveEditableCell={(value, header, rowdata, i) =>
          saveContact(value, header, rowdata, i)
        }
        overflowVisible={editFields}
        moveableColumns={moveableColumns}
      />
    </>
  );
}

function PillContainer({ active, updateActive, maxItems, delivery, external }) {
  const [menuItems, setMenuItems] = useState(initItems());
  function initItems() {
    if (delivery) {
      let items = [];
      for (let i = 0; i < delivery; i++) {
        items.push({
          id: i,
          name: (
            <div style={{ display: "flex", gap: "7px", alignItems: "center" }}>
              Delivery {i + 1}
              {/* <span className={styles.count}>{maxItems}</span> */}
            </div>
          ),
        });
      }

      return items;
    }
    let items = [
      {
        id: 0,
        name: (
          <div style={{ display: "flex", gap: "7px", alignItems: "center" }}>
            {external ? "Salesforce Contacts" : "Contacts"}
          </div>
        ),
      },
    ];
    return items;
  }

  return (
    <TabPill
      tabBarItems={menuItems}
      active={active ? active : 0}
      updateActive={updateActive}
      style={{ flexWrap: "nowrap" }}
      // showNumber={maxItems}
    />
  );
}
