import { useState, useEffect, useRef } from "react";
import styles from "./Visualization.module.scss";
import Chart from "components/Charts/Chart";
import QuestionChart, {
  MetricType,
  TableType,
  ValueType,
} from "./QuestionChart";
import { useFetchQChartRoles } from "api/resources/organization/roles";
import {
  useDeleteOneVisualization,
  useUpdateVisualization,
} from "api/resources/projects/visualizations";
import { getSortedArray } from "assets/functions/ArrayFunctions";
import { useQueryClient } from "react-query";
import ScoreChart from "./ScoreChart";
import TextBox from "components/Charts/TextBox";
import ConfigureBox from "./Settings/ConfigureBox";
import AddToWidget from "pages/dashboard/AddToDashPopouts/AddToWidget";
import { shortId } from "components/tables/EditableTable/utils";
import TableViz from "./TableViz";
import SurveyStatChart from "./StatChart";
import TableChart from "components/Charts/Table/TableChart";
import { QuickPivots } from "./Settings/QuickPivots";
import Icon from "components/Icon/Icon";

export function combinedQs(viz) {
  let Qs = [...viz.question];
  if (viz.dynamicQs) {
    for (let q of viz.dynamicQs) {
      if (!Qs.some((qst) => qst.id === q.id)) {
        Qs.push(q);
      }
    }
    // Qs = [...Qs, ...viz.dynamicQs];
  }
  return Qs;
}

export function combinedProjIds(viz) {
  let ids = [...viz.projectIdsArray];
  if (viz.dynamicQs) {
    for (let q of viz.dynamicQs) {
      if (!ids.includes(q.projectId)) {
        ids.push(q.projectId);
      }
    }
  }

  return ids;
}

export function combineFilters(viz, externalFilter) {
  if (externalFilter && externalFilter !== "{}") {
    let filter = viz.filters ? JSON.parse(viz.filters) : {};
    let external = JSON.parse(externalFilter);
    for (let key in external) {
      if (key in filter) {
        if (key === "surveys") {
          for (let survey of external[key]) {
            if (!filter[key].some((s) => s.id === survey.id)) {
              filter[key].push(survey);
            }
          }
        } else if (key === "answers") {
          filter[key] = [...filter[key], ...external[key]];
        } else {
          for (let prop of external[key].properties) {
            if (!filter[key].properties.includes(prop)) {
              filter[key].properties.push(prop);
            }
          }
        }
      } else {
        filter[key] = external[key];
      }
    }

    return JSON.stringify(filter);
  } else {
    return viz.filters ? JSON.stringify(JSON.parse(viz.filters)) : "{}";
  }
}

export default function Visualization({
  viz,
  data,
  setData,
  setUpDataCounter,
  lockData,
  refetch,
  inEdit,
  canSave,
  canDelete,
  canEdit,
  inDash,
  onCopy,
  onCopyToPage,
  pages,
  setLabelInfo,
  deleteFunction,
  newChart,
  setAbove,
  closeAboveExpand,
  seeDataInDash,
  closeSeeDataInDash,
  thumbnail,
  idAddOn,
  redrawCounter,
  setVizCopy,
  custom_fields,
  externalFilter,
  preview,
  update,
  height,
  width,
  isDragging,
  active,
  setCurrViz,
  setEditing,
  editing,
  setVisibleItems,
  visibleItems,
  showDrill,
  setShowDrill
}) {
  function parsedViz() {
    if (typeof viz.designSettings === "string") {
      viz.designSettings = JSON.parse(viz.designSettings);
    }
    if (typeof viz.tagIdsArray === "string") {
      viz.tagIdsArray = JSON.parse(viz.tagIdsArray);
    }
    if (typeof viz.projectIdsArray === "string") {
      viz.projectIdsArray = JSON.parse(viz.projectIdsArray);
    }
    if (typeof viz.programIds === "string") {
      viz.programIds = JSON.parse(viz.programIds);
    }
    if (typeof viz.questionTags === "string") {
      viz.questionTags = JSON.parse(viz.questionTags);
    }
    if (typeof viz.comparison === "string") {
      viz.comparison = JSON.parse(viz.comparison);
    }

    if (!viz.designSettings) {
      viz.designSettings = {};
    }
    if (!viz.tagIdsArray) {
      viz.tagIdsArray = [];
    }
    if (!viz.projectIdsArray) {
      viz.projectIdsArray = [];
    }

    return viz;
  }

  const [copy, setCopy] = useState();

  return (
    <>
      {preview && <VizPreview viz={parsedViz()} />}
      {!preview && (
        <Shell
          viz={copy ? copy : parsedViz()}
          saveCopy={(viz) => setCopy(viz)}
          clearCopy={() => setCopy(null)}
          data={data}
          setData={setData}
          setUpDataCounter={setUpDataCounter}
          lockData={lockData}
          refetch={refetch}
          inEdit={inEdit}
          canSave={canSave}
          canDelete={canDelete}
          canEdit={canEdit}
          inDash={inDash}
          onCopy={onCopy}
          onCopyToPage={onCopyToPage}
          pages={pages}
          setLabelInfo={setLabelInfo}
          deleteFunction={deleteFunction}
          newChart={newChart}
          setAbove={setAbove}
          closeAboveExpand={closeAboveExpand}
          seeDataInDash={seeDataInDash}
          closeSeeDataInDash={closeSeeDataInDash}
          thumbnail={thumbnail}
          idAddOn={idAddOn}
          redrawCounter={redrawCounter}
          setVizCopy={setVizCopy}
          custom_fields={custom_fields}
          externalFilter={externalFilter}
          update={update}
          height={height}
          width={width}
          isDragging={isDragging}
          active={active}
          setCurrViz={setCurrViz}
          setEditing={setEditing}
          editing={editing}
          setVisibleItems={setVisibleItems}
          visibleItems={visibleItems}
          showDrill={showDrill}
          setShowDrill={setShowDrill}
        />
      )}
    </>
  );
}

function Shell({
  viz,
  saveCopy,
  clearCopy,
  data,
  setData,
  setUpDataCounter,
  lockData,
  refetch,
  inEdit,
  canSave,
  canDelete,
  canEdit,
  inDash,
  onCopy,
  onCopyToPage,
  pages,
  setLabelInfo,
  deleteFunction,
  newChart,
  setAbove,
  closeAboveExpand,
  seeDataInDash,
  closeSeeDataInDash,
  thumbnail,
  idAddOn,
  redrawCounter,
  setVizCopy,
  custom_fields,
  externalFilter,
  update,
  height,
  width,
  isDragging,
  active,
  setCurrViz,
  setEditing,
  editing,
  setVisibleItems,
  visibleItems,
  showDrill,
  setShowDrill
}) {
  const getRole = useFetchQChartRoles();
  const deleteViz = useDeleteOneVisualization();
  const updateViz = useUpdateVisualization();

  const [filters, setFilters] = useState(combineFilters(viz, externalFilter));

  function updateFilters() {
    setFilters(combineFilters(viz, externalFilter));
  }

  useEffect(() => {
    updateFilters();
  }, [externalFilter, viz.filters]);

  const [expand, setExpand] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);

  const [reDraw, setReDraw] = useState(redrawCounter ? redrawCounter : 0);
  const [addingToDash, setAddingToDash] = useState(false);
  // const [addedToDash, setAddedToDash] = useState(false);
  const [configure, setConfigure] = useState(false);
  const [seeData, setSeeData] = useState(false);
  const [filterSubtitle, setFilterSubtitle] = useState("");
  const [saving, setSaving] = useState(false);

  const [showCopyChartMenu, setShowCopyChartMenu] = useState(false);
  const [closeCopyMenu, setCloseCopyMenu] = useState(false);
  const [showPagesMenu, setShowPagesMenu] = useState(false);

  const [edited, setEdited] = useState(false);

  const [quickPivot, setQuickPivot] = useState(false);

  useEffect(() => {
    if (redrawCounter) {
      setReDraw(reDraw + redrawCounter);
    }
  }, [redrawCounter]);

  useEffect(() => {
    if (!openSettings && showCopyChartMenu) {
      setShowCopyChartMenu(false);
      setCloseCopyMenu(false);
      setShowPagesMenu(false);
    }
  }, [openSettings]);

  function addAnswerFilterSubtitle(conditions) {
    let string = "";
    for (let i = 0; i < conditions.length; i++) {
      let condition = conditions[i];
      let op = condition.operator;

      string += condition.surveyName + ": ";

      if (op === "have answered") {
        string += "answered '" + condition.qName + "'";
      } else if (op === "have not answered") {
        string += "did not answer '" + condition.qName + "'";
      } else if (
        op === "passives" ||
        op === "promoters" ||
        op === "detractors"
      ) {
        string += op;
      } else if (Array.isArray(condition.answer)) {
        if (op === "answer" || op === "do not answer" || op === "falls in") {
          if (op === "do not answer") {
            string += "not ";
          }
          for (let i = 0; i < condition.answer.length; i++) {
            if (i > 0) {
              if (i < condition.answer.length - 1) {
                string += ", ";
              } else if (i == condition.answer.length - 1) {
                string += " or ";
              }
            }
            string += condition.answer[i];
          }
        } else if (op === "between") {
          let them = getSortedArray(condition.answer, (a, b) => a - b);
          string += "between " + them[0] + " and " + them[1];
        }
      } else if (op === "below" || op === "above") {
        string += op + " " + condition.answer;
      } else if (op === "contains") {
        string += "'" + condition.answer + "'";
      } else if (op === "does not contain") {
        string += op + "  '" + condition.answer + "'";
      }

      if (op === "is longer than" || op === "is shorter than") {
        if (op === "is longer than") {
          string += "more than ";
        } else {
          string += "less than ";
        }
        string += condition.answer + " characters";
      }

      if (i < conditions.length - 1) {
        string += " & ";
      }
    }

    return string;
  }

  function getFilterSubtitle() {
    if (viz.filters === "{}") {
      return "";
    }
    let filterObj = JSON.parse(viz.filters);

    let string = "";
    for (let one in filterObj) {
      if (one === "orAnswers") {
        continue;
      }
      if (string) {
        string += " & ";
      }
      if (one === "surveys") {
        let all = filterObj.surveys;
        string += "Survey: ";
        for (let i = 0; i < all.length; i++) {
          string += all[i].name;
          if (i < all.length - 1) {
            string += ", ";
          } else {
            string += " ";
          }
        }
      } else if (one === "answers") {
        string += addAnswerFilterSubtitle(filterObj[one]);
      } else {
        let field = filterObj[one];
        let name = field.name.charAt(0).toUpperCase() + field.name.slice(1);
        string += name + ": ";
        for (let i = 0; i < field.properties.length; i++) {
          string += field.properties[i];
          if (i < field.properties.length - 1) {
            string += ", ";
          } else {
            string += " ";
          }
        }
      }
    }
    return string;
  }

  useEffect(() => {
    let it = "";
    if (viz.designSettings?.showFilterSubtitle && filters && filters !== "{}") {
      it = getFilterSubtitle();
    }
    setFilterSubtitle(it);
  }, [filters, viz.designSettings?.showFilterSubtitle]);

  function setUpPopout() {
    if (setAbove) {
      document.addEventListener("click", clickOutsideDashPopout, true);
      setAbove(
        <div className={styles.popoutBackground} id={"outside dash" + viz.id}>
          <div className={styles.popout} style={{ padding: "0px" }}>
            <Visualization
              viz={viz}
              canSave
              canEdit
              inDash
              closeAboveExpand={closeDashExpand}
              custom_fields={custom_fields}
            />
          </div>
        </div>
      );
    } else {
      setExpand(true);
      reload();
    }
  }

  function clickOutsideDashPopout(event) {
    if (event.target === document.getElementById("outside dash" + viz.id)) {
      closeDashExpand();
    }
  }

  function closeDashExpand() {
    document.removeEventListener("click", clickOutsideDashPopout, true);
    setAbove(null);
    reload();
    if (refetch) {
      refetch();
    }
  }

  function clickOutsidePopout(event) {
    if (
      event.target ===
      document.getElementById(inEdit ? "outside editor" : "outside " + viz.id)
    ) {
      closePopout();
    }
  }

  useEffect(() => {
    if (expand) {
      document.addEventListener("click", clickOutsidePopout, true);
      return () => {
        document.removeEventListener("click", clickOutsidePopout, true); //will hit when turned to false
      };
    }
  }, [expand]);

  function closePopout() {
    setExpand(false);
    reload();
  }

  function closeDashExpand() {
    document.removeEventListener("click", clickOutsideDashPopout, true); // Works because the closeDashExpand for the closeAboveExpand is set in the same scope as the addEventListener function
    setAbove(null);
    reload();
    if (refetch) {
      refetch();
    }
  }

  function reload() {
    setReDraw(reDraw + 1);
  }

  const ref = useRef();

  function clickOutsideMenu(event) {
    if (ref.current && !ref.current?.contains(event.target)) {
      setOpenSettings(false);
    }
  }

  useEffect(() => {
    if (openSettings) {
      document.addEventListener("click", clickOutsideMenu, true);
      return () => {
        document.removeEventListener("click", clickOutsideMenu, true); // Run on unmounting
      };
    }
  }, [openSettings]);

  function menuClick(callback) {
    callback();
    setOpenSettings(false); // Should trigger the release of the event listener
  }

  function pdfExport() {
    setTimeout(() => {
      let input = window.document.getElementById("outside " + viz.id);
      html2canvas(input, { scale: 5 }).then((canvas) => {
        const img = canvas.toDataURL("image/png", 100);
        // img.save("newimgage");
        const pdf = new jsPDF("p", "pt");
        // console.log(input.clientHeight);
        pdf.internal.pageSize.height = input.clientHeight;
        pdf.internal.pageSize.width = input.clientWidth;
        pdf.addImage(img, "png", 0, 0, input.clientWidth, input.clientHeight);
        //give pdf a name and download.
        let str = "chart.pdf";
        str = str.replace(/\s+/g, "");
        str = str.toLocaleLowerCase();
        pdf.save(str);
      }, 5000);
    });
  }

  function handleSetConfigure() {
    if (setAbove) {
      setAbove(
        <ConfigureBox
          visualization={viz}
          onSave={handleSave}
          onClose={closeConfigBox}
          lockData={lockData}
          canSave={canSave}
          custom_fields={custom_fields}
          externalFilter={externalFilter}
          inDash={inDash}
          height={document.getElementById("outside " + viz.id).offsetHeight}
          width={document.getElementById("outside " + viz.id).offsetWidth}
          saving={saving}
        />
      );
    }
    if (!inEdit && !setAbove) {
      setConfigure(true);
    }
  }

  useEffect(() => {
    if (setShowDrill) {
      setShowDrill(seeData);
    }
  }, [seeData])

  function setUpSeeData() {
    if (setAbove) {
      setAbove(
        <Visualization
          viz={viz}
          seeDataInDash
          closeSeeDataInDash={() => setAbove(null)}
          custom_fields={custom_fields}
        />
      );
    } else {
      setSeeData(true);
    }
  }



  function deleteVisualization() {
    if (canDelete) {
      if (deleteFunction) {
        deleteFunction(viz.id);
      } else {
        deleteViz.mutate(
          {
            id: viz.id,
          },
          {
            onSuccess: () => {
              if (refetch) {
                refetch();
              }
            },
          }
        );
      }
    }
  }

  const queryClient = useQueryClient();

  // function stringifyData() {
  //   if (
  //     visualization.type !== TableType &&
  //     visualization.type !== ValueType &&
  //     visualization.type !== MetricType
  //   ) {
  //     let data = getData();
  //     return JSON.stringify(data);
  //   }
  //   if (visualization.type === TableType) {
  //     // let data = getData();
  //     return "table";
  //   }
  //   if (visualization.type === ValueType) {
  //     let data = getValueData();
  //     return JSON.stringify(data);
  //   }
  //   if (visualization.type === MetricType) {
  //     // let data = getData();
  //     return "metric";
  //   }
  //   return "none";
  // }

  const handleSave = (newViz) => {
    if (canSave) {
      setSaving(true);
      let data = {
        title: newViz.title,
        titleValue: newViz.titleValue,
        type: newViz.type,
        questionIds: newViz.question ? newViz.question.map((q) => q.id) : null,
        designSettings: JSON.stringify(newViz.designSettings),
        pivotString: newViz.pivotString,
        filters:
          typeof newViz.filters !== "string"
            ? JSON.stringify(newViz.filters)
            : newViz.filters,
        tagIdsArray: JSON.stringify(newViz.tagIdsArray),
        projectIdsArray: JSON.stringify(newViz.projectIdsArray),
        programIds: newViz.programIds
          ? JSON.stringify(newViz.programIds)
          : null,
        questionTags: newViz.questionTags
          ? JSON.stringify(newViz.questionTags)
          : null,
        comparison: newViz.comparison
          ? JSON.stringify(newViz.comparison)
          : null,
        // data: stringifyData(),
      };
      updateViz.mutate(
        {
          id: viz.id,
          data: data,
        },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries([newViz.id]);
            setSaving(false);
            clearCopy();
            setEdited(false);
            setQuickPivot(false);
            closeConfigBox();
            if (refetch) {
              refetch();
            }
          },
        }
      );
    }
  };

  function closeConfigBox() {
    setOpenSettings(false);
    setConfigure(false);
    if (setAbove) {
      setAbove(null);
    }
  }

  const [offsetData, setOffsetData] = useState("");

  function drag_over(event) {
    var offset;
    try {
      offset = event.dataTransfer.getData("text/plain").split(",");
    } catch (e) {
      offset = offsetData.split(",");
    }

    var container = document.getElementById(viz.id);
    let newleft =
      parseInt(event.clientX + parseInt(offset[0], 10)) / container.clientWidth;
    newleft = newleft * 100;

    let newtop =
      parseInt(event.clientY + parseInt(offset[1], 10)) /
      container.clientHeight;
    newtop = newtop * 100;

    let settings = viz.designSettings;
    if (settings.textboxes.length > 0 && offset[2]) {
      settings.textboxes[offset[2]].left = newleft;
      settings.textboxes[offset[2]].top = newtop;
    }

    event.preventDefault();
    return false;
  }

  function drop(event) {
    if (setVizCopy) {
      // if editing

      var offset;
      try {
        offset = event.dataTransfer.getData("text/plain").split(",");
      } catch (e) {
        offset = offsetData.split(",");
      }

      var container = document.getElementById(viz.id);
      let newleft =
        parseInt(event.clientX + parseInt(offset[0], 10)) /
        container.clientWidth;
      newleft = newleft * 100;

      let newtop =
        parseInt(event.clientY + parseInt(offset[1], 10)) /
        container.clientHeight;
      newtop = newtop * 100;

      let settings = viz.designSettings;
      if (
        settings.textboxes.length > 0 &&
        offset[2] &&
        settings.textboxes[offset[2]]
      ) {
        settings.textboxes[offset[2]].left = newleft;
        settings.textboxes[offset[2]].top = newtop;
      }

      let newviz = { ...viz };
      newviz.designSettings = settings;
      setVizCopy(newviz);
    }

    event.preventDefault();

    return false;
  }

  function getAddOn() {
    let it = idAddOn ? idAddOn : "normal";
    if (expand) {
      it += "expanded";
    }
    return it;
  }

  const addOn = getAddOn();

  const score = viz.type === MetricType || viz.type === ValueType;

  const tableContainerStyle = {
    height: isDragging ? height : "fit-content",
    maxHeight: height,
  };

  function getTitleContainerStyle() {
    return {
      alignItems: viz.designSettings.titleAlignment,
      backgroundColor: viz.designSettings.titleBackgroundColor,
      borderRadius: viz.designSettings.titleBorderRadius,
      paddingTop: viz.designSettings.paddingTopTitle + 10,
      paddingBottom: viz.designSettings.paddingBottomTitle,
      paddingLeft: viz.designSettings.paddingLeftTitle + 10,
      paddingRight: viz.designSettings.paddingRightTitle + 10,
    };
  }

  function getTitleStyle() {
    return {
      color: viz.designSettings.titleColor,
      fontSize: viz.designSettings.valueTitleSize,
    };
  }

  function onCopyClick() {
    if (showCopyChartMenu) {
      if (!closeCopyMenu) {
        setCloseCopyMenu(true);
        setTimeout(() => {
          setShowCopyChartMenu(false);
          setCloseCopyMenu(false);
          setShowPagesMenu(false);
        }, 500);
      }
    } else {
      if (onCopyToPage && pages) {
        setShowCopyChartMenu(true);
      } else {
        menuClick(() => onCopy(viz));
      }
    }
  }

  function onEdit(copy) {
    saveCopy(copy);
    setEdited(true);
  }

  function setUpQuickPivot() {
    if (setAbove) {
      console.log("hitting");
      setAbove(
        <QuickPivots
          viz={viz}
          close={() => setAbove(null)}
          filters={filters}
          custom_fields={custom_fields}
          externalFilter={externalFilter}
          onEdit={onEdit}
          onSave={() => handleSave(viz)}
          edited={edited}
        />
      );
    } else {
      setQuickPivot(true);
    }
  }

  return (
    <div
      className={`${expand ? styles.popoutBackground : styles.chartContainer} ${
        active && styles.active
      }`}
      id={inEdit ? "outside editor" : "outside " + viz.id}
      style={{
        boxShadow: viz.designSettings.containerShadow === false ? "none" : "",
        padding: score || viz.type === TableType ? "0px" : "",
      }}
    >
      {!thumbnail && !closeAboveExpand && (
        <>
          {!openSettings && !quickPivot && (
            <div
              className={styles.settingsBtn}
              // style={{ opacity: active ? "1" : "" }}
              onClick={(e) => {
                e.stopPropagation();
                setOpenSettings(!openSettings);
              }}
            >
              <Icon iconName={"three-dots"}></Icon>
            </div>
          )}

          {openSettings && (
            <div
              className={styles.settingsMenu}
              ref={ref}
              onMouseLeave={() => setOpenSettings(!openSettings)}
              onClick={(e) => e.stopPropagation()}
            >
              {!inEdit && canEdit && (
                <>
                  <div
                    className={styles.menuItem}
                    onClick={() =>
                      menuClick(() => {
                        setEditing(true);
                        setCurrViz(viz);
                      })
                    }
                  >
                    <i
                      className="bi bi-hammer"
                      style={{ color: "#15BCC7" }}
                    ></i>
                    Edit
                  </div>
                  {!score && (
                    <div
                      className={`${styles.menuItem} ${styles.quickPivot}`}
                      onClick={() => menuClick(() => setUpQuickPivot())}
                    >
                      <i
                        className="bi bi-wrench"
                        style={{ color: "#15BCC7" }}
                      ></i>
                      Quick Pivot
                    </div>
                  )}
                </>
              )}
              {getRole.data.role.canSeeContactInfo === true && !score && (
                <div
                  className={styles.menuItem}
                  onClick={() => menuClick(setUpSeeData)}
                >
                  <i
                    className="bi bi-list-columns-reverse"
                    style={{ color: "#15BCC7" }}
                  ></i>
                  See Data
                </div>
              )}

              {!inDash && canSave && canEdit && onCopy && !inEdit && (
                <div className={styles.menuItem} onClick={onCopyClick}>
                  <i className="bi bi-printer" style={{ color: "#15BCC7" }}></i>
                  Copy Chart
                </div>
              )}
              {showCopyChartMenu && (
                <div
                  className={`${styles.copyMenu} ${
                    closeCopyMenu ? styles.closeMenu : ""
                  } `}
                >
                  <div
                    className={`${styles.menuItem}`}
                    onClick={() => menuClick(() => onCopy(viz))}
                  >
                    {" "}
                    <i
                      className="bi bi-arrow-down"
                      style={{ color: "#15BCC7" }}
                    ></i>
                    Right Below
                  </div>
                  <div
                    className={`${styles.menuItem} ${
                      showPagesMenu ? styles.toPage : ""
                    }`}
                    style={{ gap: "10px" }}
                    onClick={() => setShowPagesMenu(!showPagesMenu)}
                  >
                    <i
                      className="bi bi-arrow-90deg-right"
                      style={{ color: "#15BCC7" }}
                    ></i>
                    To page
                  </div>
                  {showPagesMenu && (
                    <>
                      {pages.map((page) => (
                        <div
                          className={`${styles.menuItem} ${styles.pageMenuItem}`}
                          onClick={() =>
                            menuClick(() => onCopyToPage(viz, page.id))
                          }
                          key={page.id}
                        >
                          <span>{page.name}</span>
                        </div>
                      ))}
                      <div
                        className={`${styles.menuItem} ${styles.pageMenuItem} ${styles.newPage}`}
                        onClick={() => menuClick(() => onCopyToPage(viz, ""))}
                      >
                        + New Page
                      </div>
                    </>
                  )}
                </div>
              )}

              {!inDash &&
                getRole.isSuccess &&
                getRole.data.role.canViewDashboardTab &&
                (getRole.data.role.canEditPersonalDashboard ||
                  getRole.data.role.canEditOrgDashboard ||
                  getRole.data.role.canEditRoleDashboards) && (
                  <div
                    className={styles.menuItem}
                    onClick={() => menuClick(() => setAddingToDash(true))}
                  >
                    <i
                      className="bi bi-speedometer2"
                      style={{ color: "#15BCC7" }}
                    ></i>
                    Copy to Dash
                  </div>
                )}

              {!expand && !closeAboveExpand && (
                <div
                  className={styles.menuItem}
                  onClick={() => menuClick(setUpPopout)}
                >
                  <i
                    className="bi bi-arrows-angle-expand"
                    style={{ color: "#15BCC7" }}
                  ></i>
                  Expand
                </div>
              )}

              {canDelete && (
                <div
                  className={styles.menuItem}
                  onClick={() => menuClick(deleteVisualization)}
                >
                  <i className="bi bi-trash3" style={{ color: "#FF8878" }}></i>
                  Delete
                </div>
              )}
            </div>
          )}
          {quickPivot && (
            <QuickPivots
              viz={viz}
              close={() => setQuickPivot(false)}
              filters={filters}
              custom_fields={custom_fields}
              externalFilter={externalFilter}
              onEdit={onEdit}
              onSave={() => handleSave(viz)}
              edited={edited}
            />
          )}
        </>
      )}
      <div
        className={`${expand ? styles.popout : styles.asChart}`}
        style={{
          ...(expand
            ? {
                paddingTop: viz.designSettings.paddingTop + 10,
                paddingBottom: viz.designSettings.paddingBottom + 10,
                paddingLeft: viz.designSettings.paddingLeft + 5,
                paddingRight: viz.designSettings.paddingRight + 5,
              }
            : {
                paddingTop: viz.designSettings.paddingTop,
                paddingBottom: viz.designSettings.paddingBottom,
                paddingLeft: viz.designSettings.paddingLeft,
                paddingRight: viz.designSettings.paddingRight,
              }),
          ...(thumbnail ? { zoom: "50%" } : {}),
        }}
      >
        {expand && (
          <div className={styles.exitBtn} onClick={closePopout}>
            <i className="bi bi-x"></i>
          </div>
        )}

        {closeAboveExpand && (
          <div className={styles.exitBtn} onClick={closeAboveExpand}>
            <i className="bi bi-x"></i>
          </div>
        )}
        {getRole.isSuccess && (
          <>
            {!configure && (
              <div
                className={styles.vizDisplay}
                id={viz.id}
                onDragOver={
                  inEdit &&
                  viz.designSettings.textboxes &&
                  viz.designSettings.textboxes.length
                    ? drag_over
                    : undefined
                }
                onDrop={
                  inEdit &&
                  viz.designSettings.textboxes &&
                  viz.designSettings.textboxes.length
                    ? drop
                    : undefined
                }
              >
                {!score && (
                  <>
                    {viz.type === TableType && (
                      <>
                        {!data && (
                          <TableViz
                            viz={viz}
                            inEdit={inEdit}
                            newChart={newChart}
                            setAbove={setAbove}
                            custom_fields={custom_fields}
                            height={expand ? undefined : height}
                            role={getRole.data.role}
                            seeData={seeData || seeDataInDash}
                            closeSeeData={
                              seeDataInDash
                                ? closeSeeDataInDash
                                : () => setSeeData(false)
                            }
                            setVizCopy={setVizCopy}
                            setOutsideData={setData}
                            setUpOutsideDataCounter={setUpDataCounter}
                            update={update}
                            filters={filters}
                            filterSubtitle={filterSubtitle}
                            reDraw={reDraw}
                          />
                        )}{" "}
                        {data && (
                          <TableChart
                            initHeaders={
                              getRole.data.role.canSeeContactInfo
                                ? data.headers
                                : data.headers.slice(3, 4)
                            } //TODO Needs to be secured better
                            data={data.dataArray}
                            asChart
                            tableTitle={
                              viz.designSettings.hasTitle
                                ? viz.titleValue
                                  ? viz.titleValue
                                  : viz.title
                                : ""
                            }
                            titleContainerStyle={getTitleContainerStyle()}
                            titleStyle={getTitleStyle()}
                            subtitle={filterSubtitle ? filterSubtitle : false}
                            tableSort={viz.designSettings?.tableSort}
                            color={viz.designSettings?.tableColor}
                            height={height}
                          />
                        )}
                      </>
                    )}
                    {viz.type !== TableType && (
                      <>
                        {!data && (
                          <>
                            {viz.dataMethod === "survey" && (
                              <SurveyStatChart
                                viz={viz}
                                refetch={refetch}
                                inEdit={inEdit}
                                setLabelInfo={setLabelInfo}
                                newChart={newChart}
                                setAbove={setAbove}
                                custom_fields={custom_fields}
                                update={update}
                                height={height}
                                reDraw={reDraw}
                                role={getRole.data.role}
                                seeData={seeData}
                                closeSeeData={() => setSeeData(false)}
                                idAddOn={addOn}
                                filters={filters}
                                filterSubtitle={filterSubtitle}
                                setVisibleItems={setVisibleItems}
                                editing={editing}
                                visible={visibleItems}
                                active={active}
                                setCurrViz={setCurrViz}
                                visibleItems={visibleItems}
                              />
                            )}
                            {(!viz.dataMethod ||
                              viz.dataMethod === "question") && (
                              <QuestionChart
                                viz={viz}
                                setOutsideData={setData}
                                setUpOutsideDataCounter={setUpDataCounter}
                                setLabelInfo={setLabelInfo}
                                newChart={newChart}
                                setAbove={setAbove}
                                custom_fields={custom_fields}
                                thumbnail={thumbnail}
                                update={update}
                                height={height}
                                reDraw={reDraw}
                                role={getRole.data.role}
                                seeData={seeData || seeDataInDash}
                                closeSeeData={
                                  seeDataInDash
                                    ? closeSeeDataInDash
                                    : () => setSeeData(false)
                                }
                                idAddOn={addOn}
                                filters={filters}
                                filterSubtitle={filterSubtitle}
                                inEdit={inEdit}
                                setVisibleItems={setVisibleItems}
                                editing={editing}
                                visible={visibleItems}
                                active={active}
                                setCurrViz={setCurrViz}
                                showDrill={showDrill}
                                setShowDrill={setShowDrill}
                              />
                            )}
                          </>
                        )}
                        {data && (
                          <div
                            className={styles.chartWithTitle}
                            id={viz.id + idAddOn}
                          >
                            {viz.designSettings.hasTitle && (
                              <div
                                className={styles.titleContainer}
                                id={"title for " + viz.id + idAddOn}
                                style={{
                                  minHeight: thumbnail ? "25px" : "",
                                  justifyContent:
                                    viz.designSettings.titleAlignment,
                                  backgroundColor:
                                    viz.designSettings.titleBackgroundColor,
                                  borderRadius:
                                    viz.designSettings.titleBorderRadius,
                                  paddingTop:
                                    viz.designSettings.paddingTopTitle,
                                  paddingBottom:
                                    viz.designSettings.paddingBottomTitle,
                                  paddingLeft:
                                    viz.designSettings.paddingLeftTitle,
                                  paddingRight:
                                    viz.designSettings.paddingRightTitle,
                                }}
                              >
                                <div
                                  className={styles.title}
                                  style={{
                                    color: viz.designSettings.titleColor,
                                    fontSize: viz.designSettings.valueTitleSize,
                                    textAlign:
                                      viz.designSettings.titleAlignment,
                                  }}
                                >
                                  {viz.title}
                                </div>
                                {filterSubtitle &&
                                  viz.designSettings.hasSubtitle &&
                                  combinedQs(viz).length > 0 && (
                                    <div className={styles.subtitle}>
                                      {filterSubtitle}
                                    </div>
                                  )}
                              </div>
                            )}

                            <Chart
                              data={data}
                              viz={viz}
                              idAddOn={idAddOn}
                              setVisibleItems={setVisibleItems}
                              editing={editing}
                              visible={visibleItems}
                              active={active}
                              setCurrViz={setCurrViz}
                              visibleItems={visibleItems}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
                {score && (
                  <>
                    <ScoreChart
                      viz={viz}
                      filters={filters}
                      filterSubtitle={filterSubtitle}
                      outsideData={data}
                      setOutsideData={setData}
                      setUpOutsideDataCounter={setUpDataCounter}
                      width={width}
                      height={height}
                    />
                  </>
                )}

                {viz.designSettings.textboxes &&
                  viz.designSettings.textboxes.map((box, index) => (
                    <TextBox
                      key={index}
                      viz={viz}
                      box={box}
                      settings={viz.designSettings}
                      index={index}
                      setOffsetData={setOffsetData}
                      inEdit={inEdit}
                      filters={filters}
                    ></TextBox>
                  ))}
              </div>
            )}
            {/* {!inEdit && configure && (
              <ConfigureBox
                visualization={viz}
                onSave={handleSave}
                onClose={closeConfigBox}
                lockData={lockData}
                canSave={canSave}
                custom_fields={custom_fields}
                externalFilter={externalFilter}
                height={height}
                // width={width}
                width={document.getElementById("outside " + viz.id).offsetWidth}
                saving={saving}
              />
            )} */}
          </>
        )}

        {addingToDash && (
          <AddToWidget
            onClose={() => setAddingToDash(false)}
            viz={{ ...viz, orgId: getRole.data.role?.organizationId }}
            forCharts
          />
        )}
      </div>
    </div>
  );
}

function VizPreview({ viz }) {
  function getData() {
    if (
      viz.type != MetricType &&
      viz.type != TableType &&
      typeof viz.data === "string"
    ) {
      return JSON.parse(viz.data);
    }

    return viz.data;
  }

  function getTitle() {
    if (!viz.designSettings.hasTitle) {
      return "";
    }
    return viz.title;
  }

  const isScore = viz.type == ValueType || viz.type === MetricType;

  return (
    <div
      className={`${styles.chartContainer}`}
      style={{
        boxShadow: viz.designSettings.containerShadow === false ? "none" : "",
        padding: viz.type != ValueType ? "" : "0px",
        borderRadius: "3em",
      }}
    >
      {viz.type !== TableType && !isScore && (
        <div className={styles.chartWithTitle} id={viz.id + "expanded"}>
          {viz.designSettings.hasTitle && (
            <div
              className={styles.titleContainer}
              id={"title for " + viz.id + shortId()}
              style={{
                justifyContent: viz.designSettings.titleAlignment,
                backgroundColor: viz.designSettings.titleBackgroundColor,
                borderRadius: viz.designSettings.titleBorderRadius,
                paddingTop: viz.designSettings.paddingTopTitle,
                paddingBottom: viz.designSettings.paddingBottomTitle,
                paddingLeft: viz.designSettings.paddingLeftTitle,
                paddingRight: viz.designSettings.paddingRightTitle,
              }}
            >
              <div
                className={styles.title}
                style={{
                  color: viz.designSettings.titleColor,
                  fontSize: viz.designSettings.valueTitleSize,
                }}
              >
                {getTitle()}
              </div>
            </div>
          )}
          {getData() && (
            <Chart
              data={getData()}
              // onSegClick={onSegClick}
              viz={viz}
              // thumbnail={thumbnail}
              idAddOn={shortId()}
 
              // reDraw={reDraw}
              // update={update}
              // saveData={saveData}
            />
          )}
        </div>
      )}

      {isScore && <ScoreChart viz={viz} preview />}
      {viz.type === TableType && (
        <div className={styles.mocktableContainer}>
          <div className={styles.thumbnailtext}>{getTitle()}</div>
          <div className={styles.mocktable}>
            <div
              className={styles.mockrowfirst}
              style={{ backgroundColor: viz.designSettings.tableColor }}
            ></div>
            <div className={styles.mockrow}></div>
            <div className={styles.mockrow}></div>
            <div className={styles.mockrow}></div>
            <div className={styles.mockrow}></div>
            <div className={styles.mockrow}></div>
            <div className={styles.mockrow}></div>
            <div className={styles.mockrow}></div>
            <div className={styles.mockrow}></div>
          </div>
        </div>
      )}
    </div>
  );
}
