import styles from "./MatrixSettings.module.scss";
import QSettingsStyles from "../QuestionSettings.module.scss";
import { ChangeQuestionType, questionTypes } from "../QuestionSettings";
import {
  MultChoice,
  Scale,
  Text,
} from "pages/results/Charts/Settings/AccordianItems/SurveyData/QData";
import { ScaleSettings } from "../Scale/ScaleSettings";
import { ChoiceSettings } from "../Choice/ChoiceSettings";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { Label } from "components/layouts/Label/Label";
import { TextFieldSimple } from "components/inputs";
import { TextSettings } from "../Text/TextSettings";
import NumberInputWithSlider from "components/NumberInputWithSlider/NumberInputWithSlider";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";

export function MatrixSettings({
  question,
  saveQuestion,
  dependents,
  setUpLogicResolver,
  onRetract,
}) {
  const currentType =
    question.choiceQuestion && question.choiceQuestion?.isRanking
      ? questionTypes.find((t) => t.value === "Ranking")
      : questionTypes.find((t) => t.value === question.matrixQuestion.type);

  function handleChangeType(type) {
    if (question.matrixQuestion.type !== type) {
      if (dependents) {
        setUpLogicResolver(
          "change the type of matrix on",
          "edit",
          question,
          () => finishChangingType(type)
        );
      } else {
        finishChangingType(type);
      }
    } else if (
      question.choiceQuestion &&
      type === "MultipleChoice" &&
      question.choiceQuestion.isRanking
    ) {
      // won't affect dependents
      finishChangingType(type);
    }
  }

  function finishChangingType(type) {
    question.matrixQuestion.type = type;
    if (type === "MultipleChoice") {
      question.choiceQuestion = {
        isMultiSelect: false,
        isRandomized: false,
        hasOtherOption: false,
        isRanking: false,
        otherOptionLabel: "Other",
        limit: null,
        choices: question.choiceQuestion?.choices
          ? question.choiceQuestion.choices
          : [],
      };
      question.scaleQuestion = null;
      question.textQuestion = null;
    } else if (type === "NumberScale" || type === "NumberSlider") {
      question.scaleQuestion = {
        min: 1,
        minDescription: "",
        max: 5,
        maxDescription: "",
        step: 1,
      };
      question.choiceQuestion = null;
      question.textQuestion = null;
    } else if (type === "Text") {
      question.textQuestion = {
        placeholder: "Enter Text",
        maxLength: 1000,
      };
      question.choiceQuestion = null;
      question.scaleQuestion = null;
    } else if (type === "Ranking") {
      question.choiceQuestion = {
        isMultiSelect: false,
        isRandomized: false,
        isRanking: true,
        hasOtherOption: false,
        otherOptionLabel: "Other",
        limit: null,
        choices: question.choiceQuestion?.choices
          ? question.choiceQuestion.choices
          : [],
      };
      question.matrixQuestion.type = "MultipleChoice";
      question.scaleQuestion = null;
      question.textQuestion = null;
    }
    question.isScore = false;
    question.isComment = false;
    question.questionTag = [];

    question.matrixQuestion.heading = null;
    question.matrixQuestion.settings = {};

    saveQuestion(question);
    onRetract(question.id);
  }

  function changeSetting(field, val) {
    question.matrixQuestion.settings[field] = val;
    saveQuestion(question);
  }

  // function changeScaleSetting(field, val) {
  //   question.matrixQuestion.settings[field] = val;
  //   saveQuestion(question);
  // }

  const matrix = question.matrixQuestion;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "10px",
      }}
    >
      {/* <ChangeQuestionType
        types={questionTypes.filter((t) => t.value !== "Matrix")}
        onSelect={handleChangeType}
        current={currentType}
        label="Matrix Type"
      /> */}
      <CombinedInput
        label="Matrix Type"
        value={currentType}
        options={questionTypes.filter((t) => t.value !== "Matrix")}
        select
        onChange={(t) => handleChangeType(t?.value)}
        icon={"bi-chevron-down"}
      />
      {matrix.type === MultChoice && (
        <ChoiceSettings question={question} saveQuestion={saveQuestion} />
      )}
      {matrix.type === Text && (
        <TextSettings
          question={question}
          saveQuestion={saveQuestion}
          dependents={dependents}
          setUpLogicResolver={setUpLogicResolver}
        />
      )}
      {matrix.type === Scale && (
        <>
          <ScaleSettings
            question={question}
            saveQuestion={saveQuestion}
            dependents={dependents}
            setUpLogicResolver={setUpLogicResolver}
          />

          <NumberInputWithSlider
            val={
              matrix.settings.scaleSize || matrix.settings.scaleSize === 0
                ? matrix.settings.scaleSize
                : 5
            }
            onChange={(val) => changeSetting("scaleSize", val)}
            min={0}
            max={50}
            step={1}
            color={"#15bcc7"}
            title="Scale Answer Size"
          ></NumberInputWithSlider>

          <div className={styles.matrixSettings}>
            <div className={styles.toggleSetting}>
              {" "}
              <ToggleSwitch
                handleCheck={(val) => changeSetting("showNumbers", val)}
                startChecked={matrix.settings.showNumbers}
              />
              <div className={styles.text_3}>Show Numbers</div>
            </div>

            {matrix.settings.showNumbers && (
              <div
                className={styles.toggleSetting}
                style={{ paddingLeft: "10px" }}
              >
                {" "}
                <ToggleSwitch
                  handleCheck={(val) => changeSetting("hideNumbers", val)}
                  startChecked={matrix.settings.hideNumbers}
                />
                <div className={styles.text_3}>{`Hide ${
                  matrix.settings.transpose ? "Side" : "Top"
                } Numbers`}</div>
              </div>
            )}
          </div>
        </>
      )}
      {matrix.type === "NumberSlider" && (
        <>
          <ScaleSettings
            question={question}
            saveQuestion={saveQuestion}
            dependents={dependents}
            setUpLogicResolver={setUpLogicResolver}
          />
          <div
            className={styles.toggleSetting}
            style={{ paddingLeft: "1.5em" }}
          >
            {" "}
            <ToggleSwitch
              handleCheck={(val) => changeSetting("hideNumbers", val)}
              startChecked={matrix.settings.hideNumbers}
            />
            <Label>{`Hide ${
              matrix.settings.transpose ? "Side" : "Top"
            } Numbers`}</Label>
          </div>
        </>
      )}
      <div className={styles.matrixSettings}>
        <div className={styles.toggleSetting}>
          {" "}
          <ToggleSwitch
            handleCheck={(val) => changeSetting("hideLines", val)}
            startChecked={matrix.settings.hideLines}
          />
          <Label>Hide Lines</Label>
        </div>
        {!matrix.settings.hideLines && (
          <>
            <div
              className={styles.toggleSetting}
              style={{ paddingLeft: "10px" }}
            >
              {" "}
              <ToggleSwitch
                handleCheck={(val) => changeSetting("hideX", val)}
                startChecked={matrix.settings.hideX}
              />
              <Label>Hide X</Label>
            </div>
            <div
              className={styles.toggleSetting}
              style={{ paddingLeft: "10px" }}
            >
              {" "}
              <ToggleSwitch
                handleCheck={(val) => changeSetting("hideY", val)}
                startChecked={matrix.settings.hideY}
              />
              <Label>Hide Y</Label>
            </div>
          </>
        )}

        {matrix.type === MultChoice && (
          <>
            <div className={styles.toggleSetting}>
              {" "}
              <ToggleSwitch
                handleCheck={(val) => changeSetting("transpose", val)}
                startChecked={matrix.settings.transpose}
              />
              <Label>Transpose</Label>
            </div>

            {matrix.settings.transpose &&
              (matrix.type === Scale || matrix.type === "NumberSlider") && (
                <div
                  className={styles.toggleSetting}
                  style={{ paddingLeft: "10px" }}
                >
                  {" "}
                  <ToggleSwitch
                    handleCheck={(val) => changeSetting("flipNumbers", val)}
                    startChecked={matrix.settings.flipNumbers}
                  />
                  <Label>High to Low</Label>
                </div>
              )}
          </>
        )}
      </div>
    </div>
  );
}
