import React, { useState, useRef } from "react";
import styles from "./ToFrom.module.scss";
import Modal from "components/ReactModal/ReactModal";
import Audience from "pages/contacts/audiences/Audience";
import { useGetCurrentUser } from "api/resources/organization/users";
import { useNavigate } from "react-router-dom";

export function To({ chosen, options, onChange, disable, shadow }) {
  const [showOptions, setShowOptions] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchOptions, setSearchOptions] = useState();
  const [showAud, setShowAud] = useState();

  const selectRef = useRef(null);
  const searchRef = useRef(null);
  const getCurrUser = useGetCurrentUser();

  function toggleDropDown(e) {
    if (showOptions) {
      if (!searchRef.current || !searchRef.current.contains(e.target)) {
        setShowOptions(false);
      }
    } else {
      setShowOptions(true);
      document.addEventListener("click", handleClickOutside, true);
    }
  }

  const handleClickOutside = (event) => {
    if (!selectRef.current || !selectRef.current.contains(event.target)) {
      setShowOptions(false);
      searchRef.current = false;
      document.removeEventListener("click", handleClickOutside, true);
      search("");
    }
  };

  function search(userInput) {
    if (userInput) {
      let results = [];
      for (let option of options) {
        if (option.name?.toLowerCase()?.includes(userInput?.toLowerCase())) {
          results.push(option);
        }
      }
      setSearchOptions(results);
    } else {
      setSearchOptions(null);
    }
    setSearchValue(userInput);
  }

  function handleRemoveOption(e, i) {
    e.stopPropagation();
    let copy = [...chosen];
    copy.splice(i, 1);
    onChange(copy);
  }

  function getBackColor() {
    if (showOptions) {
      return "white";
    }
    return "";
  }

  function getHeight() {
    if (options.length <= 6) {
      return options.length * 40 + "px";
    } else {
      return "";
    }
  }

  function onOptionClick(option) {
    let ind = chosen.findIndex((a) => a.id === option.id);
    if (ind > -1) {
      let copy = [...chosen.slice(0, ind), ...chosen.slice(ind + 1)];
      onChange(copy);
    } else {
      onChange([...chosen, ...[option]]);
    }
  }

  function showAudience(aud) {
    setShowAud(aud);
  }

  const navigate = useNavigate();

  function onManage() {
    navigate("/audiences");
    localStorage.setItem("activepage", 4);
  }

  // TODO: Sometime do a create new audience modal, where they can select contacts they already have, or upload a file or manual entry, etc...

  return (
    <>
      <div className={`${styles.container} `} ref={selectRef}>
        <div
          className={`${styles.select}  ${
            showOptions || shadow ? styles.shadow : ""
          }`}
          onClick={toggleDropDown}
          style={{
            pointerEvents: disable ? "none" : "",
            backgroundColor: getBackColor(),
          }}
        >
          <div className={styles.text}>
            <div className={styles.label6}>Send To:</div>
            <div className={styles.selectMultiple}>
              {chosen?.map((aud, i) => (
                <div
                  className={styles.chosenAudience}
                  key={i}
                  onClick={() => showAudience(aud)}
                >
                  <div className={styles.chosenAudienceName}>
                    {aud.name}
                    <span className={styles.members}>{aud.members}</span>
                    <i
                    className={`bi-x ${styles.remove}`}
                    onClick={(e) => handleRemoveOption(e, i)}
                  ></i>
                  </div>
                
                </div>
              ))}
            </div>
            {showOptions && (
              <div
                style={{
                  display: "flex",
                  gap: ".5em",
                  alignItems: "center",
                  paddingTop: "3px",
                }}
              >
                <input
                  className={`${styles.input}`}
                  value={searchValue}
                  placeholder={"Search..."}
                  onChange={(e) => search(e.target.value)}
                  ref={searchRef}
                  onClick={
                    !showOptions ? () => setShowOptions(true) : undefined
                  }
                />
              </div>
            )}
          </div>
          <i className={`${styles.icon} bi bi-chevron-down`}></i>
        </div>

        {showOptions && (
          <div
            className={styles.dropdown}
            style={{
              maxHeight: getHeight(),
            }}
          >
            {!searchValue && (
              <div
                className={`${styles.option} ${styles.manageAudiences}`}
                onClick={onManage}
              >
                <div className={styles.manage}>
                  Manage Audiences <i className="bi bi-gear"></i>
                </div>
              </div>
            )}

            {(searchOptions ? searchOptions : options).map((option, i) => (
              <div
                key={i}
                className={styles.option}
                onClick={() => onOptionClick(option)}
              >
                <div className={styles.audienceOptionName}>
                  {option.name}{" "}
                  <span className={styles.members}>{option.members}</span>
                </div>

                {chosen.some((a) => a.id === option.id) && (
                  <div className={styles.checked}>
                    <i className="bi-check"></i>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      {getCurrUser.isSuccess && showAud && (
        <Modal
          show={showAud}
          onClose={() => setShowAud(null)}
          modalStyle={{
            borderRadius: "1em",
            height: "97%",
            width: "97%",
            maxWidth: "1400px",
            overflow: "hidden",
          }}
          dark
        >
          <Audience
            user={getCurrUser.data.me}
            audienceId={showAud?.id}
            preview
            // customFields={orgFields?.data?.getCurrOrgFields}
          ></Audience>
        </Modal>
      )}
    </>
  );
}
