import React, { useEffect, useRef } from "react";
import styles from "./SurveyDesign.module.scss";
import { PageStyle } from "./PageStyle";
import { FinishedSettings } from "./FinishedSettings";
import { Sidebar2 } from "components/sidebars";
import { PageNavigation } from "./PageNavigation";
import Icon from "components/Icon/Icon";
import Themes from "./Themes";
import { QuestionSettings } from "../QuestionSettings/QuestionSettings";
import HeaderSettings from "./HeaderSettings";
import PageNavSettings from "./PageNavSettings";
import SectionSettings from "../SectionSettings/SectionSettings";

const menuItems = [
  {
    id: "style",
    name: "Global Style",
    // to: `survey-details`,
    icon: "bi bi-brush",
  },
  {
    id: "themes",
    name: "Themes & Background",
    // to: `survey-details`,
    icon: "bi bi-image",
  },
  {
    id: "finished",
    name: "Finished",
    // to: `survey-details`,
    icon: "bi bi-check-circle",
  },
  {
    id: "header",
    name: "Page Header",
    // to: `survey-details`,
    icon: "bi bi-card-heading",
  },
  {
    id: "nav",
    name: "Page Navigation",
    // to: `survey-details`,
    icon: "bi bi-stickies-fill",
  },
];

export function SurveyDesign({
  settings,
  setSettings,
  activeTab,
  setActiveTab,
  numPages,
  setGetAll,
  currPage,
  setCurrPage,
  allQuestions,
  onDeletePage,
  addPage,
  switchPages,
  status,
  active,
  setActive,
  onRetract,
  setUpLogicResolver,
  deleteQ,
  onEditQuestion,
  question,
  dependents,
  setActiveQ,
  setActiveId,
  runSave,
  projId,
  seeAll,
  setSeeAll,
  setLogic,
  showTags,
  setShowTags,
  setShowTriggers,
}) {
  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setActiveTab("");
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  function changeSettingsField(setting, value) {
    let tempSettings = { ...settings };
    tempSettings[setting] = value;
    if (tempSettings?.theme != "custom") {
      tempSettings.theme = "custom";
    }
    setSettings(tempSettings);
  }

  function updateActive(id) {
    setActive(id);
    setActiveId("");
    setActiveQ("");
  }

  return (
    <div className={styles.designContainer}>
      <Sidebar2
        menuItems={menuItems}
        active={active}
        updateActive={updateActive}
        condensed
        style={{ paddingTop: "5rem" }}
      ></Sidebar2>

      <div
        className={styles.designContent}
        style={{ width: active ? "18rem" : "" }}
      >
        {active === "header" && (
          <HeaderSettings
            settings={settings}
            changeSetting={changeSettingsField}
            setSettings={setSettings}
            projId={projId}
          ></HeaderSettings>
        )}
        {active === "page" && (
          <HeaderSettings
            settings={settings}
            changeSetting={changeSettingsField}
          ></HeaderSettings>
        )}
        {/* {active === "editLogo" && (
          <SurveyLogoSettings
            settings={settings}
            changeSettingsField={changeSettingsField}
            setSettings={setSettings}
            projId={projId}
          ></SurveyLogoSettings>
        )} */}
        {/* 
        {active === "progress" && (
          <ProgressSettings
            settings={settings}
            setSettings={(s) => {
              setSettings(s);
            }}
          ></ProgressSettings>
        )} */}

        {active === "question" && (
          <QuestionSettings
            question={question}
            saveQuestion={(q) => {
              onEditQuestion(q);
            }}
            onCancel={deleteQ}
            dependents={dependents}
            setUpLogicResolver={setUpLogicResolver}
            onRetract={onRetract}
            allQuestions={allQuestions}
            setShowLogic={setLogic}
            showTags={showTags}
            setShowTags={setShowTags}
            setShowTriggers={setShowTriggers}
          ></QuestionSettings>
        )}

        {active === "section" && (
          <SectionSettings
            section={question}
            saveSection={onEditQuestion}
            onCancel={deleteQ}
            allQuestions={allQuestions}
            setShowLogic={setLogic}
          ></SectionSettings>
        )}

        {active === "style" && (
          <PageStyle
            active={activeTab === "PageStyle"}
            setActive={setActiveTab}
            settings={settings}
            changeSetting={(field, value) => {
              changeSettingsField(field, value);
            }}
          ></PageStyle>
        )}

        {/* {active === "nav" && (
          <PageNavSettings
            settings={settings}
            changeSetting={changeSettingsField}
            page={currPage}
            maxPage={numPages}
          ></PageNavSettings>
        )} */}

        {active === "themes" && (
          <Themes
            settings={settings}
            changeSetting={changeSettingsField}
            setSettings={setSettings}
          ></Themes>
        )}
        {active === "finished" && (
          <FinishedSettings
            active={activeTab === "Finished"}
            setActive={setActiveTab}
            settings={settings}
            changeSetting={changeSettingsField}
          ></FinishedSettings>
        )}
        {active === "nav"  && (
          <>
            <PageNavigation
              active={true}
              setActive={setActiveTab}
              settings={settings}
              setSettings={setSettings}
              changeSetting={changeSettingsField}
              numPages={numPages}
              setGetAll={setGetAll}
              currPage={currPage}
              setCurrPage={setCurrPage}
              allQuestions={allQuestions}
              onDeletePage={onDeletePage}
              addPage={addPage}
              switchPages={switchPages}
              status={status}
            ></PageNavigation>
  
          </>
        )}
      </div>
      {active != 0 && (
        <Icon
          iconName={"chevron-left"}
          style={{ position: "absolute", top: "1rem", right: "1rem" }}
          onClick={() => setActive(0)}
        ></Icon>
      )}
    </div>
  );
}
