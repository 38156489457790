import React, { useEffect, useState } from "react";
import styles from "./CustomReport.module.scss";
import { useParams } from "react-router-dom";
import { Loading } from "components/Loading/Loading";
import {
  useFetchReport,
  useFetchReportDraft,
  useFetchUserReportRole,
} from "api/resources/organization/reports";
import Report from "./Report";
import { useFetchCurrOrgsFields } from "api/resources/organization/organization";
import { shortId } from "components/tables/EditableTable/utils";

export const CustomReport = ({
  roles,
  organization,
  user,
  collapse,
  collapsed,
  basic,
}) => {
  const { reportid } = useParams();
  const fetchCustomFields = useFetchCurrOrgsFields();
  const [editing, setEditing] = useState(
    localStorage.getItem("analysis-editing-" + reportid) ? true : false
  );
  const [currViz, setCurrViz] = useState();
  function switchToDraft() {
    setEditing(true);
  }

  return (
    <div className={styles.pageContainer}>
      {reportid && fetchCustomFields.isSuccess && (
        <ReportContainer
          reportid={reportid}
          roles={roles}
          custom_fields={fetchCustomFields.data.getCurrOrgFields}
          org={organization}
          user={user}
          collapse={collapse}
          collapsed={collapsed}
          editing={editing}
          setEditing={setEditing}
          switchToDraft={switchToDraft}
          currViz={currViz}
          setCurrViz={setCurrViz}
          basic={basic}
        ></ReportContainer>
      )}
      {/* {editing && reportid && fetchCustomFields.isSuccess && (
        <ReportContainerDraft
          reportid={reportid}
          roles={roles}
          custom_fields={fetchCustomFields.data.getCurrOrgFields}
          org={organization}
          user={user}
          collapse={collapse}
          collapsed={collapsed}
          editing={editing}
          setEditing={setEditing}
          switchToDraft={switchToDraft}
          currViz={currViz}
          setCurrViz={setCurrViz}
          basic={basic}
        ></ReportContainerDraft>
      )} */}
    </div>
  );
};

const ReportContainer = ({
  reportid,
  roles,
  custom_fields,
  org,
  user,
  collapse,
  collapsed,
  editing,
  setEditing,
  switchToDraft,
  currViz,
  setCurrViz,
  basic,
}) => {
  const fetchReport = useFetchReport(reportid);
  function checkRole(canEdit) {
    if (canEdit === null) {
      return roles.canCreateEditOrgReports;
    }
    if (canEdit === false) {
      return false;
    }
    if (canEdit === true) {
      return true;
    }
  }

  const role = useFetchUserReportRole(reportid);

  return (
    <>
      {fetchReport.isLoading && <Loading />}
      {fetchReport.isError && <div>error...</div>}
      {fetchReport.isSuccess && role.isSuccess && (
        <>
          {editing && (
            <div style={{ width: "100%", display: "flex" }}>
              <Report
                report={fetchReport.data.report}
                roles={roles}
                org={org}
                custom_fields={custom_fields}
                refetchReport={() => fetchReport.refetch()}
                canEdit={checkRole(role?.data?.canEdit)}
                user={user}
                organization={org}
                collapse={collapse}
                collapsed={collapsed}
                editing={editing}
                setEditing={setEditing}
                switchToDraft={switchToDraft}
                currViz={currViz}
                setCurrViz={setCurrViz}
                basic={basic}
              ></Report>
            </div>
          )}
          {!editing && (
            <Report
              report={fetchReport.data.report}
              roles={roles}
              org={org}
              custom_fields={custom_fields}
              refetchReport={() => fetchReport.refetch()}
              canEdit={checkRole(role?.data?.canEdit)}
              user={user}
              organization={org}
              collapse={collapse}
              collapsed={collapsed}
              editing={editing}
              setEditing={setEditing}
              switchToDraft={switchToDraft}
              currViz={currViz}
              setCurrViz={setCurrViz}
              basic={basic}
            ></Report>
          )}
        </>
      )}
    </>
  );
};
