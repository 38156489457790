import React, { useState, useRef, useEffect } from "react";
import { SketchPicker } from "react-color";
import { Popover } from "@mui/material";
import styles from "./ColorPicker.module.scss";
import Button from "components/Button/Button";
import {
  ChartColors,
  SurveyColors,
  TextColors,
} from "assets/functions/ColorFunctions";

/**
 * A color picker component
 * @param {Array} colors something
 * @param {String} defaultColor something
 * @param {Function} onChange a function that is called when a color is chosen
 * @returns {React.ReactElement} a simple color picker component
 */

export const ColorPicker = ({
  remove,
  colors = [],
  defaultColor = "#ffffff",
  onChange,
  setShow,
  disable,
  colorRef,
  textColors,
  show,
  height,
  width,
  anchorRef,
}) => {
  const [color, setColor] = useState(defaultColor);
  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useRef();

  useEffect(() => {
    setColor(defaultColor);
  }, [defaultColor]);

  const defaultColors = [
    // "#2a627c",
    // "#a4c6d0",
    // "#15bcc7",
    // "#7FCFD3",
    // "#b5e1df",
    // "#ed9146",
    // "#edb57e",
    // "#F4E3C2",
    // "#738c91",
    // "#8dabb2",
    // "#616565",
    // "#a3a4a8",
    // "#d8d9d9",
    // "#E9E9E9",
    // "#FFFFFF",
    ...colors,
    ...SurveyColors,
  ];

  const textTones = [
    ...defaultColors.slice(0, defaultColors.length - 2),
    ...TextColors,
  ];

  const handleOpen = () => {
    if (setShow && !disable) {
      setShow(true);
    }
    if (!disable) {
      setShowPicker(true);
    }
  };

  const handleClose = () => {
    if (setShow) {
      setShow(false);
    }

    setShowPicker(false);
  };

  const handleColorChange = (color) => {
    setColor(color.hex);
    if (onChange) {
      onChange(color.hex);
    }
  };

  const white = typeof color === "string" && color.toLowerCase() === "#ffffff";

  return (
    <div style={{ cursor: disable ? "" : "pointer" }}>
      <div
        ref={pickerRef}
        style={{
          height: height ? height : "1.25em",
          width: width ? width : "1.25em",
          border: white ? "1px solid #cdcdcd" : undefined,
          borderRadius: "5px",
          backgroundColor: color,
          position: "relative",
        }}
        onClick={handleOpen}
      ></div>
      {(showPicker || show) && (
        <Popover
          open={showPicker || show}
          ref={colorRef}
          anchorEl={anchorRef?.current ? anchorRef?.current : pickerRef.current}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          PaperProps={{
            sx: { backgroundColor: "transparent", boxShadow: "none" },
          }}
        >
          <div className={styles.sketchPicker}>
            <SketchPicker
              color={color}
              presetColors={textColors ? textTones : defaultColors}
              // onChange={handleColorChange}  // Causes it to switch twice and glitch
              onChangeComplete={handleColorChange}
            />
            {remove && (
              <div className={styles.delete}>
                <Button
                  onClick={() => {
                    remove();
                    setShowPicker(false);
                  }}
                  height={30}
                >
                  Delete Color
                </Button>
              </div>
            )}
          </div>
        </Popover>
      )}
    </div>
  );
};
