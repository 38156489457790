import { Label } from "components/layouts/Label/Label";
import styles from "../SettingsAccordion.module.scss";

import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";
import {
  DoughnutType,
  HorizBarType,
  LineType,
  MetricType,
  TableType,
  ValueType,
  VertBarType,
} from "../../QuestionChart";
import { NumSlider } from "components/inputs/input_fields/NumSlider/NumSlider";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import NumberInputWithSlider from "components/NumberInputWithSlider/NumberInputWithSlider";

export const LayoutSettings = ({
  viz,
  title,
  changeSettingsField,
  visible,
  setVisible,
}) => {
  function handleBorderRadiusChange(val) {
    changeSettingsField("borderRadius", val);
  }

  function handleBarWidthChange(val) {
    val = val / 10;
    changeSettingsField("barPercentage", val);
  }

  function handleArcWidthChange(val) {
    // if (val === 10) {
    //   val = 0;
    // } else {
    //   val = (val - 10) * -1;
    //   val *= 10;
    // }

    val *= 10;
    changeSettingsField("cutout", val);
  }

  function getCutoutVal() {
    // if (viz.designSettings.cutout === 0) {
    //   return 10;
    // }
    // if (viz.designSettings.cutout) {
    //   return 10 - viz.designSettings.cutout / 10;
    // }
    // return 5;
    return (viz.designSettings.cutout ? viz.designSettings.cutout : 0) / 10;
  }

  return (
    <>
      <div
        key={viz.id}
        className={`${styles.header} ${visible ? styles.headervisible : ""}`}
        onClick={!visible ? setVisible : undefined}
      >
        <FlexRow
          onClick={visible ? setVisible : undefined}
          style={{ cursor: "pointer" }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
            {title} <i style={{ fontSize: "1.2em" }} className="bi-columns"></i>
          </div>

          <span className={styles.accordionicon}>
            <i className="bi bi-caret-left-fill"></i>
          </span>
        </FlexRow>
        {visible && (
          <>
            <div
              className={styles.setting2}
              style={{ paddingLeft: "10px", marginTop: "5px", gap: "0em" }}
            >
              <ToggleSwitch
                startChecked={
                  viz.designSettings.containerShadow === false ? false : true
                }
                handleCheck={(val) =>
                  changeSettingsField("containerShadow", val)
                }
              ></ToggleSwitch>
              <Label
                italics
                style={{
                  width: "fit-content",
                  fontSize: ".8em",
                }}
              >
                <div
                  className={styles.borderShadowLabel}
                  style={
                    !viz.designSettings.containerShadow
                      ? { boxShadow: "none" }
                      : undefined
                  }
                >
                  Shadow
                </div>
              </Label>
            </div>

            <div
              className={styles.setting}
              style={{
                marginTop: "5px",
                gap: "0em",
                justifyContent: "center",
                paddingRight: "0px",
              }}
            >
              <Label
                italics
                style={{
                  width: "fit-content",
                  fontSize: ".8em",
                  padding: "0",
                }}
              >
                Padding
              </Label>
              <div
                className={styles.setting}
                style={{
                  // paddingLeft: "1.5em",
                  padding: "0px",
                  marginTop: "5px",
                  gap: ".5em",
                }}
              >
                <div
                  className={`${styles.itemB} `}
                  style={{ flexDirection: "column" }}
                >
                  <i className="bi-arrow-bar-up"></i>
                  <NumberInput
                    startNumber={
                      viz.designSettings?.paddingTop
                        ? viz.designSettings.paddingTop
                        : 0
                    }
                    handleNumberChange={(val) =>
                      changeSettingsField("paddingTop", val)
                    }
                    min={0}
                    max={500}
                  ></NumberInput>
                </div>
                <div
                  className={styles.setting2}
                  style={{ gap: ".5em", paddingRight: "0px" }}
                >
                  {" "}
                  <div className={`${styles.itemB} `}>
                    <i className="bi-arrow-bar-left"></i>
                    <NumberInput
                      startNumber={
                        viz.designSettings?.paddingLeft
                          ? viz.designSettings.paddingLeft
                          : 0
                      }
                      handleNumberChange={(val) =>
                        changeSettingsField("paddingLeft", val)
                      }
                      min={0}
                      max={500}
                    ></NumberInput>
                  </div>
                  <div className={`${styles.itemB} `}>
                    <NumberInput
                      startNumber={
                        viz.designSettings?.paddingRight
                          ? viz.designSettings.paddingRight
                          : 0
                      }
                      handleNumberChange={(val) =>
                        changeSettingsField("paddingRight", val)
                      }
                      min={0}
                      max={500}
                    ></NumberInput>
                    <i className="bi-arrow-bar-right"></i>
                  </div>
                </div>
                <div
                  className={`${styles.itemB} `}
                  style={{ flexDirection: "column" }}
                >
                  <NumberInput
                    startNumber={
                      viz.designSettings?.paddingBottom
                        ? viz.designSettings.paddingBottom
                        : 0
                    }
                    handleNumberChange={(val) =>
                      changeSettingsField("paddingBottom", val)
                    }
                    min={0}
                    max={500}
                  ></NumberInput>
                  <i className="bi-arrow-bar-down"></i>
                </div>
              </div>
            </div>
            {viz.type != MetricType &&
              viz.type != ValueType &&
              viz.type != TableType && (
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <NumberInputWithSlider
                    val={
                      viz.designSettings.zoomOut
                        ? viz.designSettings.zoomOut
                        : 0
                    }
                    min={0}
                    max={15}
                    step={1}
                    onChange={(val) => changeSettingsField("zoomOut", val)}
                    color={"#2A627C"}
                    title={"Zoom"}
                  ></NumberInputWithSlider>
                </div>
              )}

            {viz.type === DoughnutType && (
              <div
                style={{
                  width: "100%",
                }}
              >
                <NumberInputWithSlider
                  val={getCutoutVal()}
                  min={0}
                  max={9}
                  step={1} //can you do .5?
                  onChange={handleArcWidthChange}
                  color={"#2A627C"}
                  title={"Cut Out Width"}
                ></NumberInputWithSlider>
              </div>
            )}

            {viz.type === LineType && (
              <>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <NumberInputWithSlider
                    val={viz.designSettings.pointRadius}
                    min={0}
                    max={5}
                    step={1}
                    onChange={(val) => changeSettingsField("pointRadius", val)}
                    color={"#2A627C"}
                    title={"Point Size"}
                  ></NumberInputWithSlider>
                </div>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <NumberInputWithSlider
                    val={viz.designSettings.lineGraphWidth}
                    min={0}
                    max={5}
                    step={1}
                    onChange={(val) =>
                      changeSettingsField("lineGraphWidth", val)
                    }
                    color={"#2A627C"}
                    title={" Line Width"}
                  ></NumberInputWithSlider>
                </div>
              </>
            )}
            {viz.type === TableType && (
              <div
                className={styles.setting2}
                style={{ gap: "10px", paddingLeft: "10px" }}
              >
                <ColorPicker
                  defaultColor={viz.designSettings.tableColor}
                  onChange={(val) => changeSettingsField("tableColor", val)}
                />
                <Label
                  style={{
                    width: "fit-content",
                  }}
                >
                  Table Color
                </Label>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
