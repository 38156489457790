import React, { useEffect, useState } from "react";
import { Label } from "components/layouts/Label/Label";
import styles from "./SectionSettings.module.scss";
import Button from "components/Button/Button";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import ColorPickerContainer from "components/inputs/input_fields/ColorPicker/ColorPickerContainer";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import { shadows } from "assets/functions/Variables";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import { QuestionLogic } from "../QuestionLogic/QuestionLogic";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Icon from "components/Icon/Icon";

export default function SectionSettings({
  section,
  saveSection,
  allQuestions,
  setShowLogic
}) {
  // const sectionTypes = {
  //   FormRegistration: {
  //     label: "Form Registration",
  //     value: "Form Registration",
  //     name: "Form Registration",
  //     icon: <i className="bi bi-list-check"></i>,
  //   },
  //   Description: {
  //     label: "Description",
  //     value: "Description",
  //     name: "Description",
  //     icon: <i className="bi bi-chat-left-text"></i>,
  //   },
  // };

  function setSetting(field, val) {
    section.sectionSettings[field] = val;
    saveSection(section);
  }
  const [groups, setGroups] = useState();

  useEffect(() => {
    if (section?.conditionGroups) {
      setGroups(JSON.parse(JSON.stringify(section?.conditionGroups)));
    } else {
      setGroups([]);
    }
  }, [section]);

  return (
    <div className={styles.body}>
      <div
        className={styles.section_label6}
        style={{ margin: "0", width: "fit-content", color: "#15bcc7" }}
      >
        Section Settings
        <HorizontalBar
          height={2}
          style={{ marginTop: ".1rem" }}
          backgroundColor={"#15bcc7"}
        ></HorizontalBar>
      </div>

      {/* <div className={styles.settingVertical}>
        <CombinedInput
          onChange={handleChangeType}
          value={section?.type}
          label="Section Type"
          icon="bi-chevron-down"
          options={Object.values(sectionTypes)}
          select
          anyWhereOptions
        />{" "}
      </div> */}

      <div className={styles.settingVertical}>
        <div className={styles.settingHorizontal}>
          <ToggleSwitch
            startChecked={section.sectionSettings?.hasBackgroundColor}
            handleCheck={(val) =>
              setSetting("hasBackgroundColor", val ? "#FFFFFF" : false)
            }
          ></ToggleSwitch>

          <div className={styles.text_3}>Has Background Color</div>
        </div>

        {section.sectionSettings?.hasBackgroundColor && (
          <div className={styles.settingVertical}>
            <ColorPickerContainer
              onChange={(color) => setSetting("hasBackgroundColor", color)}
              defaultColor={
                section.sectionSettings?.hasBackgroundColor
                  ? section.sectionSettings?.hasBackgroundColor
                  : "#FFFFFF"
              }
              title="Background Color"
              //   colors={settings.colorBank}
            />
          </div>
        )}
      </div>
      <div className={styles.settingVertical}>
        <CombinedInput
          onChange={(color) => setSetting("sectionShadow", color.value)}
          value={
            section.sectionSettings?.sectionShadow
              ? shadows.find(
                  (s) => s.value === section.sectionSettings?.sectionShadow
                )
              : { value: "", label: "No Shadow" }
          }
          label="Section Border Shadow"
          icon="bi-chevron-down"
          options={shadows}
          select
          anyWhereOptions
        />{" "}
      </div>

      <div className={styles.body}>
        <FlexRow start gap={0}>
          <Icon iconName={"signpost-fill"} seafoam></Icon>
          <div
            className={styles.label5}
            style={{ margin: "0", fontWeight: "500" }}
          >
            Text Logic
          </div>
        </FlexRow>
        {groups && groups.length > 0 && (
          <FlexRow start gap={0}>
            <Icon iconName={"check-circle"} green></Icon>
            <div
              className={styles.section_label6}
              style={{ margin: "0", fontWeight: "500" }}
            >
              Has Logic
            </div>
          </FlexRow>
        )}
        <FlexRow style={{ justifyContent: "center" }} gap={0}>
          <Button shadow onClick={() => setShowLogic(true)}>
            Edit Logic
          </Button>
        </FlexRow>
      </div>
    </div>
  );
}
