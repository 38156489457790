import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { TextFieldSimple } from "components/inputs";
import React, { useState } from "react";
import styles from "../SettingsAccordion.module.scss";
import { Label } from "components/layouts/Label/Label";
import { ValueType } from "../../QuestionChart";
import NumberInputWithSlider from "components/NumberInputWithSlider/NumberInputWithSlider";
import { combinedQs } from "../../Visualization";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import ColorPickerContainer from "components/inputs/input_fields/ColorPicker/ColorPickerContainer";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import { fonts } from "assets/functions/Variables";

//custom accordian
export const TitleSettings = ({
  viz,
  title,
  updateViz,
  changeVizField,
  changeSettingsField,
  visible,
  setVisible,
}) => {
  // const [visibility, setVisibility] = useState(false);

  // const toggleVisibility = () => {
  //   setVisibility((v) => !v);
  // };

  const [showHint, setShowHint] = useState(false);
  const [oldTitle, setOldTitle] = useState();
  const [hasCustomTitle, setHasCustomTitle] = useState(
    !viz.designSettings.artificialTitle
  );

  function genTitle() {
    let titles = [];
    let title = "";
    for (let q of combinedQs(viz)) {
      if (!titles.includes(q.questionText)) {
        if (title.length > 0) {
          title += " | ";
        }
        title += q.questionText;
        titles.push(q.questionText);
      }
    }
    return title;
  }

  function onTitleEntry(val, encoding) {
    // debugger;
    if (viz.designSettings.artificialTitle) {
      changeSettingsField("artificialTitle", false);
    }

    let newViz = { ...viz };
    newViz["titleValue"] = val;
    updateViz(newViz);
  }

  const revertBtn = oldTitle
    ? () => {
        setOldTitle(null);
        changeSettingsField("artificialTitle", false);
        changeVizField("title", oldTitle);
      }
    : () => {
        setOldTitle(viz.title);
        changeSettingsField("artificialTitle", true);
        changeVizField("title", genTitle());
      };

  function changeTitleSize(val) {
    // Make it be .2, .3 .5
    changeSettingsField("titleFontSize", val);
  }

  function titleChange(val) {
    let newViz = { ...viz };
    newViz.title = val;
    newViz.titleValue = val;
    newViz.designSettings.artificialTitle = false;
    updateViz(newViz);
  }

  function showFilterSubtitle() {
    if (viz.filters) {
      let filters = JSON.parse(viz.filters);
      return Object.keys(filters).length > 0;
    }
    return false;
  }

  return (
    <>
      <div
        key={"titles"}
        className={`${styles.header} ${visible ? styles.headervisible : ""}`}
        onClick={!visible ? setVisible : undefined}
      >
        {/* {title} */}
        <FlexRow
          onClick={(e) => {
            e.stopPropagation();
            setVisible(false);
          }}
          style={{ cursor: "pointer" }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
            {title}{" "}
            <i style={{ fontSize: "1.2em" }} className="bi bi-fonts"></i>
          </div>

          <span className={styles.accordionicon}>
            <i className="bi bi-caret-left-fill"></i>
          </span>
        </FlexRow>

        {visible && (
          <>
            <div className={styles.setting2} style={{ paddingLeft: "5px" }}>
              <ToggleSwitch
                startChecked={viz.designSettings.hasTitle}
                handleCheck={(val) => changeSettingsField("hasTitle", val)}
              ></ToggleSwitch>
              <Label
                style={{
                  width: "fit-content",
                  fontSize: ".9em",
                }}
              >
                Chart Title
              </Label>
            </div>
            {viz.designSettings.hasTitle && (
              <>
                {/* {hasCustomTitle && (
                <div className={styles.setting}>
                  <Label
                    style={{
                      fontWeight: "700",
                      width: "fit-content",
                      paddingLeft: "20px",
                      fontSize: ".9em",
                    }}
                    labelIcon={
                      viz.designSettings.artificialTitle ? null : (
                        <i className="bi bi-info-square"></i>
                      )
                    }
                    tooltipText={genTitle()}
                  >
                    <div className={styles.linkButton} onClick={revertBtn}>
                      {oldTitle ? (
                        <>
                          <i className="bi bi-arrow-counterclockwise"></i>
                          {` Undo`}
                        </>
                      ) : (
                        `Use Question as Title`
                      )}
                    </div>
                  </Label>
                </div>
              )} */}

                <TextFieldSimple
                  value={viz.title}
                  onChange={titleChange}
                  style={{ fontSize: ".9rem" }}
                  label="Title Text"
                ></TextFieldSimple>

                <div style={{ width: "100%" }}>
                  <NumberInputWithSlider
                    val={
                      viz.designSettings.valueTitleSize
                        ? viz.designSettings.valueTitleSize
                        : 17
                    }
                    min={5}
                    max={60}
                    step={1}
                    onChange={(val) =>
                      changeSettingsField("valueTitleSize", val)
                    }
                    title="Title Font Size"
                  ></NumberInputWithSlider>
                </div>
                <CombinedInput
                  select
                  label="Title Font"
                  options={fonts}
                  value={
                    viz?.designSettings?.titleFont
                      ? fonts.find(
                          (v) => v.value === viz?.designSettings?.titleFont
                        )
                      : fonts.find((v) => v.value === "Poppins, sans-serif")
                  }
                  onChange={(v) =>
                    changeSettingsField("titleFont", v.value)
                  }
                  icon={"bi-chevron-down"}
                />
                <ColorPickerContainer
                  defaultColor={
                    viz.designSettings.titleColor
                      ? viz.designSettings.titleColor
                      : "#ffffff"
                  }
                  onChange={(val) => changeSettingsField("titleColor", val)}
                  textColors
                  title="Title Color"
                  style={{
                    backgroundColor: "transparent",
                    padding: "0",
                    margin: "0",
                  }}
                ></ColorPickerContainer>
                <ColorPickerContainer
                  defaultColor={
                    viz.designSettings.titleBackgroundColor
                      ? viz.designSettings.titleBackgroundColor
                      : "#ffffff"
                  }
                  onChange={(val) =>
                    changeSettingsField("titleBackgroundColor", val)
                  }
                  textColors
                  title="Title Background Color"
                  style={{
                    backgroundColor: "transparent",
                    padding: "0",
                    margin: "0",
                  }}
                ></ColorPickerContainer>
                <div style={{ width: "100%" }}>
                  <NumberInputWithSlider
                    val={
                      viz.designSettings.titleBorderRadius
                        ? viz.designSettings.titleBorderRadius
                        : 0
                    }
                    min={0}
                    max={60}
                    step={1}
                    onChange={(val) =>
                      changeSettingsField("titleBorderRadius", val)
                    }
                    color={"#2A627C"}
                    title={"Title Border Radius"}
                  ></NumberInputWithSlider>
                </div>

                {viz.type != ValueType && (
                  <div
                    className={styles.setting}
                    style={{
                      marginTop: "5px",
                      gap: "0em",
                      justifyContent: "center",
                    }}
                  >
                    <div className={styles.label6}>Title Alignment</div>
                    <div
                      className={styles.setting2}
                      style={{
                        paddingLeft: "1.5em",
                        marginTop: "5px",
                        gap: ".5em",
                        width: "fit-content",
                        fontSize: "1rem",
                      }}
                    >
                      <div
                        className={`${styles.itemA} ${
                          viz.designSettings.titleAlignment === "start" &&
                          styles.activeItem
                        } `}
                        onClick={() =>
                          changeSettingsField("titleAlignment", "start")
                        }
                      >
                        <i className="bi-text-left"></i>
                      </div>
                      <div
                        className={`${styles.itemA} ${
                          viz.designSettings.titleAlignment === "center" &&
                          styles.activeItem
                        } `}
                        onClick={() =>
                          changeSettingsField("titleAlignment", "center")
                        }
                      >
                        <i className="bi-text-center"></i>
                      </div>
                      <div
                        className={`${styles.itemA} ${
                          viz.designSettings.titleAlignment === "end" &&
                          styles.activeItem
                        } `}
                        onClick={() =>
                          changeSettingsField("titleAlignment", "end")
                        }
                      >
                        <i className="bi-text-right"></i>
                      </div>
                    </div>
                  </div>
                )}
                {viz.type != ValueType && (
                  <div
                    className={styles.setting}
                    style={{
                      marginTop: "5px",
                      gap: "0em",
                      justifyContent: "center",
                    }}
                  >
                    <div className={styles.label6}>Title Padding</div>
                    <div
                      className={styles.setting}
                      style={{
                        paddingLeft: "1.5em",
                        marginTop: "5px",
                        gap: ".5em",
                      }}
                    >
                      <div
                        className={`${styles.itemB} `}
                        style={{ flexDirection: "column" }}
                      >
                        <i className="bi-arrow-bar-up"></i>
                        <NumberInput
                          startNumber={
                            viz.designSettings?.paddingTopTitle
                              ? viz.designSettings.paddingTopTitle
                              : 0
                          }
                          handleNumberChange={(val) =>
                            changeSettingsField("paddingTopTitle", val)
                          }
                          min={0}
                          max={500}
                        ></NumberInput>
                      </div>
                      <div className={styles.setting2} style={{ gap: ".5em" }}>
                        {" "}
                        <div className={`${styles.itemB} `}>
                          <i className="bi-arrow-bar-left"></i>
                          <NumberInput
                            startNumber={
                              viz.designSettings?.paddingLeftTitle
                                ? viz.designSettings.paddingLeftTitle
                                : 0
                            }
                            handleNumberChange={(val) =>
                              changeSettingsField("paddingLeftTitle", val)
                            }
                            min={0}
                            max={500}
                          ></NumberInput>
                        </div>
                        <div className={`${styles.itemB} `}>
                          <NumberInput
                            startNumber={
                              viz.designSettings?.paddingRightTitle
                                ? viz.designSettings.paddingRightTitle
                                : 0
                            }
                            handleNumberChange={(val) =>
                              changeSettingsField("paddingRightTitle", val)
                            }
                            min={0}
                            max={500}
                          ></NumberInput>
                          <i className="bi-arrow-bar-right"></i>
                        </div>
                      </div>
                      <div
                        className={`${styles.itemB} `}
                        style={{ flexDirection: "column" }}
                      >
                        <NumberInput
                          startNumber={
                            viz.designSettings?.paddingBottomTitle
                              ? viz.designSettings.paddingBottomTitle
                              : 0
                          }
                          handleNumberChange={(val) =>
                            changeSettingsField("paddingBottomTitle", val)
                          }
                          min={0}
                          max={500}
                        ></NumberInput>
                        <i className="bi-arrow-bar-down"></i>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {/* <div className={styles.setting2}>
            <Label
              style={{
                width: "fit-content",
                paddingLeft: "20px",
                fontSize: ".9em",
              }}
              labelIcon={<i className="bi bi-info-square"></i>}
            >
              Show Filter Subtitle
            </Label>
            <ToggleSwitch
              startChecked={viz.designSettings.hasSubtitle}
              handleCheck={(val) => changeSettingsField("hasSubtitle", val)}
            ></ToggleSwitch>
          </div> */}
            {showFilterSubtitle() && (
              <div className={styles.setting2}>
                <ToggleSwitch
                  startChecked={viz.designSettings.showFilterSubtitle}
                  handleCheck={(val) =>
                    changeSettingsField("showFilterSubtitle", val)
                  }
                ></ToggleSwitch>
                <Label
                  style={{
                    width: "fit-content",
                    fontSize: ".9em",
                  }}
                >
                  Show Filter Subtitle
                </Label>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
