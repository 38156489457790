// External
import React, { useEffect, useState } from "react";
import styles from "./SigBuilder.module.scss";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import { TextFieldSimple } from "components/inputs";
import { Label } from "components/layouts/Label/Label";
import { TextArea } from "components/inputs/input_fields/TextArea/TextArea";
import Button from "components/Button/Button";
import parse from "html-react-parser";
import {
  useCreateDistConfig,
  useDeleteDistConfig,
  useUpdateDistConfig,
} from "api/resources/projects/distributions";
import { Loading } from "components/Loading/Loading";
import { OneOrTheOther } from "components/inputs/input_fields/OneOrTheOther/OneOrTheOther";
import EmailEditor from "pages/projects/project/delivery/distributions/EmailBuilder/EmailEditor";
import Content from "pages/projects/project/delivery/distributions/EmailBuilder/Content/Content";
import { useFetchCurrOrgsFields } from "api/resources/organization/organization";

// Internal

export const SigBuilder = ({
  orig,
  onClose,
  refetch,
  orgLevel,
  isDefault,
  makeDefault,
  userId,
}) => {
  const [sig, setSig] = useState({ ...orig });
  const [loading, setLoading] = useState(false);
  const [html, setHtml] = useState(sig.signature ? true : false); // TODO: Switch it back to false. Going to only have builder going forward.
  const [content, setContent] = useState(false);
  const updateDistConfig = useUpdateDistConfig();
  const createDistConfig = useCreateDistConfig();
  const deleteDistConfig = useDeleteDistConfig();
  const fetchCustomFields = useFetchCurrOrgsFields();

  function changeValue(field, value) {
    let copy = { ...sig };
    copy[field] = value;
    setSig(copy);
  }

  function handleUpdateSignature(sig) {
    setLoading(true);

    if (sig.id) {
      let data = {
        signature: sig?.signature,
        encoding: sig?.encoding,
        aliasName: sig?.aliasName,
        fromAddress: sig?.fromAddress,
      };
      updateDistConfig.mutate(
        {
          data: data,
          id: orig.id,
        },
        {
          onSuccess: () => {
            if (refetch) {
              refetch();
            }
            setLoading(false);
          },
        }
      );
    } else {
      if (sig.aliasName && sig.fromAddress) {
        let data = {
          signature: sig?.signature,
          aliasName: sig?.aliasName,
          fromAddress: sig?.fromAddress,
          type: "signature",
          encoding: sig?.encoding,
          userId: userId ? userId : "",
        };
        createDistConfig.mutate(
          {
            data: data,
          },
          {
            onSuccess: (data) => {
              if (refetch) {
                refetch();
              }
              setLoading(false);
            },
          }
        );
      } else {
        let missing = "";
        if (!sig.aliasName) {
          missing += "an 'alias name'";
        }
        if (!sig.fromAddress) {
          if (missing) missing += " and ";
          missing += "a 'from address'";
        }

        window.alert(`Missing ${missing}.`);
        setLoading(false);
      }
    }
  }

  function onDelete() {
    if (
      window.confirm(
        "Are you sure you want to delete this signature? It will NOT affect emails with this signature already in it."
      )
    ) {
      deleteDistConfig.mutate(
        { id: orig.id },
        {
          onSuccess: (data) => {
            if (data.deleted) {
              if (refetch) {
                refetch();
              }
              onClose();
            }
          },
        }
      );
    }
  }

  function onToggleHTML() {
    let copy = { ...sig };
    if (html) {
      copy.signature = "";
    } else {
      copy.encoding = "";
    }
    setHtml(!html);
    setSig(copy);
  }

  function saveEncoding(text, code, html) {
    changeValue("encoding", code);
  }

  return (
    <>
      <ReactModal
        show={sig}
        onClose={onClose}
        modalStyle={{
          borderRadius: "1em",
          padding: "1em",
          gap: "1em",
          height: "96%",
          width: "96%",
          maxWidth: "1200px",
        }}
      >
        <>
          <TextFieldSimple
            value={sig?.aliasName}
            onChange={(val) => changeValue("aliasName", val)}
            label="Alias Name"
            placeholder="Type Alias..."
          />
          <TextFieldSimple
            value={sig?.fromAddress}
            onChange={(val) => changeValue("fromAddress", val)}
            label="From Address (Email)"
            placeholder="Type Email..."
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "100%",
              flexGrow: 1,
            }}
          >
            <div className={styles.sigLabel}>
              Signature
              <OneOrTheOther
                checked={html}
                onCheck={onToggleHTML}
                left="Builder"
                right="HTML"
                activeColor="#738c91"
              />
            </div>

            {html && (
              <div className={styles.html}>
                <TextArea
                  value={sig?.signature}
                  onSave={(val) => changeValue("signature", val)}
                  placeholder="Paste HTML Signature..."
                  className={styles.thearea}
                />
                <div className={styles.htmlPreview}>
                  {parse(`<div>${sig?.signature}</div>`)}
                </div>
              </div>
            )}

            {!html && (
              <>
                {fetchCustomFields.isLoading && <Loading />}
                {fetchCustomFields.isSuccess && (
                  <div className={styles.sigBuilderContainer}>
                    <div className={styles.content}>
                      <Content content={content} setContent={setContent} />
                    </div>

                    <EmailEditor
                      encoding={sig.encoding}
                      onSave={saveEncoding}
                      editable
                      incoming={content}
                      clearIncoming={() => setContent("")}
                      customFields={fetchCustomFields.data.getCurrOrgFields}
                    />
                  </div>
                )}
                {/* <div style={{ padding: "1em" }}>
                  {parse(`<div>${sig?.signature}</div>`)}
                </div> */}
              </>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "1em",
            }}
          >
            {isDefault && !orgLevel && (
              <div className={styles.thisIsDefault}>Default signature*</div>
            )}
            <Button height={35} shadow seafoam onClick={onClose}>
              Cancel
            </Button>
            {orig.id && (
              <Button red height={35} shadow onClick={onDelete}>
                <div className={styles.buttonSpace}>
                  Delete <i className="bi bi-trash" />
                </div>
              </Button>
            )}

            {!isDefault && !orgLevel && makeDefault && sig.id && (
              <Button height={35} shadow blue onClick={makeDefault}>
                Make Default
              </Button>
            )}

            <Button
              height={35}
              shadow
              onClick={
                loading
                  ? undefined
                  : () => {
                      handleUpdateSignature(sig);
                    }
              }
            >
              <div className={styles.buttonSpace}>
                {loading ? (
                  <>
                    Saving <Loading height={20} width={20}></Loading>
                  </>
                ) : (
                  <>Save Changes</>
                )}
              </div>
            </Button>
          </div>
          {/* {!isActive && !orgLevel && (
          <div>
            <Button
              height={35}
              shadow
              onClick={() => {
                handleUpdateActiveSig(sig);
              }}
            >
              Set as main signature
            </Button>
          </div>
        )} */}

          {/* {!isDefault && !orgLevel && } */}
        </>
      </ReactModal>
    </>
  );
};
