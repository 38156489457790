import React, { useEffect, useRef, useState } from "react";
import {
  useCreateAnalysis,
  useFetchAnalysis,
  useUpdateAnalysis,
  useDeleteAnalysis,
  useUpdateAnalysisFilter,
  useCopyAnalysis,
} from "api/resources/organization/analysis";
import {
  useCopyReport,
  useIsReportPinned,
  useUpdateTiedSurveys,
  useUpdateReport,
  useDeleteReport,
  usePublishDraft,
} from "api/resources/organization/reports";
import Button from "components/Button/Button";
import { TextFieldSimple } from "components/inputs";
import { Label } from "components/layouts/Label/Label";
import { Loading } from "components/Loading/Loading";
import Analysis from "pages/results/Analysis/Analysis";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./Report.module.scss";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from "@dnd-kit/modifiers";
import { Share } from "./Share";
import AddToWidget from "pages/dashboard/AddToDashPopouts/AddToWidget";
import DownloadView from "components/DownloadView/DownloadView";
import {
  useFix,
  useSearchProjectsForChart,
} from "api/resources/projects/projects";
import { PageFilter } from "./PageFilter";
import IconShopper from "../Charts/Settings/AccordianItems/MetricSettings/IconShopper/IconShopper";
import { forEach } from "assets/functions/ArrayFunctions";
import { TextArea } from "components/inputs/input_fields/TextArea/TextArea";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { combinedProjIds } from "../Charts/Visualization";
import ConfigureBox from "../Charts/Settings/ConfigureBox";
import { useUpdateVisualization } from "api/resources/projects/visualizations";
import { Sidebar2 } from "components/sidebars";
import { trimDate } from "assets/functions/DateFunctions";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import Icon from "components/Icon/Icon";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import fontSize from "components/inputs/input_fields/suneditor/src/plugins/submenu/fontSize";
import { MetricType, ValueType } from "../Charts/QuestionChart";
import ReportStyles from "./ReportStyles";

const tagOptions = [
  {
    value: "Survey Results",
    label: "Survey Results",
  },
  {
    value: "Ongoing Results",
    label: "Ongoing Results",
  },
  {
    value: "Program Results",
    label: "Program Results",
  },
  {
    value: "Raw Survey Results",
    label: "Raw Survey Results",
  },
  {
    value: "Analytics Report",
    label: "Analytics Report",
  },
  {
    value: "Info Report",
    label: "Info Report",
  },
];

const menuItems = [
  {
    id: "settings",
    name: "Report Settings",
    // to: `survey-details`,
    icon: "bi bi-gear",
  },
  // {
  //   id: "file",
  //   name: "Export Settings",
  //   // to: `survey-details`,
  //   icon: "bi bi-file-earmark",
  // },
  {
    id: "style",
    name: "Global Style",
    // to: `survey-details`,
    icon: "bi bi-brush",
  },
  // {
  //   id: "finished",
  //   name: "Finished",
  //   // to: `survey-details`,
  //   icon: "bi bi-check-circle",
  // },
  // {
  //   id: "header",
  //   name: "Page Header",
  //   // to: `survey-details`,
  //   icon: "bi bi-card-heading",
  // },
  {
    id: "nav",
    name: "Page Navigation",
    // to: `survey-details`,
    icon: "bi bi-stickies",
  },
];

export default function Report({
  report,
  roles,
  draft,
  custom_fields,
  refetchReport,
  canEdit,
  user,
  organization,
  basic,
  collapse,
  editing,
  setEditing,
  switchToDraft,
  currViz,
  setCurrViz,
}) {
  const { reportid } = useParams();
  const isReportPinned = useIsReportPinned(reportid);
  const findFirstAnal = () => {
    let pageId = localStorage.getItem("ReportPage");
    if (!report.analysis.find((a) => a.id === pageId)) {
      pageId = null;
      localStorage.setItem("ReportPage", null);
    }
    // debugger;
    if (!pageId && report?.pageOrder) {
      for (let analId of JSON.parse(report?.pageOrder)) {
        let first = report?.analysis?.find(
          (analysis) => analysis.id === analId
        ); // If the first in the page order still exists, use it
        if (first) {
          return first.id;
        }
      }
    } else if (pageId) {
      return pageId;
    }

    // doc
    return report.analysis[0]?.id;
  };

  const [currAnalysisId, setCurrAnalysisId] = useState(findFirstAnal());
  const [showAddToWidget, setShowAddToWidget] = useState(false);

  const createAnalysisPage = useCreateAnalysis();
  const [show, setShow] = useState(false);
  const updateReport = useUpdateReport();
  const [saveChanges, setSaveChanges] = useState(false);
  const [saveStatus, setSaveStatus] = useState("Saved");
  const [addChart, setAddChart] = useState("");
  const [showShare, setShowShare] = useState(false);
  const [publishLoading, setPublishLoading] = useState("");
  const [currReport, setCurrReport] = useState(report);
  const [tempReportName, setTempReportName] = useState(currReport?.name);
  const [reportContent, setReportContent] = useState(currReport.content);

  const [labels, setLabels] = useState();
  const [icons, setIcons] = useState(false);
  const [zoom, setZoom] = useState(1);

  const [visibleItems, setVisibleItems] = useState();

  // useEffect(() => {
  //   const scoreType = currViz.type === MetricType || currViz.type === ValueType;
  //   if (currViz) {
  //     setVisibleItems({
  //       SurveyData: true,
  //       ChartType: false,
  //       Axes: false,
  //       ChartSegments: false,
  //       Title: false,
  //       Legend: false,
  //       Labels: false,
  //       DataSettings: false,
  //       Filters: false,
  //       SplitsNPivots: false,
  //       MetricSurvey: false,
  //       MetricSettings: scoreType && !currViz.designSettings.metrics?.length,
  //       ScoreData: false,
  //       AdvancedData: false,
  //       ChartDetails: false,
  //       LayoutSettings: false,
  //     });
  //   } else {
  //     setVisibleItems(null);
  //   }
  // }, [currViz]);

  useEffect(() => {
    if (editing) {
      if (report?.draftId) {
        localStorage.setItem("analysis-editing-" + report?.draftId, true);
      } else {
        localStorage.setItem("analysis-editing-" + report?.id, true);
      }

      collapse("collapsed");
      localStorage.setItem("sidemenustate", "collapsed");
    } else {
      if (report?.draftId) {
        localStorage.removeItem("analysis-editing-" + report?.draftId);
      } else {
        localStorage.removeItem("analysis-editing-" + report?.id);
      }

      collapse("expanded");
      localStorage.setItem("sidemenustate", "expanded");
    }
  }, [editing]);

  const [whereto, showWhereto] = useState(false);

  useEffect(() => {
    setCurrReport(report);
  }, [report]);

  useEffect(() => {
    if (currViz) {
      setSaveStatus(
        <div style={{ display: "flex", flexDirection: "row" }}>
          Saving <Loading height={20} width={20}></Loading>
        </div>
      );
      let data = {
        updatedAt: new Date(),
      };
      updateReport.mutate(
        {
          data: data,
          id: report.id,
        },
        {
          onSuccess: (data) => {
            setSaveStatus("Saved");
            setTimeout(() => {
              setSaveChanges(false);
            }, 750);
          },
        }
      );
    }
  }, [currViz]);

  // const getWidth = () =>
  //   window.innerWidth ||
  //   document.documentElement.clientWidth ||
  //   document.body.clientWidth;
  // let [width, setWidth] = useState(getWidth());

  // useEffect(() => {
  //   const resizeListener = () => {
  //     // change width from the state object
  //     let width = getWidth();
  //     setWidth(width);
  //   };
  //   // set resize listener
  //   window.addEventListener("resize", resizeListener);

  //   // clean up function
  //   return () => {
  //     // remove resize listener
  //     window.removeEventListener("resize", resizeListener);
  //   };
  // }, []);

  let navigate = useNavigate();
  const routeChange = (path) => {
    // console.log(path);
    navigate(path);
  };

  function changeName(value) {
    // if (!saveChanges) {
    //   setSaveChanges(true);
    //   setSaveStatus("Save Changes");
    // } else if (value != currReport?.name) {
    //   setSaveChanges(true);
    //   setSaveStatus("Save Changes");
    // } else if (value === currReport?.name) {
    //   setSaveChanges(false);
    //   // setSaveStatus("Save Changes");
    // }
    // {
    //   name: tempReportName,
    //   content: currReport.content,
    //   isUser: currReport.userId != null ? true : false,
    //   type: currReport.type,
    //   icon: currReport.icon,
    // },
    let data = { name: value };
    onSave(data);
  }

  function changeContent(e) {
    let value = e.target.value;
    if (!saveChanges) {
      setSaveChanges(true);
      setSaveStatus("Save Changes");
    } else if (value != currReport.content) {
      setSaveChanges(true);
      setSaveStatus("Save Changes");
    } else if (value === currReport.content) {
      setSaveChanges(false);
      // setSaveStatus("Save Changes");
    }
    let temp = { ...currReport, ...{ content: value } };
    setCurrReport(temp);
  }

  function changeType(value) {
    // if (!saveChanges) {
    //   setSaveChanges(true);
    //   setSaveStatus("Save Changes");
    // } else if (value != currReport.type) {
    //   setSaveChanges(true);
    //   setSaveStatus("Save Changes");
    // } else if (value === currReport.type) {
    //   setSaveChanges(false);
    //   // setSaveStatus("Save Changes");
    // }
    let temp = { ...currReport, ...{ type: value } };
    setCurrReport(temp);
    let data = { type: value };
    onSave(data);
  }

  function changeIcon(i) {
    // if (!saveChanges) {
    //   setSaveChanges(true);
    //   setSaveStatus("Save Changes");
    // } else if (value != currReport.type) {
    //   setSaveChanges(true);
    //   setSaveStatus("Save Changes");
    // } else if (value === currReport.type) {
    //   setSaveChanges(false);
    //   // setSaveStatus("Save Changes");
    // }
    let temp = { ...currReport, ...{ icon: i } };
    setCurrReport(temp);

    let data = { icon: i };
    onSave(data);
    setIcons(false);
  }

  function onSave(data) {
    setSaveStatus(
      <div style={{ display: "flex", flexDirection: "row" }}>
        Saving <Loading height={20} width={20}></Loading>
      </div>
    );
    // let data = {
    //   name: tempReportName,
    //   content: currReport.content,
    //   isUser: currReport.userId != null ? true : false,
    //   type: currReport.type,
    //   icon: currReport.icon,
    // }
    updateReport.mutate(
      {
        data: data,
        id: report.id,
      },
      {
        onSuccess: (data) => {
          setSaveStatus("Saved");
          setTimeout(() => {
            setSaveChanges(false);
          }, 750);
          // let newName = {
          //   name: data.name,
          //   content: data.content,
          //   userId: data.updateReport.userId,
          //   // updatedAt: data.updatedAt
          // };
          setCurrReport({ ...currReport, ...newName });
        },
      }
    );
  }

  function changeReportStatus(status) {
    setPublishLoading(
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Loading height={20} width={20}></Loading>
      </div>
    );
    let data;
    if (status === "Published") {
      data = {
        status: status,
        organizationId: roles.organizationId,
        isUser: false,
      };
    } else {
      data = { status: status, organizationId: null, isUser: true };
    }
    updateReport.mutate(
      {
        data: data,
        id: report.id,
      },
      {
        onSuccess: (data) => {
          let newName = {
            userId: data.updateReport.userId,
            organizationId: data.updateReport.organizationId,
          };
          let newReport = { ...currReport, ...newName };
          newReport.status = status;
          setCurrReport(newReport);
          setPublishLoading("");
        },
      }
    );
  }

  function addPage(name, onComplete) {
    createAnalysisPage.mutate(
      {
        name: name,
        reportId: reportid,
      },
      {
        onSuccess: (data) => {
          onComplete(data.createAnalysis);
          localStorage.setItem("ReportPage", data.createAnalysis.id);
          setCurrAnalysisId(data.createAnalysis.id);
          if (refetchReport) {
            refetchReport();
          }
        },
      }
    );
  }

  function addReport() {
    showWhereto(true);

    // console.log(whereto);
    // if (!currReport.analysis[0]?.layout) {
    //   currReport.analysis[0].layout = "[]";
    // }
    // createReportQuery.mutate(
    //   {
    //     data: {
    //       name: currReport.name,
    //       content: currReport.content,
    //       firstAnalysis: JSON.stringify(currReport.analysis[0]),
    //       // pageOrder: currReport?.pageOrder,
    //       // status: currReport.status,
    //       // analysisIds: analysisIds,
    //       // type: type,
    //       // title: "New Analysis",
    //       // subTitle: "2022",
    //       // status: isUser === true ? null : "Draft",
    //       isUser: true,
    //     },
    //   },
    //   {
    //     onSuccess: (data) => {
    //       console.log(data);
    //       routeChange("/reports/" + data.createReport.id);
    //     },
    //   }
    // );
  }

  function fetchAnalysis(id) {
    localStorage.setItem("ReportPage", id);
    setCurrAnalysisId(id);
  }

  function onSelectPage(id) {
    if (report.analysis.some((a) => a.id === id)) {
      fetchAnalysis(id);
      setShow(false);
    }
  }

  function switchToPage(pageId) {
    fetchAnalysis(pageId);
    refetchReport();
  }

  function refetchAnalysis() {
    // fetchAnalysisReport.refetch();
  }

  const [showDownload, setShowDownload] = useState(false);
  const pageOrder =
    typeof report.pageOrder === "string"
      ? JSON.parse(report.pageOrder)
      : report?.pageOrder
      ? report?.pageOrder
      : [];

  function nextPage() {
    let index = pageOrder?.findIndex((id) => id === currAnalysisId);
    let newIndex = index + 1;
    if (newIndex > pageOrder?.length - 1) {
      newIndex = 0;
    }
    let newId = pageOrder[newIndex];
    onSelectPage(newId);
  }

  function prevPage() {
    let index = pageOrder?.findIndex((id) => id === currAnalysisId);
    let newIndex = index - 1;
    if (newIndex < 0) {
      newIndex = pageOrder?.length - 1;
    }
    let newId = pageOrder[newIndex];
    onSelectPage(newId);
  }

  return (
    <>
      <AnalysisContainer
        analysisId={currAnalysisId}
        addChart={addChart}
        setAddChart={setAddChart}
        show={show}
        setShow={setShow}
        report={currReport}
        canEdit={report.organizationId ? canEdit : true}
        roles={roles}
        onSelectPage={onSelectPage}
        setShowShare={setShowShare}
        showShare={showShare}
        custom_fields={custom_fields}
        reportName={tempReportName}
        changeName={changeName}
        user={user}
        showDownload={showDownload}
        setShowDownload={setShowDownload}
        pageOrder={pageOrder}
        organization={organization}
        basic={basic}
        goToPage={switchToPage}
        currViz={currViz}
        setCurrViz={setCurrViz}
        prevPage={prevPage}
        nextPage={nextPage}
        addPage={addPage}
        switchToPage={switchToPage}
        refetchReport={refetchReport}
        changeContent={changeContent}
        icons={icons}
        changeIcon={changeIcon}
        draft={draft}
        saveChanges={saveChanges}
        changeType={changeType}
        setIcons={setIcons}
        saveStatus={saveStatus}
        setSaveStatus={setSaveStatus}
        onSave={onSave}
        setSaveChanges={setSaveChanges}
        editing={editing}
        setEditing={setEditing}
        visibleItems={visibleItems}
        setVisibleItems={setVisibleItems}
        setLabels={setLabels}
        labels={labels}
        zoom={zoom}
        setZoom={setZoom}
        switchToDraft={switchToDraft}
      />
      {/* {report.analysis.length > 1 && !showDownload && !showShare && (
        <div className={styles.footer}>
          <div className={styles.reportFooter}>
            Page {pageOrder?.findIndex((a) => a === currAnalysisId) + 1} of{" "}
            {report.analysis.length}
            <div
              className={styles.reportSettingsButton}
              onClick={() => prevPage()}
            >
              Prev
            </div>
            <div
              className={styles.reportSettingsButton}
              onClick={() => nextPage()}
            >
              Next
            </div>
          </div>
        </div>
      )} */}
      {show && (
        <div className={styles.pageContainer}>
          <div className={styles.filler} onClick={() => setShow(false)}></div>
          <div className={styles.configBox}>
            <div
              className={styles.closeButton}
              onClick={() => {
                setShow(false);
                setSaveChanges(null);
                setReportContent(report.content);
              }}
            >
              <i className="bi bi-x-lg"></i>
            </div>

            <div className={styles.pagesTableContainer}>
              <ReportPages
                report={currReport}
                addPage={addPage}
                onSelectPage={onSelectPage}
                switchToPage={switchToPage}
                refetchAnalysis={refetchAnalysis}
                roles={roles}
                activePage={currAnalysisId}
                refetch={refetchReport}
                canEdit={canEdit}
              ></ReportPages>
            </div>
            <div style={{ padding: "0px 20px", width: "100%" }}>
              <TextFieldSimple
                label="Report Name"
                value={tempReportName}
                onSave={changeName}
                disable={!report.userId && !canEdit}
              ></TextFieldSimple>
            </div>
            {/* <div className={styles.reportSettings}> */}
            {/* <Label style={{ fontWeight: "600", marginLeft: "30px" }}>
                Report Settings
              </Label> */}

            <TieToSurvey report={report} refetch={refetchReport} />

            <div style={{ width: "100%", padding: "0px 20px" }}>
              <Label>Report Description</Label>
              <TextArea
                className={styles.textArea}
                value={currReport.content}
                onChange={changeContent}
                disabled={!report.userId && !canEdit}
                placeholder="Description..."
              ></TextArea>
            </div>

            {/* <div className={styles.pinnedNTemplate}>
              {isReportPinned.isSuccess &&
                roles.canViewDashboardTab &&
                (roles.canEditPersonalDashboard ||
                  roles.canEditOrgDashboard ||
                  roles.canEditRoleDashboards) && (
                  <div
                    className={styles.pinned}
                    onClick={() => setShowAddToWidget(true)}
                  >
                    <div>
                      {isReportPinned.data.isReportPinned.pinned
                        ? "Pinned to Dash"
                        : "Pin to Dash"}
                    </div>
                    <i
                      className={`bi ${
                        isReportPinned.data.isReportPinned.pinned
                          ? "bi-pin-fill"
                          : "bi-pin"
                      }`}
                    ></i>
                  </div>
                )}

              {roles.canCreateReportTemplate && (
                <>
                  <div
                    className={styles.reportSettingsButton}
                    onClick={
                      () =>
                        setShowTemplate(
                          !showTemplate
                        ) 
                    }
                  >
                    Use As Template
                  </div>
                  <Template
                    roles={roles}
                    report={report}
                    show={showTemplate}
                    setShow={setShowTemplate}
                  ></Template>
                </>
              )}
            </div> */}

            <CombinedInput
              options={tagOptions}
              value={{ value: report.type, label: currReport.type }}
              onChange={(obj) => changeType(obj.value)}
              label="Report Tag or Type"
            ></CombinedInput>

            <div className={styles.reportIcon}>
              <Label style={{ width: "fit-content", padding: "0em" }}>
                Report Icon
              </Label>
              <i
                className={`bi-${
                  currReport?.icon ? currReport?.icon : "bar-chart"
                }`}
                onClick={() => setIcons(!icons)}
              ></i>
            </div>
            {icons && (
              <div className={styles.iconShopper}>
                <IconShopper
                  folderView
                  graphs
                  onPick={(val) => changeIcon(val)}
                ></IconShopper>
              </div>
            )}

            {/* {report.userId && roles.seeUnPublishedReports && <Button>Make Visible To Org</Button>} */}
            {draft && (
              <>
                {" "}
                <Button height={25} onClick={addReport}>
                  Save Report
                </Button>
                <div className={styles.notifyText}>
                  *This Report is a draft. Click{" "}
                  <a onClick={addReport}>Save Report</a> to create and save this
                  draft
                </div>
              </>
            )}
            {((!report.userId && canEdit ? canEdit : roles.canViewOrgReports) ||
              (saveChanges && !draft)) && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "40px",
                  gap: "10px",
                  position: "sticky",
                  bottom: "1em",
                }}
              >
                {saveChanges && !draft && (
                  <>
                    {saveStatus === "Save Changes" && (
                      <Button
                        // height={25}
                        onClick={() => {
                          setShow(false);
                          setSaveChanges(null);
                        }}
                        shadow
                        red
                      >
                        Cancel
                      </Button>
                    )}
                    <Button
                      // height={25}
                      shadow
                      onClick={saveStatus === "Save Changes" ? onSave : null}
                    >
                      {saveStatus}
                    </Button>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {showAddToWidget && (
        <AddToWidget
          onClose={() => setShowAddToWidget(false)}
          forReports
          report={report}
          onFinished={() => isReportPinned.refetch()}
          alreadyIn={isReportPinned.data.isReportPinned.reportWidgets}
        />
      )}
    </>
  );
}

function AnalysisContainer({
  analysisId,
  addChart,
  setAddChart,
  setShow,
  show,
  report,
  canEdit,
  roles,
  onSelectPage,
  showShare,
  setShowShare,
  custom_fields,
  user,
  showDownload,
  setShowDownload,
  pageOrder,
  organization,
  basic,
  goToPage,
  currViz,
  setCurrViz,
  prevPage,
  nextPage,
  addPage,
  switchToPage,
  refetchReport,
  changeName,
  changeContent,
  reportName,
  icons,
  changeIcon,
  draft,
  saveChanges,
  changeType,
  setIcons,
  saveStatus,
  onSave,
  setSaveChanges,
  editing,
  setEditing,
  visibleItems,
  setVisibleItems,
  labels,
  setLabels,
  zoom,
  setZoom,
  switchToDraft,
  // setSaveStatus
}) {
  const fetchAnalysisReport = useFetchAnalysis(analysisId, onLoad);
  const useUpdateFilters = useUpdateAnalysisFilter();
  const [showFilter, setShowFilter] = useState(false);
  const [externalFilter, setExternalFilter] = useState();
  const [showSaveFilters, setShowSaveFilters] = useState(false);
  const [seePages, setSeePages] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showDownloads, setShowDownloads] = useState(false);
  const [showDrill, setShowDrill] = useState(false);
  const [saveDisplay, setSaveDisplay] = useState(
    <>
      Save Filters <i className="bi bi-funnel"></i>
    </>
  );

  function pdfExport() {
    // setOpenSettings(false);
    setTimeout(() => {
      let input = window.document.getElementById(analysisId);
      html2canvas(input, { scale: 5 }).then((canvas) => {
        const img = canvas.toDataURL("image/png", 1);
        // img.save("newimgage");
        const pdf = new jsPDF("p", "pt");
        console.log(input.clientHeight);
        pdf.internal.pageSize.height = input.clientHeight;
        pdf.internal.pageSize.width = input.clientWidth;
        pdf.addImage(img, "png", 0, 0, input.clientWidth, input.clientHeight);
        //give pdf a name and download.
        let str = "chart.pdf";
        str = str.replace(/\s+/g, "");
        str = str.toLocaleLowerCase();
        pdf.save(str);
      });
    }, 1000);
  }

  useEffect(() => {
    setExternalFilter("");
    setShowSaveFilters(false);
  }, [analysisId]);

  function onLoad(data) {
    if (!externalFilter && data?.analysisById?.filters) {
      setExternalFilter(data?.analysisById?.filters);
    }
  }

  function onFilterChange(filtersObj) {
    let filtersString = JSON.stringify(filtersObj);
    let ogFilters = fetchAnalysisReport.data.analysisById.filters;
    if (filtersString === ogFilters) {
      setShowSaveFilters(false);
    } else {
      setShowSaveFilters(true);
    }
    setExternalFilter(filtersString);
  }

  function saveFilters() {
    setSaveDisplay(
      <>
        Saving <Loading height={20} width={20}></Loading>
      </>
    );

    useUpdateFilters.mutate(
      {
        id: fetchAnalysisReport.data.analysisById.id,
        filters: externalFilter,
      },
      {
        onSuccess: (data) => {
          setSaveDisplay(
            <>
              Saved <i className="bi bi-check"></i>
            </>
          );

          setTimeout(() => {
            setSaveDisplay(
              <>
                Save Filters <i className="bi bi-funnel"></i>
              </>
            );
            setShowSaveFilters(false);
          }, 1000);
        },
      }
    );
  }

  function getPages() {
    let order = JSON.parse(report.pageOrder);
    let list = [];
    for (let id of order) {
      if (id !== analysisId) {
        let analysis = report.analysis.find((a) => a.id === id);
        if (analysis) {
          list.push(analysis);
        }
      }
    }
    return list;
  }

  const updateViz = useUpdateVisualization();
  const handleSave = (newViz) => {
    let data = {
      title: newViz.title,
      titleValue: newViz.titleValue,
      type: newViz.type,
      questionIds: newViz.question ? newViz.question.map((q) => q.id) : null,
      designSettings: JSON.stringify(newViz.designSettings),
      pivotString: newViz.pivotString,
      filters:
        typeof newViz.filters !== "string"
          ? JSON.stringify(newViz.filters)
          : newViz.filters,
      tagIdsArray: JSON.stringify(newViz.tagIdsArray),
      projectIdsArray: JSON.stringify(newViz.projectIdsArray),
      programIds: newViz.programIds ? JSON.stringify(newViz.programIds) : null,
      questionTags: newViz.questionTags
        ? JSON.stringify(newViz.questionTags)
        : null,
      comparison: newViz.comparison ? JSON.stringify(newViz.comparison) : null,
      // data: stringifyData(),
    };
    updateViz.mutate(
      {
        id: newViz?.id,
        data: data,
      },
      {
        onSuccess: (data) => {},
      }
    );
  };

  useEffect(() => {
    if (currViz) {
      handleSave(currViz);
      updateActive(null);
    }
  }, [currViz]);

  const [active, updateActive] = useState();
  function zoomOut() {
    // const container = document.getElementById("report-container-" + analysis?.id);
    // const content = document.getElementById("report-content-" + analysisId);
    if (zoom) {
      var multiplier = Math.pow(10, 1);
      zoom = Math.round(zoom * multiplier) / multiplier;
      // content.style.transform = `scale(${zoom - 0.1})`;
      setZoom(zoom - 0.1);
    } else {
      var multiplier = Math.pow(10, 1);
      zoom = Math.round(zoom * multiplier) / multiplier;
      // content.style.transform = `scale(${0.9})`;
      setZoom(0.9);
    }
    // setZoom(prevZoom => Math.max(prevZoom - 0.2, 0.5));
  }

  function zoomIn() {
    // const container = document.getElementById("report-container-" + analysis?.id);
    // const content = document.getElementById("report-content-" + analysisId);
    if (zoom) {
      var multiplier = Math.pow(10, 1);
      zoom = Math.round(zoom * multiplier) / multiplier;
      // content.style.transform = `scale(${zoom + 0.1})`;
      setZoom(zoom + 0.1);
    } else {
      var multiplier = Math.pow(10, 1);
      zoom = Math.round(zoom * multiplier) / multiplier;
      // content.style.transform = `scale(${1.1})`;
      setZoom(1.1);
    }

    // setZoom(prevZoom => prevZoom + 0.2);
  }

  const refOptions = useRef(null);
  const ref2 = useRef(null);

  const handleClickOutside = (event) => {
    if (refOptions.current && !refOptions.current.contains(event.target)) {
      setShowOptions(false);
    }
    if (ref2.current && !ref2.current.contains(event.target)) {
      setShowFilter(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  // const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const scrollableAreaRef = useRef(null);
  const contentRef = useRef(null);

  // useEffect(() => {
  //   const handleMouseMove = (event) => {
  //     const boundingRect = contentRef.current.getBoundingClientRect();
  //     const x = event.clientX - boundingRect.left;
  //     const y = event.clientY - boundingRect.top;

  //     setMousePosition({ x, y });
  // };

  //   window.addEventListener("mousemove", handleMouseMove);

  //   return () => {
  //     window.removeEventListener("mousemove", handleMouseMove);
  //   };
  // }, []);

  const deleteReport = useDeleteReport(report?.draftId);
  const publishDraft = usePublishDraft();

  function deleteDraft(e) {
    e.stopPropagation();
    if (
      window.confirm(
        "Are you sure you'd like to discard this draft? This is not recoverable "
      )
    ) {
      if (report?.draftId) {
        localStorage.removeItem("analysis-editing-" + report?.draftId);
      } else {
        localStorage.removeItem("analysis-editing-" + report?.id);
      }

      setEditing(false);
      deleteReport.mutate(
        {
          id: report?.id,
        },
        {
          onError: (data) => {
            setEditing(true);
            console.log("ERROR Removing Draft");
          },
        }
      );
    }
  }
  const [publishing, setPublishing] = useState(false);
  const navigate = useNavigate();
  function publishChanges(e) {
    e.stopPropagation();
    setPublishing(true);

    if (
      window.confirm(
        "Are you sure you'd like to publish your changes to the live report?"
      )
    ) {
      publishDraft.mutate(
        {
          id: report?.draftId,
        },
        {
          onSuccess: () => {
            setEditing(false);
            if (report?.draftId) {
              localStorage.removeItem("analysis-editing-" + report?.draftId);
            } else {
              localStorage.removeItem("analysis-editing-" + report?.id);
            }

            setPublishing(false);
            location.reload();
            // if (basic) {
            //   let projectId = report.project
            //     ? report.project.length === 1
            //       ? report.project[0]?.id
            //       : ""
            //     : "";

            //   navigate(
            //     "/project/" + projectId + "/surveyresults/" + report?.draftId
            //   );
            // } else {
            //   navigate("/report/" + report?.draftId);
            // }
          },
          onError: (data) => {},
        }
      );
    }
  }

  const copyReport = useCopyReport();

  function analyzeFurther() {
    copyReport.mutate(
      {
        id: editing ? report?.draftId : report?.id,
      },
      {
        onSuccess: (data) => {
          console.log(data);
          localStorage.setItem("activepage", 2);
          localStorage.removeItem("activeprojecttab");
          localStorage.removeItem("activecontactstab");
          localStorage.removeItem("activeorgtab");
          localStorage.removeItem("activeaccounttab");
          localStorage.removeItem("activeresultstab");
          localStorage.removeItem("activeanalysistab");
          localStorage.removeItem("activesurveystab");
          localStorage.removeItem("reportspageitem");
          localStorage.removeItem("activeaudiencetab");
          window.localStorage.removeItem("page1");
          window.localStorage.removeItem("page2");
          window.localStorage.removeItem("page3");
          if (report?.draftId) {
            localStorage.removeItem("analysis-editing-" + report?.draftId);
          } else {
            localStorage.removeItem("analysis-editing-" + report?.id);
          }

          navigate("/report/" + data.copyReport.id);
        },
      }
    );
  }

  function getPageProjects() {
    let included = {};

    let all = fetchAnalysisReport.data?.analysisById?.visualization?.map(
      (v) => {
        if (typeof v.projectIdsArray === "string") {
          v.projectIdsArray = JSON.parse(v.projectIdsArray);
        }
        return combinedProjIds(v);
      }
    );
    for (let list of all) {
      for (let id of list) {
        if (!(id in included)) {
          included[id] = true;
        }
      }
    }

    return Object.keys(included);
  }

  function addAnswerFilterSubtitle(conditions) {
    let string = "";
    for (let i = 0; i < conditions.length; i++) {
      let condition = conditions[i];
      let op = condition.operator;

      string += condition.surveyName + ": ";

      if (op === "have answered") {
        string += "answered '" + condition.qName + "'";
      } else if (op === "have not answered") {
        string += "did not answer '" + condition.qName + "'";
      } else if (
        op === "passives" ||
        op === "promoters" ||
        op === "detractors"
      ) {
        string += op;
      } else if (Array.isArray(condition.answer)) {
        if (op === "answer" || op === "do not answer" || op === "falls in") {
          if (op === "do not answer") {
            string += "not ";
          }
          for (let i = 0; i < condition.answer.length; i++) {
            if (i > 0) {
              if (i < condition.answer.length - 1) {
                string += ", ";
              } else if (i == condition.answer.length - 1) {
                string += " or ";
              }
            }
            string += condition.answer[i];
          }
        } else if (op === "between") {
          let them = getSortedArray(condition.answer, (a, b) => a - b);
          string += "between " + them[0] + " and " + them[1];
        }
      } else if (op === "below" || op === "above") {
        string += op + " " + condition.answer;
      } else if (op === "contains") {
        string += "'" + condition.answer + "'";
      } else if (op === "does not contain") {
        string += op + "  '" + condition.answer + "'";
      }

      if (op === "is longer than" || op === "is shorter than") {
        if (op === "is longer than") {
          string += "more than ";
        } else {
          string += "less than ";
        }
        string += condition.answer + " characters";
      }

      if (i < conditions.length - 1) {
        string += " & ";
      }
    }

    return string;
  }

  function getFilterSubtitle() {
    let filters = JSON.parse(externalFilter);
    let string = "";
    for (let one in filters) {
      if (string) {
        string += " & ";
      }

      if (one === "surveys") {
        let all = filters.surveys;
        string += "Survey: ";
        for (let i = 0; i < all.length; i++) {
          string += all[i]?.name;
          if (i < all.length - 1) {
            string += ", ";
          } else {
            string += " ";
          }
        }
      } else if (one === "answers") {
        string += addAnswerFilterSubtitle(filters[one]);
      } else {
        let field = filters[one];
        let name =
          field?.name?.charAt(0)?.toUpperCase() + field?.name?.slice(1);
        string += name + ": ";
        for (let i = 0; i < field.properties.length; i++) {
          string += field.properties[i];
          if (i < field.properties.length - 1) {
            string += ", ";
          } else {
            string += " ";
          }
        }
      }
    }
    return string;
  }

  function showFilterSubtitle() {
    if (!externalFilter) {
      return false;
    }

    let filters = JSON.parse(externalFilter);
    let count = 0;
    for (let key in filters) {
      if (key === "surveys" || key === "answers") {
        count += filters[key].length;
      } else {
        count += filters[key]?.properties?.length;
      }
    }
    return count > 0 && count < 6;
  }

  return (
    <>
      {editing && (
        <div
          className={`${styles.pageContainer2} `}
          style={{ width: currViz || active ? "400px" : "" }}
        >
          <Sidebar2
            menuItems={menuItems}
            active={active}
            updateActive={updateActive}
            condensed
            style={{ paddingTop: "5rem" }}
          ></Sidebar2>

          {active === "nav" && (
            <div className={styles.configBox}>
              <div className={styles.pagesTableContainer}>
                <ReportPages
                  report={report}
                  addPage={addPage}
                  onSelectPage={onSelectPage}
                  switchToPage={switchToPage}
                  roles={roles}
                  activePage={analysisId}
                  refetch={refetchReport}
                  canEdit={canEdit}
                ></ReportPages>
              </div>
            </div>
          )}

          {active === "style" && (
            <div className={styles.configBox}>
              <ReportStyles report={report}></ReportStyles>
            </div>
          )}

          {/* {active === "file" && (
            <div
              className={styles.configBox}
              style={{ padding: "1rem", gap: "1rem" }}
            >
              <div className={styles.label5} style={{ fontWeight: "500" }}>
                File Settings
              </div>
              <FlexRow start>
                <Icon
                  iconName={"filetype-pdf"}
                  style={{ fontSize: "1.1rem" }}
                ></Icon>
                <Button link> Export page as PDF</Button>
              </FlexRow>
            </div>
          )} */}

          {active === "settings" && (
            <div className={styles.configBox}>
              <div
                className={styles.label5}
                style={{ fontWeight: "500", padding: "1rem 1rem" }}
              >
                Report Settings
              </div>
              <div style={{ padding: "1rem", width: "100%" }}>
                <TextFieldSimple
                  label="Report Name"
                  value={reportName}
                  onChange={changeName}
                  disable={!report.userId && !canEdit}
                  style={{ fontSize: ".7rem" }}
                ></TextFieldSimple>
              </div>

              <TieToSurvey
                report={report}
                refetch={refetchReport}
                setShowDownload={setShowDownload}
              />

              <div style={{ padding: "1rem 1rem" }}>
                <CombinedInput
                  options={tagOptions}
                  value={{ value: report.type, label: report.type }}
                  onChange={(obj) => changeType(obj.value)}
                  label="Report Tag or Type"
                  select
                  icon="bi-chevron-down"
                ></CombinedInput>
              </div>
              <div className={styles.reportIcon}>
                <Label style={{ width: "fit-content", padding: "0em" }}>
                  Report Icon
                </Label>
                <i
                  className={`bi-${report?.icon ? report?.icon : "bar-chart"}`}
                  onClick={() => setIcons(!icons)}
                ></i>
              </div>
              {icons && (
                <div className={styles.iconShopper}>
                  <IconShopper
                    folderView
                    graphs
                    onPick={(val) => changeIcon(val)}
                  ></IconShopper>
                </div>
              )}

              {/* {report.userId && roles.seeUnPublishedReports && <Button>Make Visible To Org</Button>} */}
              {draft && (
                <>
                  {" "}
                  <Button height={25} onClick={addReport}>
                    Save Report
                  </Button>
                  <div className={styles.notifyText}>
                    *This Report is a draft. Click{" "}
                    <a onClick={addReport}>Save Report</a> to create and save
                    this draft
                  </div>
                </>
              )}
              {((!report.userId && canEdit
                ? canEdit
                : roles.canViewOrgReports) ||
                (saveChanges && !draft)) && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "40px",
                    gap: "10px",
                    position: "sticky",
                    bottom: "1em",
                  }}
                >
                  {saveChanges && !draft && (
                    <>
                      {saveStatus === "Save Changes" && (
                        <Button
                          // height={25}
                          onClick={() => {
                            setShow(false);
                            setSaveChanges(null);
                          }}
                          shadow
                          red
                        >
                          Cancel
                        </Button>
                      )}
                      <Button
                        // height={25}
                        shadow
                        onClick={saveStatus === "Save Changes" ? onSave : null}
                      >
                        {saveStatus}
                      </Button>
                    </>
                  )}
                </div>
              )}
            </div>
          )}

          {currViz && !active && (
            <ConfigureBox
              visualizationCopy={currViz}
              setVisualizationCopy={setCurrViz}
              onSave={() => handleSave(currViz)}
              canSave={true}
              custom_fields={custom_fields}
              externalFilter={externalFilter}
              visibleItems={visibleItems}
              setVisibleItems={setVisibleItems}
              labels={labels}
            />
          )}
        </div>
      )}

      {fetchAnalysisReport.isLoading && <Loading></Loading>}
      {fetchAnalysisReport.isSuccess && (
        <div
          className={`${styles.container} ${editing && styles.back}`}
          onClick={() => {
            setCurrViz(null);
            updateActive(null);
          }}
          id={"report-container-" + analysisId}
        >
          {/* {editing && canEdit && (
            <div className={styles.nav}>
              <FlexRow start style={{ width: "fit-content" }}>
                <div className={styles.isEdit}>
                  <Icon iconName={"info-circle"} blue></Icon>Now Editing
                  <span onClick={() => setEditing(false)}>Close Editor</span>
                </div>
                <div className={`${styles.bubble} ${styles.draft}`}>Draft</div>
                <div
                  className={styles.section_label6}
                  style={{
                    whiteSpace: "nowrap",
                    margin: "0",
                    display: "flex",
                    gap: ".5rem",
                  }}
                >
                  {saveStatus}
                  {` ${trimDate(report?.updatedAt, true)}`}
                </div>
              </FlexRow>
              <FlexRow start style={{ width: "fit-content" }}>
                {canEdit && <AddChartButton setAddChart={setAddChart} />}
                <Button shadow onClick={deleteDraft}>
                  Discard Draft
                </Button>
                <Button blue shadow onClick={publishChanges}>
                  {publishing ? (
                    <FlexRow start>
                      Publishing <Loading height={25} width={25}></Loading>
                    </FlexRow>
                  ) : (
                    "Publish Changes"
                  )}
                </Button>
              </FlexRow>
            </div>
          )} */}

          {/* {!editing && canEdit && (
            <div className={styles.nav}>
              <FlexRow start style={{ width: "fit-content" }}>
                <div className={styles.isEdit}>
                  <Icon iconName={"info-circle"} blue></Icon>Presentation
                  <span onClick={() => switchToDraft()}>Edit Report</span>
                </div>
                <div className={`${styles.bubble} ${styles.live}`}>Live</div>
                <div
                  className={styles.section_label6}
                  style={{
                    whiteSpace: "nowrap",
                    margin: "0",
                    display: "flex",
                    gap: ".5rem",
                  }}
                >
                  Last Updated
                  {` ${trimDate(report?.updatedAt, true)}`}
                </div>
              </FlexRow>
            </div>
          )} */}
          <div
            className={styles.navPresent}
            style={{ top: canEdit ? "" : "1rem" }}
          >
            {!editing && canEdit && (
              <div className={styles.nav}>
                <FlexRow start style={{ width: "fit-content" }}>
                  <div className={styles.isEdit}>
                    <Icon iconName={"info-circle"} blue></Icon>Presentation
                    <span onClick={() => switchToDraft()}>Edit Report</span>
                  </div>
                  <div className={`${styles.bubble} ${styles.live}`}>Live</div>
                  <div
                    className={styles.section_label6}
                    style={{
                      whiteSpace: "nowrap",
                      margin: "0",
                      display: "flex",
                      gap: ".5rem",
                    }}
                  >
                    Last Updated
                    {` ${trimDate(report?.updatedAt, true)}`}
                  </div>
                </FlexRow>
                {basic && (
                  <Button
                    shadow
                    blue
                    onClick={
                      report.project
                        ? report.project.length === 1
                          ? () => setShowDownload(report.project[0])
                          : () => setShowDownloads(true)
                        : null
                    }
                  >
                    Download Results
                  </Button>
                )}
              </div>
            )}
            {editing && canEdit && (
              <div className={styles.nav}>
                <FlexRow start style={{ width: "fit-content" }}>
                  <div className={styles.isEdit}>
                    <Icon iconName={"info-circle"} blue></Icon>Now Editing
                    <span onClick={() => setEditing(false)}>Close Editor</span>
                  </div>
                  <div className={`${styles.bubble} ${styles.draft}`}>
                    Draft
                  </div>
                  <div
                    className={styles.section_label6}
                    style={{
                      whiteSpace: "nowrap",
                      margin: "0",
                      display: "flex",
                      gap: ".5rem",
                    }}
                  >
                    {saveStatus}
                    {` ${trimDate(report?.updatedAt, true)}`}
                  </div>
                </FlexRow>
                <FlexRow start style={{ width: "fit-content" }}>
                  {canEdit && <AddChartButton setAddChart={setAddChart} />}
                  <Button shadow onClick={deleteDraft}>
                    Discard Draft
                  </Button>
                  <Button blue shadow onClick={publishChanges}>
                    {publishing ? (
                      <FlexRow start>
                        Publishing <Loading height={25} width={25}></Loading>
                      </FlexRow>
                    ) : (
                      "Publish Changes"
                    )}
                  </Button>
                  {/* <div className={styles.header_6} style={{ margin: "0" }}>
                  {reportName}
                </div> */}
                </FlexRow>
              </div>
            )}

            <FlexRow start style={{ width: "fit-content" }} gap={".2rem"}>
              <span
                className={`${styles.header_6} ${styles.spanner}`}
                style={{
                  margin: "0",
                  backgroundColor: showOptions ? "#d8d9d9" : "",
                }}
                onClick={() => setShowOptions(true)}
              >
                Options
              </span>
              <span
                className={`${styles.header_6} ${styles.spanner}`}
                style={{
                  margin: "0",
                  backgroundColor: seePages ? "#d8d9d9" : "",
                }}
                onClick={() => setSeePages(true)}
              >
                Pages
              </span>
              <span
                className={`${styles.header_6} ${styles.spanner}`}
                style={{
                  margin: "0",
                  backgroundColor: showFilter ? "#d8d9d9" : "",
                }}
                onClick={() => setShowFilter(true)}
              >
                Filters
              </span>

              <span
                className={`${styles.header_6} ${styles.spanner}`}
                style={{
                  margin: "0",
                  backgroundColor: showShare ? "#d8d9d9" : "",
                }}
                onClick={() => setShowShare(true)}
              >
                Share
              </span>
            </FlexRow>
            {showOptions && (
              <div
                className={styles.settingsBox}
                ref={refOptions}
                style={{ left: "1rem", width: "fit-content", top: "6rem" }}
                onClick={(e) => e.stopPropagation()}
              >
                <div
                  className={styles.menuItem}
                  onClick={
                    report.project
                      ? report.project.length === 1
                        ? () => setShowDownload(report.project[0])
                        : () => setShowDownloads(true)
                      : null
                  }
                >
                  <i
                    className="bi bi-cloud-arrow-down"
                    style={{ color: "#15BCC7" }}
                  ></i>
                  Download Results
                </div>
                <div className={styles.menuItem} onClick={analyzeFurther}>
                  <i className="bi bi-layers" style={{ color: "#15BCC7" }}></i>
                  Copy This Report
                </div>
                <div className={styles.menuItem} onClick={pdfExport}>
                  <i
                    className="bi bi-filetype-pdf"
                    style={{ color: "#15BCC7" }}
                  ></i>
                  Export Page as PDF
                </div>
              </div>
              // </div>
            )}
            {showFilter && (
              <div
                className={styles.filter}
                style={{ right: canEdit ? "" : "0em" }}
                ref={ref2}
              >
                <div className={styles.filterHeader}>
                  <div>Filter Page By</div>
                  <div style={{ fontSize: ".6em" }}>
                    Changes override individual chart filters
                  </div>
                </div>

                <PageFilter
                  onFilterChange={onFilterChange}
                  chosenFilter={
                    externalFilter ? JSON.parse(externalFilter) : {}
                  }
                  pageProjects={getPageProjects()}
                  custom_fields={custom_fields}
                ></PageFilter>
              </div>
            )}
            {showFilterSubtitle() && (
              <div className={styles.filtersSubtitle}>
                {getFilterSubtitle()}
              </div>
            )}
            <FlexRow start style={{ width: "fit-content" }} gap={".2rem"}>
              <FlexRow start style={{ width: "fit-content" }} gap={"0rem"}>
                {zoom != 1 && (
                  <span
                    className={`${styles.header_6} ${styles.spanner}`}
                    style={{ margin: "0" }}
                    onClick={() => setZoom(1)}
                  >
                    Reset
                  </span>
                )}
                <a
                  className={`${styles.header_6} ${styles.aa}`}
                  style={{ margin: "0" }}
                >
                  {Math.floor(zoom * 100)}%
                </a>
                <span className={` ${styles.spanner}`} onClick={zoomOut}>
                  <Icon iconName="dash"></Icon>
                </span>
                <span className={`${styles.spanner}`} onClick={zoomIn}>
                  <Icon iconName="plus"></Icon>
                </span>
              </FlexRow>
              <a
                className={`${styles.header_6} ${styles.aa}`}
                style={{ margin: "0" }}
              >
                {reportName}
              </a>
            </FlexRow>
            <ReportHeader
              setShow={setShow}
              show={show}
              showSave={showSaveFilters}
              saveDisplay={saveDisplay}
              saveFilters={saveFilters}
              setAddChart={setAddChart}
              report={report}
              page={fetchAnalysisReport.data.analysisById}
              canEdit={canEdit}
              roles={roles}
              onSelectPage={onSelectPage}
              setShowShare={setShowShare}
              showShare={showShare}
              analysisId={analysisId}
              custom_fields={custom_fields}
              externalFilter={externalFilter}
              onFilterChange={onFilterChange}
              user={user}
              showDownload={showDownload}
              setShowDownload={setShowDownload}
              organization={organization}
              basic={basic}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              seePages={seePages}
              setSeePages={setSeePages}
              showDownloads={showDownloads}
              setShowDownloads={setShowDownloads}
            ></ReportHeader>
          </div>
          <div
            className={`${styles.zoomcontainer} ${editing && styles.back}`}
            onClick={() => {
              setCurrViz(null);
              updateActive(null);
            }}
            id={"report-container2-" + analysisId}
            // id={"report-content-" + analysisId}
          >
            <div
              className={styles.scrollablearea}
              ref={scrollableAreaRef}
              id={"report-content-" + analysisId}
              // id={"report-container2-" + analysisId}
            >
              <div
                className={`${!showDrill && styles.zoomablecontent} ${
                  showDrill && styles.fixedContent
                }`}
                // id={"report-content-" + analysisId}
                ref={contentRef}
                style={{ transform: showDrill ? "none" : `scale(${zoom})` }}
                // id={"report-container2-" + analysisId}
              >
                <Analysis
                  key={analysisId}
                  analysis={fetchAnalysisReport.data.analysisById}
                  refetch={() => fetchAnalysisReport.refetch()}
                  addChart={addChart}
                  setAddChart={setAddChart}
                  canEdit={canEdit}
                  externalFilter={externalFilter}
                  custom_fields={custom_fields}
                  page={
                    pageOrder &&
                    pageOrder?.findIndex((a) => a === analysisId) + 1
                  }
                  pages={getPages()}
                  goToPage={goToPage}
                  reportId={report.id}
                  currViz={currViz}
                  setCurrViz={setCurrViz}
                  editing={editing}
                  setEditing={setEditing}
                  setVisibleItems={setVisibleItems}
                  visibleItems={visibleItems}
                  setLabels={setLabels}
                  zoom={zoom}
                  setZoom={setZoom}
                  showDrill={showDrill}
                  setShowDrill={setShowDrill}
                />
              </div>
            </div>
          </div>
          {report.analysis.length > 1 && !showDownload && !showShare && (
            <div className={styles.footer}>
              <div className={styles.reportFooter}>
                <div className={styles.reportFooterPage}>
                  {" "}
                  Page {pageOrder?.findIndex((a) => a === analysisId) +
                    1} of {report.analysis.length}
                </div>

                <Button shadow onClick={() => prevPage()} width={70}>
                  Prev
                </Button>
                <Button shadow onClick={() => nextPage()} width={70}>
                  Next
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
      {/* </div> */}
    </>
  );
}

function ReportHeader({
  show,
  showSave,
  saveDisplay,
  saveFilters,
  setShow,
  setAddChart,
  report,
  page,
  canEdit,
  roles,
  onSelectPage,
  addReport,
  analysisId,
  showShare,
  setShowShare,
  custom_fields,
  externalFilter,
  onFilterChange,
  user,
  showDownload,
  setShowDownload,
  organization,
  basic,
  showFilter,
  setShowFilter,
  seePages,
  setSeePages,
  showDownloads,
  setShowDownloads,
}) {
  const [showSettings, setShowSettings] = useState(false);

  const copyReport = useCopyReport();

  const ref3 = useRef(null);
  const ref4 = useRef(null);

  const handleClickOutside = (event) => {
    if (ref3.current && !ref3.current.contains(event.target)) {
      setShowSettings(false);
    }
    if (ref4.current && !ref4.current.contains(event.target)) {
      setShowDownloads(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  let navigate = useNavigate();

  function runCopyReport() {
    copyReport.mutate(
      {
        id: report.id,
        name: report.name + " (Copy)",
      },
      {
        onSuccess: (data) => {
          console.log(data);
          localStorage.setItem("activepage", 2);
          localStorage.removeItem("activeprojecttab");
          localStorage.removeItem("activecontactstab");
          localStorage.removeItem("activeorgtab");
          localStorage.removeItem("activeaccounttab");
          localStorage.removeItem("activeresultstab");
          localStorage.removeItem("activeanalysistab");
          localStorage.removeItem("activesurveystab");
          localStorage.removeItem("reportspageitem");
          localStorage.removeItem("activeaudiencetab");
          window.localStorage.removeItem("page1");
          window.localStorage.removeItem("page2");
          window.localStorage.removeItem("page3");
          navigate("/report/" + data.copyReport.id);
        },
      }
    );
  }

  function pdfExport() {
    // setOpenSettings(false);
    setTimeout(() => {
      let input = window.document.getElementById(analysisId);
      html2canvas(input, { scale: 5 }).then((canvas) => {
        const img = canvas.toDataURL("image/png", 1);
        // img.save("newimgage");
        const pdf = new jsPDF("p", "pt");
        console.log(input.clientHeight);
        pdf.internal.pageSize.height = input.clientHeight;
        pdf.internal.pageSize.width = input.clientWidth;
        pdf.addImage(img, "png", 0, 0, input.clientWidth, input.clientHeight);
        //give pdf a name and download.
        let str = "chart.pdf";
        str = str.replace(/\s+/g, "");
        str = str.toLocaleLowerCase();
        pdf.save(str);
      });
    }, 1000);
  }

  function showFilterCount() {
    return externalFilter && externalFilter !== "{}";
  }

  function getFilterCount() {
    let filtersObject = JSON.parse(externalFilter);
    let count = 0;
    for (let key in filtersObject) {
      let filter = filtersObject[key];
      if (key === "surveys" || key === "answers") {
        count += filter.length;
      } else {
        if (filter?.properties?.length) {
          count += filter?.properties?.length;
        }
      }
    }
    return count;
  }

  const fix = useFix();
  function onClickFix() {
    fix.mutate(
      {},
      {
        onSuccess: (data) => {
          console.log(data);
        },
      }
    );
  }

  return (
    <>
      {seePages && (
        <PagesNav
          report={report}
          canEdit={canEdit}
          addReport={addReport}
          roles={roles}
          onSelectPage={onSelectPage}
          page={page}
          show={show}
          setShow={setShow}
          close={() => setSeePages(false)}
        />
      )}
      {showShare && !basic && (
        <Share
          roles={roles}
          report={report}
          show={showShare}
          setShow={setShowShare}
          currUser={user}
          canEdit={canEdit}
          organization={organization}
        />
      )}
      {showDownload && (
        <DownloadView
          show={showDownload}
          setShow={setShowDownload}
          survey={showDownload}
          custom_fields={custom_fields}
          externalChosenfilter={externalFilter ? externalFilter : {}}
          roles={roles}
        ></DownloadView>
      )}
      {showDownloads &&
        !showFilter &&
        !showSettings &&
        !seePages &&
        !showShare && (
          <div className={styles.projectTies} ref={ref4}>
            {report.project.map((survey, i) => (
              <div
                className={styles.surveyRow}
                key={i}
                onClick={() => setShowDownload(survey)}
              >
                {survey?.name}
              </div>
            ))}
          </div>
        )}
      {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <div className={styles.reportPageCon}>
          <div className={styles.reportpagename}>
            {page?.name}
          </div>

          {showSave && (
            <div style={{ zIndex: "130" }}>
              <Button height={35} onClick={saveFilters} shadow>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  {saveDisplay}
                </div>
              </Button>
            </div>
          )}
        </div>
      </div> */}

      {/* <div className={styles.reportHeader} onClick={(e) => e.stopPropagation()}> */}
      {/* <div className={styles.reportSettingsContainer}> */}
      {/* {showDownloads &&
            !showFilter &&
            !showSettings &&
            !seePages &&
            !showShare && (
              <div className={styles.projectTies} ref={ref4}>
                {report.project.map((survey, i) => (
                  <div
                    className={styles.surveyRow}
                    key={i}
                    onClick={() => setShowDownload(survey)}
                  >
                    {survey?.name}
                  </div>
                ))}
              </div>
            )} */}
      {/* {(canEdit ? canEdit : JSON.parse(report.pageOrder).length > 1) && (
            <div
              className={`${styles.reportSettingsButton} ${
                seePages ? styles.reportSettingsButtonOpen : ""
              }`}
              onClick={() => setSeePages(!seePages)}
            >
              <i className="bi-journals"></i>Pages
              {seePages && (
                <PagesNav
                  report={report}
                  canEdit={canEdit}
                  addReport={addReport}
                  roles={roles}
                  onSelectPage={onSelectPage}
                  page={page}
                  show={show}
                  setShow={setShow}
                  close={() => setSeePages(false)}
                />
              )}
            </div>
          )} */}
      {/* <HorizontalBar rotate height={20} width={2}></HorizontalBar> */}
      {/* {showShare && !basic && (
            <Share
              roles={roles}
              report={report}
              show={showShare}
              setShow={setShowShare}
              currUser={user}
              canEdit={canEdit}
              organization={organization}
            />
          )} */}

      {/* {report.project && report.project.length > 0 && !basic && (
            <>
              <div
                className={`${styles.reportSettingsButton} ${
                  showDownload ? styles.reportSettingsButtonOpen : ""
                }`}
                onClick={
                  report.project ? () => setShowDownloads(!showDownloads) : null
                }
              >
                <i
                  className="bi bi-cloud-download"
                  style={{
                    color:
                      report.project && report.project.length > 0
                        ? ""
                        : "lightgray",
                  }}
                ></i>
                <span
                  style={{
                    color:
                      report.project && report.project.length > 0
                        ? ""
                        : "lightgray",
                  }}
                >
                  Download
                </span>
              </div>
              <HorizontalBar rotate height={20} width={2}></HorizontalBar>
            </>
          )}

          {/* <div
            className={`${styles.reportSettingsButton}`}
            onClick={onClickFix}
          >
            Fix
          </div> */}

      {/* <div
            className={`${styles.reportSettingsButton} ${
              showFilter ? styles.reportSettingsButtonOpen : ""
            }`}
            onClick={() => setShowFilter(true)}
          >
            <i className="bi bi-funnel"></i>
            Filters
            {showFilterCount() && (
              <div className={styles.pageFilterCount}>{getFilterCount()}</div>
            )}
            {showFilter && (
              <div
                className={styles.filter}
                style={{ right: canEdit ? "" : "0em" }}
                ref={ref2}
              >
                <div className={styles.filterHeader}>
                  <div>Filter Page By</div>
                  <div style={{ fontSize: ".6em" }}>
                    Changes override individual chart filters
                  </div>
                </div>

                <PageFilter
                  onFilterChange={onFilterChange}
                  chosenFilter={
                    externalFilter ? JSON.parse(externalFilter) : {}
                  }
                  pageProjects={getPageProjects()}
                  custom_fields={custom_fields}
                ></PageFilter>
              </div>
            )}
          </div> */}

      {/* <HorizontalBar rotate height={20} width={2}></HorizontalBar> */}

      {/* {!basic && canEdit && (
            <>
              <div
                className={`${styles.reportSettingsButtonShare} `}
                onClick={() => setShowShare(!showShare)}
              >
                <i className="bi-people"></i>
                <span>Share</span>
              </div>
            </>
          )} */}
      {/* </div> */}
      {/* {report.project && report.project.length > 0 && basic && (
          <div
            className={`${styles.reportSettingsButtonDownload} ${
              showDownload ? styles.reportSettingsButtonOpen : ""
            }`}
            onClick={
              report.project
                ? report.project.length === 1
                  ? () => setShowDownload(report.project[0])
                  : () => setShowDownloads(!showDownloads)
                : null
            }
          >
            <i
              className="bi bi-cloud-download"
              style={{
                color:
                  report.project && report.project.length > 0
                    ? ""
                    : "lightgray",
              }}
            ></i>
            <span
              style={{
                color:
                  report.project && report.project.length > 0
                    ? ""
                    : "lightgray",
              }}
            >
              Download Results
            </span>
          </div>
        )} */}
      {/* </div> */}
    </>
  );
}

function AddChartButton({ setAddChart }) {
  const [show, setShow] = useState(false);

  function onClickListener() {
    setShow(false);
    document.removeEventListener("click", onClickListener, false);
  }

  function setUpShow() {
    if (!show) {
      setTimeout(
        () => document.addEventListener("click", onClickListener, false),
        300
      );
      setShow(true);
    }
  }

  const ref = useRef(null);

  const chartImageStyle = {
    height: "25px",
    width: "25px",
  };

  return (
    <div
      className={styles.addChart}
      onClick={setUpShow}
      style={show ? { backgroundColor: "light gray" } : undefined}
    >
      <div>+ Add</div>

      {show && (
        <div className={styles.chartTypeMenu} ref={ref}>
          <div
            className={styles.chartType}
            onClick={() => setAddChart("chart")}
          >
            Chart
            <i className={`bi bi-bar-chart-fill ${styles.chart} `}></i>
            {/* <img style={chartImageStyle} src={VerticalBarImage} alt="IMG" /> */}
          </div>
          <div
            className={styles.chartType}
            onClick={() => setAddChart("table")}
          >
            <i
              className={`bi bi-layout-text-window-reverse ${styles.table}`}
            ></i>
            Table
            {/* <img style={chartImageStyle} src={TableImage} alt="IMG" /> */}
          </div>
          <div
            className={styles.chartType}
            onClick={() => setAddChart("metric")}
          >
            Metric <i className={`bi bi-card-text ${styles.metric} `}></i>
          </div>

          {/* <div>Note</div> */}
        </div>
      )}
    </div>
  );
}

function PagesNav({
  report,
  canEdit,
  addReport,
  roles,
  onSelectPage,
  page,
  show,
  setShow,
  close,
}) {
  const ref = useRef();

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      close();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div
      className={styles.pagesPopout}
      style={{ right: canEdit ? "" : "-6em" }}
      ref={ref}
    >
      <ReportPages
        report={report}
        roles={roles}
        onSelectPage={onSelectPage}
        activePage={page.id}
        pagesTab
        noTitle
      ></ReportPages>
      {/* {canEdit && <div className={styles.notifyText}></div>} */}
    </div>
  );
}

function ReportPages({
  report,
  addPage,
  onSelectPage,
  switchToPage,
  refetchAnalysis,
  roles,
  activePage,
  pagesTab,
  noTitle,
  refetch,
  canEdit,
}) {
  const initPages = () => {
    if (report?.pageOrder) {
      let pages = [];
      forEach(JSON.parse(report?.pageOrder), (analId) => {
        let analysis = report.analysis.find((a) => a.id === analId);
        if (analysis) {
          pages.push(analysis);
        }
      });
      return pages;
    }
    return report.analysis;
  };

  const [pages, setPages] = useState(initPages());
  const [newPage, setNewPage] = useState(null);
  const [copying, setCopying] = useState("");

  const deleteAnalysis = useDeleteAnalysis();
  const copyAnalysis = useCopyAnalysis();
  const updateReport = useUpdateReport();

  function addTempPage() {
    // let temp = [...pages];
    let tempPage = { name: "", id: "new" };
    // temp.push(tempPage);
    setNewPage(tempPage);
  }

  function changePageName(id, name) {
    let newPages = [...pages];
    for (let page of pages) {
      if (page.id === id) {
        page.name = name;
      }
    }
    setPages(newPages);
    refetchAnalysis();
  }

  function deletePage(page) {
    if (window.confirm("Are you sure you want to delete " + page?.name + "?")) {
      deleteAnalysis.mutate(
        {
          deleteAnalysisId: page.id,
        },
        {
          onSuccess: (data) => {
            console.log("page deleted");
            let newPages = [...pages];
            let ind = newPages.findIndex((p) => p.id === page.id);
            newPages.splice(ind, 1);
            setPages(newPages);

            if (page.id === activePage) {
              if (ind < newPages?.length) {
                switchToPage(newPages[ind].id); // Will now be the one after it
              } else {
                switchToPage(newPages[ind - 1].id); // Was last page
              }
            }
            refetch();
          },
          onError: () => {
            console.log("Error deleting the page");
          },
        }
      );
    }
  }

  function copyPage(page) {
    copyAnalysis.mutate(
      {
        copyAnalysisId: page.id,
      },
      {
        onSuccess: (data) => {
          if (data.copyAnalysis) {
            console.log("page copied!");
            let newPages = [...pages];
            let ind = newPages.findIndex((p) => p.id === page.id);
            newPages.splice(ind + 1, 0, data.copyAnalysis);
            setPages(newPages);
            refetch();
            setCopying("");
          }
        },
        onError: () => {
          console.log("Error copying the page");
        },
      }
    );
    setCopying(page.id);
  }

  function addPageToList(analysis) {
    let newPages = [...pages];
    newPages.push({
      name: analysis?.name,
      id: analysis.id,
      visualization: [],
    });
    setPages(newPages);
    // savePageOrder(newPages.map((p) => p.id));
  }

  function handleNewPage(name) {
    if (!name) {
      name = "Page " + (pages.length + 1);
    }
    addPage(name, addPageToList);
    setNewPage(false);
  }

  function savePageOrder(order) {
    updateReport.mutate(
      {
        data: {
          pageOrder: JSON.stringify(order),
        },
        id: report.id,
      },
      {
        onSuccess: (data) => {
          console.log("Order Saved");
          if (refetch) {
            refetch();
          }
        },
        onError: () => {
          console.log("Error Saving Order");
        },
      }
    );
  }

  function handleDragEnd({ active, over }) {
    if (!over?.id) {
      return;
    }
    if (over.id === active.id) {
      return;
    }

    let activeInd = active.data.current.sortable.index;
    let overInd = over.data.current.sortable.index;

    let newPages = [...pages];

    let activePage = newPages[activeInd];
    let overPage = newPages[overInd];

    newPages.splice(activeInd, 1);
    let newOverInd = newPages.findIndex((p) => p.id === overPage.id);

    if (activeInd > overInd) {
      // moving up
      newPages.splice(newOverInd, 0, activePage);
    } else {
      // moving down
      newPages.splice(newOverInd + 1, 0, activePage);
    }

    setPages(newPages);
    savePageOrder(newPages.map((p) => p.id));
  }

  return (
    <div
      className={`${styles.pagesTable} ${noTitle ? styles.pagesRadius : ""}`}
    >
      {!noTitle && (
        <div
          className={styles.label5}
          style={{ padding: ".5rem", fontWeight: "500" }}
        >
          Report Pages
        </div>
      )}
      <table>
        <tbody>
          <DndContext
            // sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
          >
            <SortableContext
              id={"Sortable Pages"}
              items={pages.map((p) => p.id)}
              strategy={verticalListSortingStrategy}
            >
              {pages.map((page, index) => (
                <PageRow
                  key={index}
                  page={page}
                  report={report}
                  roles={roles}
                  isUser={report.userId}
                  active={page.id === activePage}
                  pagesTab={pagesTab}
                  index={index}
                  canEdit={canEdit}
                />
              ))}
            </SortableContext>
          </DndContext>

          {newPage && (
            <tr>
              <Input
                saveText="Add"
                onSave={handleNewPage}
                onExit={() => setNewPage(false)}
                initText=""
                index={pages.length}
              />
            </tr>
          )}

          {(report.userId || canEdit) && !pagesTab && (
            <tr onClick={addTempPage}>
              <td style={{ padding: "10px 50px", width: "100%" }}>
                <div className={styles.addpage}>+ Page</div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );

  function PageRow({
    page,
    isUser,
    roles,
    active,
    pagesTab,
    report,
    index,
    canEdit,
  }) {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({ id: page.id });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      opacity: isDragging ? 0.9 : 1,
      zIndex: isDragging ? 10 : 0,
      width: "100%",
      backgroundColor: active ? "#E9E9E9" : "",
    };

    const [editName, setEditName] = useState(false);
    const updateAnalysis = useUpdateAnalysis();

    function editAnalysisName(name) {
      if (name !== page.name) {
        updateAnalysis.mutate(
          {
            id: page.id,
            data: {
              name: name,
            },
          },
          {
            onSuccess: (data) => {
              console.log("Updated name", data);
              changePageName(page.id, name);
            },
            onError: (data) => {
              // debugger;
            },
          }
        );
      }
      setEditName(false);
    }

    return (
      <tr
        ref={setNodeRef}
        {...attributes}
        style={{ ...style }}
        onClick={pagesTab && !active ? () => onSelectPage(page.id) : null}
      >
        {!editName && (
          <td className={styles.pageTableRow}>
            <div
              className={styles.page}
              onClick={!active ? () => onSelectPage(page.id) : null}
            >
              {pagesTab && <>{index + 1}. </>}
              {page.name ? page.name : "Page " + (index + 1)}
            </div>
            {(isUser || canEdit) && !pagesTab && (
              <div className={styles.icons}>
                <i
                  className={`bi bi-pencil ${styles.editName}`}
                  onClick={() => setEditName(true)}
                ></i>
                {/* <i
                    className="bi bi-clipboard"
                    onClick={copyReport}
                  ></i> */}
                {report.analysis.length > 1 && (
                  <i
                    className={`bi bi-trash3 ${styles.trash}`}
                    onClick={() => deletePage(page)}
                  ></i>
                )}
                {copying !== page.id && (
                  <i
                    className={`bi bi-copy ${styles.copy}`}
                    style={
                      !page.visualization.length
                        ? { visibility: "hidden" }
                        : undefined
                    }
                    onClick={
                      page.visualization.length
                        ? () => copyPage(page)
                        : undefined
                    }
                  ></i>
                )}
                {copying === page.id && <Loading height={20} width={20} />}
                <div className={styles.grid} {...listeners}>
                  <i className="bi bi-grip-vertical"></i>
                </div>
              </div>
            )}
          </td>
        )}
        {editName && (
          <Input
            onSave={editAnalysisName}
            onExit={() => setEditName(false)}
            initText={page.name}
            saveText="Save"
            index={index}
          />
        )}
      </tr>
    );
  }

  function Input({ initText, saveText, onExit, onSave, index }) {
    const [value, setValue] = useState(initText);

    function listenForEnter(e) {
      if (e.key === "Enter" || e.keyCode === 13) {
        onSave(value);
      }
    }

    return (
      <>
        <td>
          <div className={styles.page}>
            <input
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className={styles.inputLine}
              onKeyDown={listenForEnter}
              placeholder={"Page " + (index + 1)}
            ></input>
          </div>
        </td>
        <td>
          <div className={styles.exitOrSave}>
            <span onClick={onExit}>X</span>
            <Button height={25} onClick={() => onSave(value)}>
              {saveText}
            </Button>
          </div>
        </td>
      </>
    );
  }
}

function TieToSurvey({ report, refetch, setShowDownload }) {
  const [edited, setEdited] = useState(false);
  const [tied, setTied] = useState([...report.project]);
  const [show, setShow] = useState(false);

  const updateTies = useUpdateTiedSurveys();

  function saveTies() {
    updateTies.mutate(
      {
        reportId: report.id,
        projectIds: {
          list: tied.map((s) => s.id),
        },
      },
      {
        onSuccess: (data) => {
          console.log("updated:", data);
          refetch();
        },
        onError: (data) => {
          setTied(report.project);
        },
      }
    );
    setEdited(false);
  }

  function untie(survey) {
    let copy = [...tied];
    let index = copy.findIndex((s) => s.id === survey.id);
    if (index >= 0) {
      copy.splice(index, 1);
    }
    setTied(copy);
    setEdited(true);
  }

  function tie(survey) {
    let copy = [...tied];
    copy.push(survey);
    setTied(copy);
    setShow(false);
    setEdited(true);
  }

  function revert() {
    setEdited(false);
    setTied([...report.project]);
  }

  return (
    <div className={styles.tiedToContainer}>
      <div className={styles.label6}>Surveys Tied to Report:</div>

      <div className={styles.tiedSurveys}>
        {tied.map((survey, i) => (
          <TiedSurvey
            key={i}
            survey={survey}
            untie={untie}
            setShowDownload={setShowDownload}
          />
        ))}
        <div className={styles.addSurvey}>
          <Button shadow onClick={() => setShow(true)}>
            + add
          </Button>
        </div>
        {show && (
          <AddProject
            included={tied}
            close={() => setShow(false)}
            addProject={tie}
          />
        )}

        {edited && (
          <div className={styles.saveTies}>
            <Button height={25} shadow onClick={saveTies}>
              Save
            </Button>
            <Button height={25} red onClick={revert}>
              Cancel
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

function TiedSurvey({ survey, untie, setShowDownload }) {
  const [over, setOver] = useState(false);

  return (
    <div className={`${styles.tiedSurvey} ${over ? styles.careful : ""}`}>
      <span>
        {survey.name}{" "}
        <span className={styles.link} onClick={() => setShowDownload(survey)}>
          View Results
        </span>
      </span>
      <div
        className={styles.deleteSurvey}
        onClick={() => untie(survey)}
        onMouseEnter={() => setOver(true)}
        onMouseLeave={() => setOver(false)}
      >
        <i className={`bi bi-${over ? "trash3-fill" : "trash3"}`}></i>
      </div>
    </div>
  );
}

function AddProject({ included, addProject, close }) {
  const [searchString, setSearchString] = useState("");
  const [perPage, setPerPage] = useState(20);
  const [pageSkip, setPageSkip] = useState(0);
  const [searchSort, setSearchSort] = useState({
    item: "startedAt",
    descend: true,
  });

  useEffect(() => {
    fetchProjects.refetch();
  }, [searchString]);

  const fetchProjects = useSearchProjectsForChart(
    searchString,
    pageSkip,
    perPage,
    searchSort
  );

  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      close();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className={styles.addProjectAnchor}>
      <div className={styles.addProjects} ref={ref}>
        <div className={styles.search}>
          <TextFieldSimple
            value={searchString}
            placeholder="Search Surveys..."
            onChange={(val) => setSearchString(val)}
            customStyles={styles.searchSurveys}
          ></TextFieldSimple>
        </div>

        {fetchProjects.isSuccess && (
          <div className={styles.projectOptions}>
            {fetchProjects.data?.surveys.map((project, index) => (
              <React.Fragment key={index}>
                {!included.some((p) => p.id === project.id) && (
                  <div
                    key={index}
                    className={styles.projectOption}
                    onClick={() => {
                      addProject(project);
                    }}
                  >
                    {project.name}
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
