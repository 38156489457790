import React from "react";
import styles from "../SettingsAccordion.module.scss";
import {
  TableType,
  MetricType,
  ValueType,
  defaultColors,
  LineType,
  NoSort,
  HorizBarType,
  VertBarType,
} from "../../QuestionChart";

import { SegmentSettings } from "./Colors/SegmentSettings";
import { Label } from "components/layouts/Label/Label";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { combinedQs } from "../../Visualization";
import { splitting } from "../SettingsAccordion";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import NumberInputWithSlider from "components/NumberInputWithSlider/NumberInputWithSlider";
import ColorPickerContainer from "components/inputs/input_fields/ColorPicker/ColorPickerContainer";

export const ColorSettings = ({
  viz,
  title,
  changeSettingsField,
  labels,
  updateViz,
  visible,
  setVisible,
}) => {
  function updateSettings(newSettings) {
    let newViz = { ...viz };
    newViz.designSettings = newSettings;
    updateViz(newViz);
  }

  function handleBorderRadiusChange(val) {
    changeSettingsField("borderRadius", val);
  }

  function handleBarWidthChange(val) {
    val = val / 10;
    changeSettingsField("barPercentage", val);
  }

  const QChart = !viz.dataMethod || viz.dataMethod === "question";

  return (
    <>
      <div
        key={viz.id}
        className={`${styles.header} ${visible ? styles.headervisible : ""}`}
        onClick={!visible ? setVisible : undefined}
      >
        <FlexRow
          onClick={visible ? setVisible : undefined}
          style={{ cursor: "pointer" }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
            {title}{" "}
            <i style={{ fontSize: "1.2em" }} className="bi-bar-chart"></i>
          </div>
          <span className={styles.accordionicon}>
            <i className="bi bi-caret-left-fill"></i>
          </span>
        </FlexRow>

        {visible && (
          <>
            {viz.type === LineType && !splitting(viz) && (
              <>
                <div
                  className={styles.setting2}
                  style={{ gap: "10px", paddingLeft: "10px" }}
                >
                  <ColorPicker
                    defaultColor={viz.designSettings.lineGraphColor}
                    onChange={(val) =>
                      changeSettingsField("lineGraphColor", val)
                    }
                    textColors
                  />
                  <Label
                    style={{
                      width: "fit-content",
                    }}
                  >
                    Line Color
                  </Label>
                </div>

                <div
                  className={styles.setting2}
                  style={{
                    gap: "10px",
                    paddingLeft: "10px",
                  }}
                >
                  <ToggleSwitch
                    startChecked={viz.designSettings.hasUniformPointColor}
                    handleCheck={(val) =>
                      changeSettingsField("hasUniformPointColor", val)
                    }
                  ></ToggleSwitch>
                  <Label
                    style={{
                      width: "fit-content",
                    }}
                  >
                    Points All Same Color
                  </Label>
                </div>

                {viz.designSettings.hasUniformPointColor && (
                  <div
                    className={styles.setting2}
                    style={{ gap: "10px", paddingLeft: "15px" }}
                  >
                    <ColorPicker
                      defaultColor={viz.designSettings.uniformPointColor}
                      onChange={(val) =>
                        changeSettingsField("uniformPointColor", val)
                      }
                    />
                    <Label
                      style={{
                        width: "fit-content",
                      }}
                    >
                      Point Color
                    </Label>
                  </div>
                )}
              </>
            )}

            {((QChart && combinedQs(viz).length > 0) || !QChart) &&
              !(
                viz.type === LineType &&
                viz.designSettings.hasUniformPointColor &&
                !splitting(viz)
              ) && (
                <>
                  {viz.type != TableType && (
                    <SegmentSettings
                      viz={viz}
                      changeSettingsField={changeSettingsField}
                      updateSettings={updateSettings}
                      labels={labels}
                    />
                  )}
                </>
              )}
            {viz.designSettings.colors &&
              viz.designSettings.sortData !== NoSort &&
              !splitting(viz) &&
              !(
                viz.type === LineType && viz.designSettings.hasUniformPointColor
              ) && (
                <div
                  className={styles.row}
                  style={{
                    paddingLeft: "10px",
                    paddingTop: "10px",
                  }}
                >
                  <ToggleSwitch
                    startChecked={viz.designSettings.afterSort}
                    handleCheck={(val) => changeSettingsField("afterSort", val)}
                  ></ToggleSwitch>
                  <Label
                    style={{
                      width: "fit-content",
                      fontSize: ".85em",
                    }}
                  >
                    Apply colors after sorting
                  </Label>
                </div>
              )}
            {viz.type === TableType && (
              <div
                className={styles.setting2}
                style={{ gap: "10px", paddingLeft: "10px" }}
              >
                <ColorPicker
                  defaultColor={viz.designSettings.tableColor}
                  onChange={(val) => changeSettingsField("tableColor", val)}
                />
                <Label
                  style={{
                    width: "fit-content",
                  }}
                >
                  Table Color
                </Label>
              </div>
            )}

            {(viz.type === HorizBarType || viz.type === VertBarType) && (
              <>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <NumberInputWithSlider
                    val={
                      viz.designSettings.barPercentage
                        ? viz.designSettings.barPercentage * 10
                        : 9
                    }
                    min={1}
                    max={15}
                    step={0.5}
                    onChange={handleBarWidthChange}
                    color={"#2A627C"}
                    title={"Bar Width"}
                  ></NumberInputWithSlider>
                </div>
              </>
            )}

            {viz.type != LineType && viz.type != TableType && (
              <>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <NumberInputWithSlider
                    val={
                      viz.designSettings.borderRadius
                        ? viz.designSettings.borderRadius
                        : 0
                    }
                    min={0}
                    max={20}
                    step={1}
                    onChange={handleBorderRadiusChange}
                    color={"#2A627C"}
                    title={"Bar Border Radius"}
                  ></NumberInputWithSlider>
                </div>
                <NumberInputWithSlider
                  val={viz?.designSettings?.segmentBorder}
                  onChange={(val) => changeSettingsField("segmentBorder", val)}
                  min={0}
                  max={40}
                  step={1}
                  title="Segment Border"
                />

                <ColorPickerContainer
                  defaultColor={
                    viz?.designSettings?.segmentBorderColor
                      ? viz?.designSettings?.segmentBorderColor
                      : "#000000"
                  }
                  onChange={(val) =>
                    changeSettingsField("segmentBorderColor", val)
                  }
                  textColors
                  title="Segment Border Color"
                  style={{
                    backgroundColor: "transparent",
                    padding: "0",
                    margin: "0",
                  }}
                ></ColorPickerContainer>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
