/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

import { useGqlQuery, useGqlMutation } from "api/Api";

export const useFetchMyOrg = () => {
  const query = gql`
    query MyOrganization {
      organization: myOrganization {
        id
        name
        custom_field {
          id
          properties
          name
          displayName
          delimiter
        }
      }
    }
  `;

  return useGqlQuery(["orgDash"], query, {});
};

export const useCheckOrgDash = () => {
  const query = gql`
    query MyOrganization {
      organization: myOrganization {
        dashboard {
          id
        }
        showDash
        dashboardId
      }
    }
  `;

  return useGqlQuery(["dash for org"], query, {});
};

export const useFetchCurrOrgsFields = () => {
  const query = gql`
    query Query {
      getCurrOrgFields {
        delimiter
        id
        name
        properties
        displayName
        filterable
      }
    }
  `;

  return useGqlQuery(["orgFields"], query, {});
};

export const useSearchCurrOrgsFields = (search) => {
  const query = gql`
    query {
      fields: searchCurrOrgFields(search: "${search}") {
        delimiter
        id
        name
        properties
        displayName
        filterable
      }
    }
  `;

  return useGqlQuery(["search_org_fields"], query, {});
};

export const useSearchCurrOrgsFieldsNoProps = (search) => {
  const query = gql`
    query {
      fields: searchCurrOrgFields(search: "${search}") {
        id
        name
        displayName
      }
    }
  `;

  return useGqlQuery(["search_org_fields_no_props"], query, {});
};

export const useFetchAnOrgsFields = (id) => {
  const query = gql`
    query {
      getOrgFields(id: "${id}") {
        id
        properties
        name
        displayName
        delimiter
      }
    }
  `;

  return useGqlQuery([id + " customFields"], query, {});
};

export const useFetchOrgFieldsNoProps = (id) => {
  const query = gql`
    query {
      getOrgFields(id: "${id}") {
        id
        name
        displayName
      }
    }
  `;

  return useGqlQuery([id + " customFieldsNoProp"], query, {});
};

export const useIsHealthCatalyst = () => {
  const query = gql`
    query Query {
      isHealthCatalyst
    }
  `;

  return useGqlQuery(["isHC"], query, {});
};

export const useUpdateCustomField = () => {
  const mutation = gql`
    mutation Mutation($data: CustomFieldInput!, $id: String!) {
      updateCustomField(id: $id, data: $data)
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("orgFields");
      queryClient.invalidateQueries("fetchmyorg");
    },
  };
  return useGqlMutation(mutation, options);
};

export const useUpdateCustomFieldAndContacts = () => {
  const mutation = gql`
    mutation UpdateCustomFieldAndAllContacts(
      $updateFields: UpdateContactFields!
      $data: CustomFieldInput!
      $id: String!
    ) {
      updateCustomFieldAndAllContacts(
        id: $id
        data: $data
        updateFields: $updateFields
      )
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("orgFields");
      queryClient.invalidateQueries("fetchmyorg");
    },
  };
  return useGqlMutation(mutation, options);
};

export const useDeleteCustomField = () => {
  const mutation = gql`
    mutation Mutation($id: String!) {
      deleteCustomField(id: $id)
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("orgFields");
      queryClient.invalidateQueries("fetchmyorg");
    },
  };
  return useGqlMutation(mutation, options);
};

export const useCreateCustomField = () => {
  const mutation = gql`
    mutation Mutation($data: CustomFieldInput!) {
      createCustomField(data: $data) {
        id
        name
        properties
        displayName
        delimiter
      }
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("orgFields");
      queryClient.invalidateQueries("fetchmyorg");
    },
  };
  return useGqlMutation(mutation, options);
};

export const useFetchCustomField = () => {
  const mutation = gql`
    mutation Mutation($id: String!) {
      field: fetchCustomField(id: $id) {
        id
        name
        properties
        displayName
        delimiter
        filterable
      }
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("orgFields");
      queryClient.invalidateQueries("fetchmyorg");
    },
  };
  return useGqlMutation(mutation, options);
};

export const useFetchOrganizations = (search) => {
  const query = gql`
    query {
      organizations: getOrganizations(search: "${search}") {
        name
        displayName
        timezone
        id
        image {
          isLogo
          imageURL
        }
      }
    }
  `;

  return useGqlQuery(["fetchmyorgs"], query, {});
};

export const useFetchAllOrganizations = (searchString, page, perPage, sort) => {
  const query = gql`
    query Query(
      $search: String!
      $page: Float!
      $perPage: Float!
      $sort: sortSelectionDelivery!
    ) {
      object: getAllOrganizations(
        search: $search
        page: $page
        perPage: $perPage
        sort: $sort
      ) {
        organizations {
          name
          displayName
          timezone
          id
          customFields
          defaultLocale
          sendGridName
          createdAt
          subdomain
          showDash
          dashboardId
          address1
          address2
          country
          city
          state
          postalCode
          billingAddress1
          billingAddress2
          billingCountry
          billingCity
          billingState
          billingPostalCode
          invoiceAddress1
          invoiceAddress2
          invoiceCountry
          invoiceCity
          invoiceState
          invoicePostalCode
          usersAllowed
          contactsAllowed
          reportsAllowed
          responsesAllowed
          surveysAllowed
          isLiscense
          plan
          planFrequency
          planPrice
          isLiscense
        }
        count
      }
    }
  `;

  return useGqlQuery(["reportOrganization"], query, {
    search: searchString,
    page: page,
    perPage: perPage,
    sort: sort,
  });
};

export const useFetchOrganization = (id) => {
  const query = gql`
    query {
      organization(id: "${id}") {
        name
        displayName
        timezone
        id
        customFields
        defaultLocale
        sendGridName
        createdAt
        subdomain
        showDash
        dashboardId
        address1
        address2
        country
        city
        state
        postalCode
        billingAddress1
        billingAddress2
        billingCountry
        billingCity
        billingState
        billingPostalCode
        invoiceAddress1
        invoiceAddress2
        invoiceCountry
        invoiceCity
        invoiceState
        invoicePostalCode
        usersAllowed
        contactsAllowed
        reportsAllowed
        responsesAllowed
        surveysAllowed
        adminsAllowed
        plan
        planFrequency
        planPrice
        primaryColor
        secondaryColor
        isLiscense
        project {
          id
          status
        }
        custom_field {
          id
          properties
          name
          displayName
          delimiter
          filterable
        }
      }
    }
  `;

  return useGqlQuery(["fetchmyorg:" + id], query, {});
};

export const useFetchOrganizationSideMenu = (id) => {
  const query = gql`
    query {
      organization(id: "${id}") {
        name
        displayName
      }
    }
  `;

  return useGqlQuery(["fetchmyorgsidemenu"], query, {});
};

export const useGetOrgName = () => {
  const query = gql`
    query MyOrganization {
      myOrganization {
        id
        name
        displayName
      }
    }
  `;

  return useGqlQuery(["orgName"], query, {});
};

export const useFetchSurveyTemplates = () => {
  const query = gql`
    query GetSurveyTemplatesByOrg {
      getSurveyTemplatesByOrg {
        projectId
        organizationId
        name
        description
        id
        imageURL
        category
        type
      }
    }
  `;

  return useGqlQuery(["surveyTemplates"], query, {});
};

export const useUpdateOrgSettings = () => {
  const mutation = gql`
    mutation Mutation(
      $data: OrganizationInput!
      $updateOrganizationId: String!
    ) {
      updateOrganization(data: $data, id: $updateOrganizationId) {
        name
        displayName
        timezone
        showDash
      }
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("fetchmyorg");
      queryClient.invalidateQueries("dash for org");
    },
  };
  return useGqlMutation(mutation, options);
};

export const useUpdateOrg = () => {
  const mutation = gql`
    mutation Mutation($data: OrganizationInput!) {
      updateOrganizationSimple(data: $data)
    }
  `;

  return useGqlMutation(mutation, {});
};

export const useCreateOrgainization = () => {
  const mutation = gql`
    mutation Mutation(
      $data: OrganizationInput!
      $users: String!
      $meAdmin: Boolean!
    ) {
      createOrganization(data: $data, users: $users, meAdmin: $meAdmin) {
        id
      }
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("createOrganization");
    },
  };
  return useGqlMutation(mutation, [], options);
};

export const useJoinOrgainization = () => {
  const mutation = gql`
    mutation Mutation($joinCode: String!) {
      success: joinOrganization(joinCode: $joinCode)
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("joinOrganization");
    },
  };
  return useGqlMutation(mutation, [], options);
};

export const useCreateOrgDash = () => {
  const mutation = gql`
    mutation CreateOrgDash($orgId: String!) {
      createOrgDash(orgId: $orgId)
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("fetchmyorg");
      queryClient.invalidateQueries("dash for org");
    },
  };
  return useGqlMutation(mutation, options);
};

export const useGetOrgLogo = () => {
  const query = gql`
    query getOrgLogo {
      getOrgLogo {
        id
        imageURL
        position
        scale
        name
        about
      }
    }
  `;

  return useGqlQuery(["OrganizationLogo"], query, {});
};

export const useGetOrgLogoUnique = (orgId, index) => {
  const query = gql`
  query {
    getLogoLinkForOrg(orgId: "${orgId}") {
      id
      imageURL
      position
      scale
      name 
      about
    }
  }
  
  `;

  return useGqlQuery(["logo, " + orgId + " " + index], query, {});
};

export const useRefreshOrgCustomFields = () => {
  const mutation = gql`
    mutation RefreshCustomFields($orgId: String!) {
      refreshCustomFields(orgId: $orgId)
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("fetchmyorg");
    },
  };
  return useGqlMutation(mutation, options);
};
