import React, { useState, useEffect } from "react";
import { Label } from "components/layouts/Label/Label";
import { Header } from "components/layouts";
import styles from "./EditSingle.module.scss";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import {
  useCreateCustomField,
  useFetchCustomField,
  useUpdateCustomFieldAndContacts,
} from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import Button from "components/Button/Button";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";

export default function EditSingleCustomField({
  onClose,
  brandNew,
  field,
  onSaved,
  noModal,
  deleteField,
}) {
  const fetchField = useFetchCustomField();
  useEffect(() => {
    if (!brandNew) {
      fetchField.mutate({
        id: field?.id,
      });
    }
  }, []);

  return (
    <>
      {!noModal && (
        <ReactModal
          show={true}
          onClose={onClose}
          modalStyle={{
            padding: "2em 1em",
            borderRadius: "1em",
            width: "500px",
          }}
          dark
        >
          {fetchField.isLoading && !brandNew && <Loading></Loading>}
          {(fetchField.isSuccess || brandNew) && (
            <Container
              field={fetchField?.data?.field}
              onSaved={onSaved}
              brandNew={brandNew}
              index={brandNew ? 0 : field?.index}
              deleteField={deleteField}
            />
          )}
        </ReactModal>
      )}
      {noModal && fetchField.isLoading && <Loading></Loading>}
      {noModal && fetchField.isSuccess && (
        <Container
          field={fetchField?.data?.field}
          onSaved={onSaved}
          brandNew={brandNew}
          index={field.index}
          deleteField={deleteField}
          // fieldCopy={fieldCopy}
          // editField={editField}
          // valid={valid}
          // setAddProperty={setAddProperty}
          // addProperty={addProperty}
          // saveStatus={saveStatus}
          // handleDeleteProp={handleDeleteProp}
          // handleAddProp={handleAddProp}
          // handleSaveField={handleSaveField}
          // brandNew={brandNew}
          // reload={reload}
          // handleUpdateProp={handleUpdateProp}
          // noModal={noModal}
          // saveStatus2={saveStatus2}
        ></Container>
      )}
    </>
  );
}

function Container({ field, brandNew, deleteField, index }) {
  const initField = () => {
    if (field && !brandNew) {
      let copy = { ...field };
      if (typeof copy.properties === "string") {
        copy.properties = JSON.parse(copy.properties);
      }
      return copy;
    }
    if (brandNew) {
      return {
        name: "",
        displayName: "",
        properties: [],
        delimiter: "",
        filterable: true,
      };
    }
  };

  const [fieldCopy, setFieldCopy] = useState(initField());
  const [addProperty, setAddProperty] = useState("");
  const [saveStatus, setSaveStatus] = useState(null);
  const [saveStatus2, setSaveStatus2] = useState(null);
  const [valid, setValid] = useState(true);
  const [changes, setChanges] = useState({});
  const [reload, setReload] = useState(false);
  const updateFieldAndContacts = useUpdateCustomFieldAndContacts();
  const createField = useCreateCustomField();

  function makeEdit(copy) {
    if (!valid && copy?.name) {
      setValid(true);
    }

    if (field) {
      // check to see if it is different than before
      if (
        field?.name !== copy?.name ||
        field?.displayName !== copy.displayName ||
        field?.delimiter !== copy?.delimiter ||
        JSON.stringify(field.properties) !== JSON.stringify(copy.properties) ||
        field.filterable !== copy.filterable
      ) {
        if (!saveStatus) {
          setSaveStatus("Save + Update Contacts");
        }
      } else {
        setSaveStatus(null);
      }
    } else if (!saveStatus && brandNew) {
      // it is a new field being edited for the first time
      setSaveStatus("Create");
    }
    // This is so you know something is different. The properties change automatically

    // if (!saveStatus) {
    //   setSaveStatus(brandNew ? "Create" : "Save + Update");
    // }

    setFieldCopy(copy);
  }

  const editField = (fieldInField, val) => {
    let copy = { ...fieldCopy };
    copy[fieldInField] = val;
    makeEdit(copy);
  };

  function handleAddProp() {
    let copy = { ...fieldCopy };
    let props = [...copy.properties];
    props.push(addProperty);
    copy.properties = props;
    makeEdit(copy);
    setAddProperty("");
  }

  function handleDeleteProp(index) {
    if (
      window.confirm(
        "Are you sure you want to delete this field property? It will no longer be visible in your chart filters."
      )
    ) {
      let copy = { ...fieldCopy };
      let props = [...fieldCopy.properties];
      props.splice(index, 1);
      copy.properties = props;
      makeEdit(copy);

      setReload(!reload); // the content editable divs in the FieldProperty's need to re-render. Hence the code duplication with reload vs !reload;

      let record = { ...changes };
      for (let i = index; i < copy.properties.length; i++) {
        if (record[i + 1]) {
          record[i] = record[i + 1];
          delete record[i + 1];
        }
      }
      setChanges(record);
    }
  }

  function handleUpdateProp(property, index) {
    let copy = { ...fieldCopy };
    let props = [...fieldCopy.properties];
    props[index] = property;
    copy.properties = props;
    makeEdit(copy);

    let record = { ...changes };
    if (!record[index]) {
      record[index] = {
        old: fieldCopy.properties[index],
        new: property,
      };
    } else {
      record[index].new = property;
    }
    setChanges(record);
  }

  function handleSaveField(update) {
    if (!fieldCopy?.name) {
      setValid(false);
      return;
    }

    if (update) {
      setSaveStatus(
        <>
          {brandNew ? "Creating" : "Saving"}{" "}
          <Loading height={20} width={20}></Loading>
        </>
      );
    } else {
      setSaveStatus2(
        <>
          {brandNew ? "Creating" : "Saving"}{" "}
          <Loading height={20} width={20}></Loading>
        </>
      );
    }

    let data = { ...fieldCopy };
    data.properties = JSON.stringify(data.properties);

    if (brandNew) {
      createField.mutate(
        {
          data: data,
        },
        {
          onSuccess: (data) => {
            onSaveSuccess(data.createCustomField);
          },
        }
      );
    } else if (field) {
      let id = field.id;
      delete data.id;

      let changedProps = [];
      if (update) {
        for (let ind in changes) {
          changedProps.push(changes[ind]);
        }
      }

      updateFieldAndContacts.mutate(
        {
          id: id,
          data: data,
          updateFields: {
            propsToChange: update ? changedProps : undefined,
          },
        },
        {
          onSuccess: () => {
            onSaveSuccess();
          },
        }
      );
    }
  }

  function handleUpdateFilter() {
    updateFieldAndContacts.mutate(
      {
        id: fieldCopy?.id,
        data: {
          filterable: !fieldCopy.filterable,
        },
        updateFields: {
          propsToChange: [],
        },
      },
      {
        onSuccess: () => {
          setFieldCopy({ ...fieldCopy, filterable: !fieldCopy.filterable });
        },
      }
    );
  }

  function onSaveSuccess(data) {
    setSaveStatus(
      <>
        {brandNew ? "Created" : "Saved"} <i className="bi bi-check"></i>
      </>
    );
    setSaveStatus2(
      <>
        {brandNew ? "Created" : "Saved"} <i className="bi bi-check"></i>
      </>
    );
    setTimeout(() => {
      setSaveStatus(null);
      setSaveStatus2(null);
    }, 2000);
  }

  return (
    <>
      <div className={styles.fieldSettings}>
        <Header title={`${brandNew ? "New" : "Edit"} Custom Field`}></Header>
        <Label
          style={{
            width: "35px",
            height: "35px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            padding: "0em",
            margin: "0em",
            borderRadius: ".5em",
            // fontSize: ".9em",
            cursor: "pointer",
            boxShadow: fieldCopy?.filterable
              ? "rgba(0, 0, 0, 0.24) 0px 3px 8px"
              : "",
            backgroundColor: fieldCopy?.filterable ? "#a6b8bc" : "",
            color: fieldCopy?.filterable ? "white" : "",
            transition: ".3s all",
          }}
          labelIcon={<i className="bi-funnel"></i>}
          tooltipText={
            fieldCopy?.filterable
              ? "Click to remove as filter in Results & Analyze"
              : "Click to make filterable in Results & Analyze"
          }
          tooltipStyle={{
            color: "gray",
          }}
          onClick={() => editField("filterable", !fieldCopy?.filterable)}
        ></Label>
        <div style={{ width: "100px" }}>
          <CombinedInput
            onChange={(val) => editField("delimiter", val)}
            placeholder="None..."
            value={fieldCopy?.delimiter ? fieldCopy?.delimiter : ""}
            label="Delimiter"
          />
        </div>
      </div>

      <div className={styles.fieldSettings}>
        <div style={{ width: "50%" }}>
          <CombinedInput
            label="Field Name*"
            onChange={(val) => editField("name", val)}
            placeholder="Type Field Name..."
            value={fieldCopy?.name}
            valid={valid}
            invalidMessage={"Required"}
          />
        </div>
        <div style={{ width: "50%" }}>
          <CombinedInput
            label="Display Name"
            onChange={(val) => editField("displayName", val)}
            placeholder="Type Display Name..."
            value={fieldCopy?.displayName}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          gap: "1em",
          alignItems: "center",
          fontSize: ".9em",
        }}
      >
        <div style={{ width: "100%" }}>
          <CombinedInput
            onChange={(val) => {
              setAddProperty(val);
            }}
            placeholder="Type Property to Add..."
            value={addProperty}
            onEnter={handleAddProp}
            label="Properties"
          />
        </div>
        {addProperty && (
          <Button onClick={handleAddProp} shadow>
            Add
          </Button>
        )}
      </div>
      <div className={styles.customFieldsBox}>
        {!reload &&
          fieldCopy?.properties &&
          typeof fieldCopy?.properties != "string" &&
          fieldCopy.properties.map((prop, index) => (
            <FieldProperty
              key={index}
              prop={prop}
              index={index}
              deleteProp={handleDeleteProp}
              updateProp={handleUpdateProp}
            ></FieldProperty>
          ))}
        {reload &&
          fieldCopy.properties.map((prop, index) => (
            <FieldProperty
              prop={prop}
              index={index}
              deleteProp={handleDeleteProp}
              updateProp={handleUpdateProp}
            ></FieldProperty>
          ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          bottom: "0em",
          right: "1em",
          gap: "1em",
          marginTop: "1rem",
        }}
      >
        {!brandNew && (
          <Button
            height={35}
            onClick={() => deleteField(field, index)}
            shadow
            red
            style={{ display: "flex", gap: ".5rem" }}
          >
            <i className="bi-trash"></i> Delete
          </Button>
        )}
        {saveStatus && (
          <>
            {/* <div className={styles.saveDivider}></div> */}

            {!saveStatus2 && (
              <Button height={35} onClick={() => handleSaveField(true)} shadow>
                {saveStatus}
              </Button>
            )}

            {!brandNew && (
              <Button height={35} onClick={() => handleSaveField(false)} shadow>
                {!saveStatus2 && "Save"}
                {saveStatus2}
              </Button>
            )}
          </>
        )}
      </div>
    </>
  );
}

function FieldProperty({ prop, index, deleteProp, updateProp }) {
  const [val, setVal] = useState(prop);
  const [edit, setEdit] = useState(false);

  function handleSaveProp(e) {
    if (e.nativeEvent.inputType !== "insertParagraph") {
      let property = e.target.innerText;
      updateProp(property, index);
    } else {
      e.target.innerText = prop;
    }
  }

  function lookForEnter(e) {
    if (e.key === "Enter" || e.keyCode === 13) {
      // remove the selection
      let selection = window.getSelection();
      if (selection.rangeCount > 0) {
        selection.removeAllRanges();
      }
      setEdit(false);
    }
  }

  return (
    <div className={styles.cField}>
      <div
        className={`${styles.name} ${!edit ? styles.editable : ""}`}
        contentEditable
        onClick={() => {
          if (!edit) {
            setEdit(true);
          }
        }}
        onBlur={() => setEdit(false)}
        onKeyDown={lookForEnter}
        onInput={handleSaveProp}
        suppressContentEditableWarning={true}
        spellCheck={false}
      >
        {val}
      </div>
      <div className={`${styles.otherSide} ${edit ? "" : styles.delete}`}>
        <i
          className="bi bi-x"
          style={edit ? { visibility: "hidden" } : {}}
          onClick={() => deleteProp(index)}
        ></i>
      </div>
    </div>
  );
}
