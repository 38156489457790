import styles from "./EditLogo.module.scss";
import styles2 from "../SurveyDesign/SurveyDesign.module.scss";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import ColorPickerContainer from "components/inputs/input_fields/ColorPicker/ColorPickerContainer";
import NumberInputWithSlider from "components/NumberInputWithSlider/NumberInputWithSlider";
import {
  useCreateProjectImage,
  useGetProjectLogo,
  useGetSignedURL,
} from "api/resources/projects/images";
import EditableLogo from "./EditableLogo";
import axios from "axios";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import { shadows } from "assets/functions/Variables";

export function SurveyLogoSettings({
  settings,
  changeSettingsField,
  setSettings,
  projId,
}) {
  // function changeSettingsField(setting, value) {
  //   let tempSettings = { ...settings };
  //   tempSettings[setting] = value;
  //   setSettings(tempSettings);
  // }

  const options = ["left", "center", "right"];

  const getProjectLogo = useGetProjectLogo(projId);

  const getSignedURL = useGetSignedURL();
  const createImage = useCreateProjectImage();

  function onSaveNewImage(file) {
    getSignedURL.mutate(
      {
        filename: file.name,
        filetype: file.type,
      },
      {
        onSuccess: async (data) => {
          await uploadToS3(file, data.getSignedURL.signedRequest);
          createImage.mutate(
            {
              url: data.getSignedURL.url,
              projectId: projId,
            },
            {
              onSuccess: async (data) => {
                getProjectLogo.refetch();
              },
            }
          );
        },
      }
    );
  }

  async function uploadToS3(file, signedRequest) {
    const options = {
      headers: {
        "Content-Type": file.type,
      },
    };
    const instance = axios.create({
      baseURL: "https://reaction.health/",
      headers: { "Access-Control-Allow-Origin": "*" },
    });
    instance.defaults.withCredentials = true;
    let res = await instance.put(signedRequest, file, options);
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {getProjectLogo.isSuccess && (
          <EditableLogo
            settings={settings}
            source={
              getProjectLogo.data.getProjectLogo != null
                ? getProjectLogo.data.getProjectLogo.imageURL
                : "/static/media/reactionlogodark.7d895fefebc952458ad6.png"
            }
            setSettings={setSettings}
            projectLogo={
              getProjectLogo.data.getProjectLogo != null
                ? getProjectLogo.data.getProjectLogo
                : "/static/media/reactionlogodark.7d895fefebc952458ad6.png"
            }
            onSaveNewImage={onSaveNewImage}
          ></EditableLogo>
        )}
      </div>
      <div className={styles2.settingVertical}>
        <NumberInputWithSlider
          val={settings?.logoSize}
          onChange={(val) => changeSettingsField("logoSize", val)}
          min={0}
          max={150}
          step={1}
          color={"#15bcc7"}
          title="Logo Size"
        ></NumberInputWithSlider>
      </div>
      <div className={styles2.settingVertical}>
        <NumberInputWithSlider
          val={
            settings?.logoRadius || settings?.logoRadius === 0
              ? settings?.logoRadius
              : 0
          }
          onChange={(val) => changeSettingsField("logoRadius", val)}
          min={0}
          max={4}
          step={0.25}
          color={"#15bcc7"}
          title="Logo Border Radius"
        ></NumberInputWithSlider>
      </div>
      <div className={styles2.settingVertical}>
        <div className={styles.label6}>Logo</div>

        <div className={styles2.settingVertical}>
          <div className={styles2.settingHorizontal}>
            {" "}
            <ToggleSwitch
              startChecked={settings.showLogo}
              handleCheck={(val) => changeSettingsField("showLogo", val)}
            ></ToggleSwitch>{" "}
            <div className={styles.text_3}>Show Logo</div>
          </div>

          <div className={styles2.settingHorizontal}>
            <ToggleSwitch
              startChecked={!settings.logoOnEveryPage}
              handleCheck={(val) =>
                changeSettingsField("logoOnEveryPage", !val)
              }
            ></ToggleSwitch>

            <div className={styles.text_3}>Logo first page only</div>
          </div>

          <div className={styles2.settingHorizontal}>
            <ToggleSwitch
              startChecked={settings?.logoBackgroundColor}
              handleCheck={(val) =>
                changeSettingsField(
                  "logoBackgroundColor",
                  val ? "#FFFFFF" : false
                )
              }
            ></ToggleSwitch>

            <div className={styles.text_3}>Has Background Color</div>
          </div>
        </div>

        {settings?.logoBackgroundColor && (
          <ColorPickerContainer
            onChange={(color) =>
              changeSettingsField("logoBackgroundColor", [color])
            }
            defaultColor={
              settings.logoBackgroundColor
                ? settings.logoBackgroundColor
                : settings.backgroundColor
                ? settings?.backgroundColor
                : "#f9fbfc"
            }
            title="Logo Background Color"
          />
        )}
      </div>
      <div className={styles2.settingVertical}>
        <CombinedInput
          onChange={(color) =>
            changeSettingsField("logoBoxShadow", color.value)
          }
          value={
            settings?.logoBoxShadow
              ? shadows.find((s) => s.value === settings?.logoBoxShadow)
              : { value: "", label: "No Shadow" }
          }
          label="Logo Border Shadow"
          icon="bi-chevron-down"
          options={shadows}
          select
          anyWhereOptions
        />
      </div>
      <div className={styles2.settingVertical}>
        <div className={styles.label6}>Logo Alignment</div>
        <div className={styles2.settingHorizontal}>
          {options.map((opt, i) => (
            <div
              key={i}
              className={`${styles.alignmentBtn} ${
                settings.logoAlign === opt ? styles.chosenAlignment : ""
              }`}
              onClick={() => changeSettingsField("logoAlign", opt)}
            >
              <i className={`bi bi-text-${opt}`}></i>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
