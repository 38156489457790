/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction Data. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: brodyspencer

*/
//External
import React, { useState } from "react";

//Internal
import styles from "./Question.module.scss";
import { NumberScaleQuestion } from "./Types/NumberScaleQuestion";
import { MultipleChoice } from "./Types/MultipleChoice/MultipleChoice";
import TextQ from "components/Survey/UXQuestions/TextQuestion/TextQ";
import TextEditorTry from "components/inputs/input_fields/TextEditorTry/TextEditorTry";
import { NumberSlideQuestion } from "./Types/NumberSlideQuestion";
import Matrix from "./Types/Matrix/Matrix";

/**
 * A component that displays the current question
 * @param {function} question the question being used to display
 * @returns {React.ReactElement} a QuestionConstruction component
 */
const Question = ({
  question,
  active,
  saveQuestion,
  designSettings,
  roles,
  onAnswer,
  onRetract,
  answerMap,
  dependents,
  setUpLogicResolver,
  questionNumber,
  checkHealth,
}) => {
  function handleSaveText(text, encoding) {
    question.questionText = text;
    question.encoding = encoding;
    saveQuestion(question);
  }

  function saveDescription(text, encoding) {
    question.description = text;
    question.descriptionEncoding = encoding;
    saveQuestion(question);
  }

  return (
    <>
      {/* <div className={styles.number}>{`${questionNumber}.`}</div> */}
      <div
        style={{
          display: "flex",
          width: "100%",
          marginBottom: active ? ".5rem" : "",
        }}
      >
        {designSettings?.numbers && (
          <div className={styles.number2}>{`${questionNumber}.`}</div>
        )}
        <TextEditorTry
          editable={active && roles.canCreateEditOrgSurveys ? true : false}
          encoding={question.encoding}
          placeholder={"Question Name"}
          defaultStyle={{
            cursor: !active ? "pointer" : "",
            color: designSettings?.questionTextColor
              ? designSettings?.questionTextColor
              : "black",
            fontSize: designSettings?.questionNameSize
              ? designSettings?.questionNameSize
              : "12pt",
          }}
          // onChange={handleSaveText}
          onSave={handleSaveText}
          style={{
            cursor: !active ? "pointer" : "",
            color: designSettings?.questionTextColor
              ? designSettings?.questionTextColor
              : "",
            fontSize: designSettings?.questionNameSize
              ? designSettings?.questionNameSize
              : "",
            borderRadius: ".5rem",
            borderTopLeftRadius: ".5rem",
            borderTopRightRadius: ".5rem",
          }}
          containerStyle={{
            // borderBottom: active ? "1px solid lightgray" : "",
            borderRadius: ".5rem",
            backgroundColor: active ? "#f5f5f5" : "",
          }}
          placeholderStyle={{
            cursor: !active ? "pointer" : "",
            // color: designSettings?.questionTextColor
            //   ? designSettings?.questionTextColor
            //   : "",
            fontSize: designSettings?.questionNameSize
              ? designSettings?.questionNameSize
              : "",
          }}
          charCheck={checkHealth ? 185 : null}
        />
      </div>
      {!active &&
        question.description != "\n" &&
        question.description != "" && (
          <TextEditorTry
            editable={active && roles.canCreateEditOrgSurveys ? true : false}
            encoding={question.descriptionEncoding}
            placeholder={"Question Instructions"}
            defaultStyle={{
              fontSize: "9pt",
              fontStyle: "italic",
              color: "#a3a4a8",
            }}
            placeholderStyle={{
              fontSize: "9pt",
              fontStyle: "italic",
            }}
            // onChange={saveDescription}
            onSave={saveDescription}
            style={{
              cursor: !active ? "pointer" : "",
              color: designSettings?.instructionTextColor
                ? designSettings?.instructionTextColor
                : "",
              fontSize: designSettings?.questionInstructionSize
                ? designSettings?.questionInstructionSize
                : "",
              borderRadius: ".5rem",
              borderTopLeftRadius: ".5rem",
              borderTopRightRadius: ".5rem",
            }}
            containerStyle={{
              borderRadius: ".5rem",
              backgroundColor: active ? "#f5f5f5" : "",
            }}
          />
        )}
      {/* {active && <div className={styles.spacer} />} */}
      {active && (
        <TextEditorTry
          editable={active && roles.canCreateEditOrgSurveys ? true : false}
          encoding={question.descriptionEncoding}
          placeholder={"Question Instructions"}
          defaultStyle={{
            fontSize: "9pt",
            fontStyle: "italic",
            color: "#a3a4a8",
          }}
          placeholderStyle={{
            fontSize: "9pt",
            fontStyle: "italic",
          }}
          // onChange={saveDescription}
          onSave={saveDescription}
          style={{
            cursor: !active ? "pointer" : "",
            color: designSettings?.instructionTextColor
              ? designSettings?.instructionTextColor
              : "",
            fontSize: designSettings?.questionInstructionSize
              ? designSettings?.questionInstructionSize
              : "",
            borderRadius: ".5rem",
            borderTopLeftRadius: ".5rem",
            borderTopRightRadius: ".5rem",
          }}
          containerStyle={{
            borderRadius: ".5rem",
            backgroundColor: active ? "#f5f5f5" : "",
          }}
          charCheck={checkHealth ? 80 : null}
        />
      )}
      {/* {active && <div className={styles.spacer} style={{ height: "20px" }} />} */}
      <div className={styles.spacer} />
      <div>
        {(question.type === "MultipleChoice" ||
          question.type === "Ranking") && (
          <MultipleChoice
            question={question}
            active={active}
            saveQuestion={saveQuestion}
            designSettings={designSettings}
            onAnswer={onAnswer}
            onRetract={onRetract}
            answerMap={answerMap}
            dependents={dependents}
            setUpLogicResolver={setUpLogicResolver}
          />
        )}
        {question.type === "NumberScale" && (
          <NumberScaleQuestion
            question={question}
            color={designSettings.baseColor}
            onAnswer={onAnswer}
            onRetract={onRetract}
            answerMap={answerMap}
          />
        )}
        {question.type === "NumberSlider" && (
          <NumberSlideQuestion
            question={question}
            color={designSettings.baseColor}
            onAnswer={onAnswer}
            answerMap={answerMap}
          />
        )}
        {question.type === "Text" && (
          <TextQ
            question={question}
            onAnswered={onAnswer}
            onRetract={onRetract}
            color={designSettings.baseColor}
            value={answerMap[question.id] ? answerMap[question.id] : ""}
            answered={answerMap[question.id]}
          />
        )}
        {question.type === "Matrix" && (
          <Matrix
            question={question}
            active={active}
            saveQuestion={saveQuestion}
            designSettings={designSettings}
            onAnswer={onAnswer}
            onRetract={onRetract}
            answerMap={answerMap}
            dependents={dependents}
            setUpLogicResolver={setUpLogicResolver}
          />
        )}
      </div>
    </>
  );
};

export default Question;
