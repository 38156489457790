import styles from "./Conditions.module.scss";
import { Label } from "components/layouts/Label/Label";
import { TextFieldSimple } from "components/inputs";
import Button from "components/Button/Button";
import { useState, useEffect } from "react";

export const Conditions = ({
  customFields,
  conditions,
  setConditions,
  onPick,
  init,
  onConditionSwap,
}) => {
  const conditionOptions = [
    {
      name: "Exists",
      value: "exists",
    },
    {
      name: "Contains",
      value: "contains",
    },
    {
      name: "Does Not Contain",
      value: "does not contain",
    },
    {
      name: "Equals",
      value: "equals",
    },
    {
      name: "Does Not Equal",
      value: "does not equal",
    },
  ];

  const firstCondition = {
    field: "firstName",
    condition: "exists",
    value: "",
    show: ["firstName"],
    else: "",
    name: "first name",
  };
  const [edit, setEdit] = useState(init || !conditions || !conditions?.length);
  const [brandNew, setBrandNew] = useState(!conditions || !conditions.length);
  const [condition, setCondition] = useState(
    init && conditions
      ? conditions.find((c) => c.name === init)
      : firstCondition
  );
  const [ind, setInd] = useState(
    init && conditions ? conditions.findIndex((c) => c.name === init) : -1
  );

  const [run, setRun] = useState(false);

  useEffect(() => {
    if (run) {
      setRun(false);
      init ? onConditionSwap(condition) : onPick(condition);
    }
  }, [run]);

  function changeField(field, value) {
    let temp = { ...condition };
    temp[field] = value;
    setCondition(temp);
  }

  function switchToOptions(field, value) {
    if (value !== "staticText") {
      let temp = { ...condition };
      temp[field] = [value];
      setCondition(temp);
    }
  }

  function editOptions(field, value, i) {
    let temp = { ...condition };
    if (value === "staticText") {
      temp[field] = "";
    } else {
      temp[field][i] = value;
    }
    setCondition(temp);
  }

  function addOption(field) {
    let temp = { ...condition };
    temp[field].push("firstName");
    setCondition(temp);
  }

  function removeOption(field, i) {
    let temp = { ...condition };
    temp[field].splice(i, 1);
    setCondition(temp);
  }

  function useCondition() {
    let copy = conditions ? [...conditions] : [];
    if (brandNew) {
      copy.push(condition);
    } else {
      copy[ind] = condition;
    }

    setConditions(copy);
    // onPick(condition);
    setRun(true);
  }

  function deleteCondition() {
    let temp = [...conditions];
    temp.splice(ind, 1);
    setConditions(temp);

    if (temp.length) {
      setEdit(false);
      setInd(-1);
    } else {
      buildNew();
    }
  }

  const fields = [
    ...[
      {
        name: "firstName",
        displayName: "First Name",
      },
      {
        name: "lastName",
        displayName: "Last Name",
      },
    ],
    ...customFields,
  ];

  const fieldsPlusStatic = [
    { displayName: "Static Text", name: "staticText" },
    ...fields,
  ];

  function setUpEdit(condition, i) {
    setCondition(JSON.parse(JSON.stringify(condition)));
    setBrandNew(false);
    setEdit(true);
    setInd(i);
  }

  function buildNew() {
    let cond = { ...firstCondition };

    if (conditions && conditions.some((c) => cond.name === c.name)) {
      let ind = 2;
      let name = cond.name + ind;
      while (conditions.some((c) => c.name === name)) {
        ind++;
        name = cond.name + ind;
      }
      cond.name = name;
    }

    setCondition(cond);
    setBrandNew(true);
    setEdit(true);
    setInd(-1);
  }

  const showUse =
    edit &&
    !(
      conditions &&
      conditions.some((c, i) => i !== ind && c.name === condition.name)
    );

  // console.log(activeCondition);
  return (
    <div className={styles.builder}>
      {/* <Label
        style={{ padding: "0em", marginBottom: "5px" }}
        tooltipText={
          "Drag and drop the created Conditions below into editor or just type it in the email."
        }
        labelIcon={<i className="bi bi-question-circle"></i>}
      >
        Add Conditions
      </Label> */}
      {conditions && conditions.length > 0 && (
        <>
          <div className={styles.conditions}>
            {conditions.map((condition, i) => (
              <Condition
                key={i}
                condition={condition}
                setActive={() => setUpEdit(condition, i)}
                active={ind == i}
              ></Condition>
            ))}
            <div
              className={`${styles.condition} ${styles.buildNew} ${
                brandNew ? styles.hover : ""
              }`}
              onClick={buildNew}
            >
              + Create New
            </div>
          </div>

          {edit && <div className={styles.divider}></div>}
        </>
      )}
      {edit && (
        <div
          className={styles.edit}
          style={{
            height: condition ? "fit-content" : "",
            width: condition ? "100%" : "",
            display: condition ? "flex" : "",
          }}
        >
          <div className={styles.group}>
            <Label>
              {brandNew ? "Create Condition" : `Editing '${condition.name}'`}
            </Label>
            <div className={styles.row}>
              If{" "}
              <select
                value={condition.field}
                onChange={(e) => changeField("field", e.target.value)}
                className={styles.select}
              >
                {fields.map((field) => (
                  <option key={field.name} value={field.name}>
                    {field.displayName}
                  </option>
                ))}
              </select>
              <select
                value={condition.condition}
                onChange={(e) => changeField("condition", e.target.value)}
                className={styles.select}
              >
                {conditionOptions.map((field) => (
                  <option key={field.name} value={field.value}>
                    {field.name}
                  </option>
                ))}
              </select>{" "}
              {condition.condition !== "exists" && (
                <input
                  placeholder={
                    conditionOptions.find(
                      (c) => c.value === condition.condition
                    ).name + " ___?"
                  }
                  value={condition.value}
                  onChange={(e) => changeField("value", e.target.value)}
                  className={styles.textInput}
                ></input>
              )}
            </div>

            <div className={styles.row} style={{ paddingLeft: "10px" }}>
              show{" "}
              {Array.isArray(condition.show) ? (
                <>
                  {condition.show.map((show, i) => (
                    <div className={styles.option}>
                      <select
                        key={i}
                        value={show}
                        onChange={(e) => editOptions("show", e.target.value, i)}
                        className={styles.select}
                      >
                        {(!i && condition.show.length == 1
                          ? fieldsPlusStatic
                          : fields
                        ).map((field) => (
                          <option key={field.name} value={field.name}>
                            {field.displayName}
                          </option>
                        ))}
                      </select>
                      {condition.show.length > 1 && (
                        <span
                          className={styles.cancelOption}
                          onClick={() => removeOption("show", i)}
                        >
                          <i className="bi bi-x"></i>
                        </span>
                      )}
                    </div>
                  ))}{" "}
                  <div
                    className={styles.editPencil}
                    onClick={(e) => addOption("show")}
                  >
                    +
                  </div>
                </>
              ) : (
                <>
                  <select
                    value={"staticText"}
                    onChange={(e) => switchToOptions("show", e.target.value)}
                    className={`${styles.select} ${styles.staticSelect}`}
                  >
                    {fieldsPlusStatic.map((field) => (
                      <option key={field.name} value={field.name}>
                        {field.displayName}
                      </option>
                    ))}
                  </select>

                  <input
                    placeholder={"nothing"}
                    value={condition.show}
                    onChange={(e) => changeField("show", e.target.value)}
                    className={styles.textInput}
                  ></input>
                </>
              )}
            </div>
          </div>

          <div className={styles.group}>
            <div>Else </div>
            <div className={styles.row} style={{ paddingLeft: "10px" }}>
              show
              {Array.isArray(condition.else) ? (
                <>
                  {condition.else.map((show, i) => (
                    <div className={styles.option}>
                      <select
                        key={i}
                        value={show}
                        onChange={(e) => editOptions("else", e.target.value, i)}
                        className={styles.select}
                      >
                        {(!i && condition.else.length == 1
                          ? fieldsPlusStatic
                          : fields
                        ).map((field) => (
                          <option key={field.name} value={field.name}>
                            {field.displayName}
                          </option>
                        ))}
                      </select>
                      {condition.else.length > 1 && (
                        <span
                          className={styles.cancelOption}
                          onClick={() => removeOption("else", i)}
                        >
                          <i className="bi bi-x"></i>
                        </span>
                      )}
                    </div>
                  ))}
                  <div
                    className={styles.editPencil}
                    onClick={(e) => addOption("else")}
                  >
                    +
                  </div>
                </>
              ) : (
                <>
                  <select
                    value={"staticText"}
                    onChange={(e) => switchToOptions("else", e.target.value)}
                    className={`${styles.select} ${styles.staticSelect}`}
                  >
                    {fieldsPlusStatic.map((field) => (
                      <option key={field.name} value={field.name}>
                        {field.displayName}
                      </option>
                    ))}
                  </select>

                  <input
                    placeholder={"nothing"}
                    value={condition.else}
                    onChange={(e) => changeField("else", e.target.value)}
                    className={styles.textInput}
                  ></input>
                </>
              )}
              {/* <div> Or</div>
              <input
                // placeholder={
                //   conditionOptions.find((c) => c.value === condition.condition)
                //     .name + " ___?"
                // }
                value={condition.else}
                onChange={(e) =>
                  changeActiveConditionField("else", e.target.value)
                }
                className={styles.textInput}
              ></input> */}
            </div>
          </div>
          <div>
            <TextFieldSimple
              placeholder="Type Condition Name..."
              label="Condition Name"
              value={condition.name}
              onChange={(e) => changeField("name", e)}
            ></TextFieldSimple>
            {!showUse && (
              <div className={styles.mustHave}>Must be a unique name*</div>
            )}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            <div>
              <span>{"{"}</span>
              <span className={styles.example}>condition</span>
              <span className={styles.exampleName}>{` ${condition.name}`}</span>
              <span>{"}"}</span>
            </div>

            {/* {brandNew && (
              <Button
                height={30}
                shadow
                onClick={() => {
                  setShowFields(false);
                  setActiveCondition("");
                }}
                red
              >
                {" "}
                Cancel{" "}
              </Button>
            )} */}
            <div className={styles.row} style={{ gap: "15px" }}>
              {!brandNew && (
                <Button height={30} onClick={deleteCondition} gray shadow>
                  Delete
                </Button>
              )}
              {showUse && (
                <Button height={30} onClick={useCondition} seafoam shadow>
                  Use
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function Condition({ condition, setActive, active }) {
  return (
    <span
      onClick={setActive}
      className={`${styles.condition} ${active ? styles.hover : ""}`}
    >
      <span>{"{"}</span>
      <span className={styles.example}>condition</span>
      <span className={styles.exampleName}>{` ${condition.name}`}</span>
      <span>{"}"}</span>
    </span>
  );
}
