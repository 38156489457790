import React, { useState } from "react";
import { useFetchOrgFieldsNoProps } from "api/resources/organization/organization";
import { BasicTable } from "components/tables/BasicTable/BasicTable";
import { PaginationContainer } from "components/tables/BasicTable/PaginationContainer";
import { Pagination } from "components/tables/BasicTable/Pagination";

function ContactTable({
  contacts,
  orgId,
  sort,
  setSort,
  perPage,
  setPerPage,
  setPageSkip,
  count,
}) {
  const fetchCustomFields = useFetchOrgFieldsNoProps(orgId);
  
  return (
    <>
      {fetchCustomFields.isSuccess && (
        <TheTable
          customFields={fetchCustomFields.data.getOrgFields}
          contacts={contacts}
          sort={sort}
          setSort={setSort}
          perPage={perPage}
          setPerPage={setPerPage}
          setPageSkip={setPageSkip}
          count={count}
        ></TheTable>
      )}
    </>
  );
}

export default ContactTable;

function TheTable({
  customFields,
  contacts,
  sort,
  setSort,
  perPage,
  setPerPage,
  setPageSkip,
  count,
}) {
  const [headers, setHeaders] = useState(initHeaders());
  const [pageNumber, setPageNumber] = useState(1);


  function initHeaders() {
    let heads = [
      {
        id: 0,
        name: "Email",
        accessor: "email",
        enabled: true,
        canSort: true,
        sorted: true,
        cell_style: null,
      },
      {
        id: 2,
        name: "First Name",
        accessor: "firstName",
        enabled: true,
        canSort: true,
        cell_style: null,
      },
      {
        id: 3,
        name: "Last Name",
        accessor: "lastName",
        enabled: true,
        canSort: true,
        cell_style: null,
      },
    ];

    for (let field of customFields) {
      heads.push({
        id: field.id,
        name: field?.displayName,
        // accessor: "lastName",
        canSort: true,
        enabled: true,
        cell_style: (val) => <div>{checkFields(val, field)}</div>,
      });
    }
    return heads;
  }

  function checkFields(contact, field) {
    if (contact && "customField" in contact && contact?.customField) {
      let fields = JSON.parse(contact.customField);
      while (typeof fields === "string") {
        fields = JSON.parse(fields);
      }

      return fields[field.name];
    } else {
      return "";
    }
  }
  console.log(perPage);
  console.log(count);

  return (
    <div>
      <BasicTable
        data={contacts}
        initheaders={headers}
        rowNumbers
        sort={sort}
        setSort={setSort}
      ></BasicTable>{" "}
      <PaginationContainer
        style={{
          padding: "1em 1em",
          position: "relative",
          maxHeight: "fit-content",
        }}
      >
        <Pagination
          pageNum={pageNumber}
          setPageNum={setPageNumber}
          maxPage={count / perPage < 1 ? 1 : Math.ceil(count / perPage)}
          length={count}
          searchString={""}
          perPage={perPage}
          setPerPage={setPerPage}
          setPageSkip={setPageSkip}
        ></Pagination>
      </PaginationContainer>
    </div>
  );
}
