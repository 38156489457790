import React from "react";
import styles from "./FlexRow.module.scss";

function FlexRow({ children, style, start, gap, className, onClick }) {
  return (
    <div
      className={`${styles.flexrow} ${className}`}
      style={{
        ...{ justifyContent: start ? "flex-start" : "", gap: gap ? gap : "" },
        ...style,
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

export default FlexRow;
