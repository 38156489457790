import React, { useState, useEffect, useRef } from "react";
import styles from "./UserProfile2.module.scss";
import {
  useFetchUser,
  useRemoveUserFromOrg,
  useUpdateUserById,
} from "api/resources/organization/users";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import Button from "components/Button/Button";
import { Label } from "components/layouts/Label/Label";
import { Loading } from "components/Loading/Loading";

import ImageEditor from "components/Image Editor/ImageEditor";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import {
  useChangeUserRole,
  useFetchRolesGql,
} from "api/resources/organization/roles";
import DoYouWantToSave from "./DoYouWantToSave";
import { ChangePassword } from "pages/account/accountDetails/AccountDetails";
import { AddField } from "components/CustomFieldsEditor/CustomFieldsEditor";
import { useFetchCurrOrgsFields } from "api/resources/organization/organization";
import EditSingleCustomField from "components/CustomFieldsEditor/EditSingle";
import salesforce from "assets/images/Salesforce.png";
import LinkNewAccount from "pages/account/LinkNewAccount/LinkNewAccount";
import { deleteLinkedAccount } from "api/resources/contacts/contacts";
import UserActivity from "./UserActivity";
import { SigBuilder } from "pages/organization/SigBuilder/SigBuilder";
import { TabBar } from "components/layouts";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { useMakeDefaultSig } from "api/resources/projects/distributions";

const menuItems = [
  {
    id: 0,
    name: "User Details",
    // to: `/organization/${id}/org-details`, d
    icon: "bi bi-card-text",
  },
  {
    id: 1,
    name: "Email Preferences",
    // to: `/organization/${id}/email-preferences`,
    icon: "bi bi-envelope",
  },
  // {
  //   id: 2,
  //   name: "Dashboards",
  //   // to: `/organization/${id}/dashboards`,
  //   icon: "bi bi-speedometer2",
  // },
  {
    id: 3,
    name: "Activity",
    // to: `/organization/${id}/nlp`,
    icon: "bi bi-lightbulb",
  },
  // {
  //   id: 4,
  //   name: "Permissions",
  //   // to: `/organization/${id}/nlp`,
  //   icon: "bi bi-person-lock",
  // },
];

function UserProfile2({
  userId,
  show,
  setShow,
  popout,
  organizationId,
  roles,
  canEdit,
  account,
}) {
  const getUser = useFetchUser(userId);
  const [editImage, setEditImage] = useState(false);
  const [currRole, setCurrRole] = useState(getRoles());
  const [editPswd, setEditPswd] = useState(false);
  const [linkAccount, setLinkAccount] = useState(false);
  const [changedRole, setChangedRole] = useState(false);
  const [saveWords, setSaveWords] = useState("Save Changes");
  const [activeSig, setActiveSig] = useState();
  const [checkRemove, setCheckRemove] = useState(false);
  const [accountMenu, setAccountMenu] = useState();
  const [active, setActive] = useState(0);

  const [add, setAdd] = useState(false);
  const orgRoles = useFetchRolesGql(organizationId);
  const changeRole = useChangeUserRole();
  const removeUser = useRemoveUserFromOrg();
  const makeDefaultSig = useMakeDefaultSig();

  function getOptions(options) {
    let response = [];
    for (let option of options) {
      if (currRole?.id != option?.id) {
        response.push({ value: option?.id, label: option?.name });
      }
    }
    return response;
  }

  function handleChangeRole() {
    setSaveWords(
      <>
        Saving <Loading width={20} height={20}></Loading>
      </>
    );
    changeRole.mutate(
      {
        orgId: organizationId,
        userId: userId,
        roleId: currRole?.value,
      },
      {
        onSuccess: (data) => {
          setChangedRole(false);
          setSaveWords("Save Changes");
        },
      }
    );
  }

  function getRoles() {
    let role = roles.find((role) => role?.organizationId === organizationId);
    return role
      ? { value: role?.id, label: role?.name }
      : { value: "", label: "" };
  }

  function handleRemoveUser(userId) {
    removeUser.mutate(
      {
        orgId: organizationId,
        userId: userId,
      },
      {
        onSuccess: () => {
          setShow(false);
        },
      }
    );
  }

  function clickOutside(e) {
    if (ref.current && !ref.current?.contains(e.target)) {
      setAccountMenu(undefined);
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  }, []);

  const ref = useRef(null);

  const deleteLink = deleteLinkedAccount();

  function removeLinkedAccount(id) {
    deleteLink.mutate(
      {
        id: id,
      },
      {
        onSuccess: () => {
          getUser.refetch();
        },
      }
    );
  }

  function makeDefault() {
    if (activeSig && activeSig.id) {
      makeDefaultSig.mutate(
        {
          sigId: activeSig.id,
        },
        {
          onSuccess: (data) => {
            getUser.refetch();
          },
          onError: (data) => {
            console.log(data);
          }
        }
      );
    }
  }

  function childrenElements() {
    return (
      <>
        {editPswd && getUser.isSuccess && (
          <ReactModal
            show={editPswd}
            onClose={() => setEditPswd(false)}
            modalStyle={{ borderRadius: "1em" }}
            dark
            // rightStyle
          >
            <ChangePassword
              currUser={getUser.data?.user}
              close={() => setEditPswd(false)}
            />
          </ReactModal>
        )}
        {linkAccount && getUser.isSuccess && (
          <ReactModal
            show={linkAccount}
            onClose={() => setLinkAccount(false)}
            modalStyle={{ borderRadius: "1em" }}
            dark
          >
            <LinkNewAccount
              currUser={getUser.data?.user}
              close={() => setLinkAccount(false)}
            />
          </ReactModal>
        )}
        {checkRemove && getUser.isSuccess && (
          <DoYouWantToSave
            cancelText={"No, do not remove"}
            confirmText={"Yes"}
            prompt={
              "Are you sure you want to remove this user from your organization?"
            }
            navAway={() => setCheckRemove(false)}
            onSave={() => handleRemoveUser(getUser?.data?.user?.id)}
          ></DoYouWantToSave>
        )}
        {getUser.isLoading && <Loading></Loading>}
        {getUser.isSuccess && (
          <>
            <div className={styles.top}>
              <div className={styles.section}>
                <ImageEditor
                  src={getUser.data?.user?.image?.imageURL}
                  canEdit={false}
                  isUser={true}
                  userId={getUser.data?.user?.id}
                  position={
                    getUser.data?.user?.image?.position &&
                    typeof getUser.data?.user?.image?.position === "string"
                      ? JSON.parse(getUser.data?.user?.image?.position)
                      : getUser.data?.user?.image?.position
                  }
                  image={getUser.data?.user?.image}
                  refetch={getUser.refetch}
                  height={50}
                  width={50}
                ></ImageEditor>

                <div className={styles.name}>
                  {getUser.data?.user?.firstName} {getUser.data?.user?.lastName}
                </div>
              </div>
            </div>
            {canEdit && !account && (
              <div
                className={styles.section}
                style={{ position: "absolute", bottom: "1rem", right: "1rem" }}
              >
                <Button red shadow onClick={() => setCheckRemove(true)}>
                  Remove User
                </Button>
              </div>
            )}
            <div className={styles.page}>
              <div className={styles.menu}>
                <TabBar
                  tabBarItems={menuItems}
                  active={active}
                  updateActive={setActive}
                />
              </div>
              {active === 0 && (
                <FlexRow
                  style={{ justifyContent: "center", alignItems: "flex-start" }}
                >
                  <div className={styles.pageSection}>
                    <div className={styles.editImage}>
                      <Label
                        style={{
                          fontWeight: "700",
                          padding: "0em",
                          margin: "0em",
                          letterSpacing: "0",
                        }}
                      >
                        Profile Image
                      </Label>

                      <ImageEditor
                        src={getUser.data?.user?.image?.imageURL}
                        canEdit={canEdit}
                        isUser={true}
                        userId={getUser.data?.user?.id}
                        position={
                          getUser.data?.user?.image?.position &&
                          typeof getUser.data?.user?.image?.position ===
                            "string"
                            ? JSON.parse(getUser.data?.user?.image?.position)
                            : getUser.data?.user?.image?.position
                        }
                        image={getUser.data?.user?.image}
                        refetch={getUser.refetch}
                        setShow={setEditImage}
                        show={editImage}
                        height={200}
                        width={200}
                      ></ImageEditor>

                      <div className={styles.link}>
                        {" "}
                        <i className="bi-image"></i>{" "}
                        <a onClick={() => setEditImage(true)}>
                          Edit Profile Image
                        </a>
                      </div>
                    </div>
                    <div className={styles.editImage}>
                      <Label
                        style={{
                          fontWeight: "700",
                          padding: "0em",
                          margin: "0em",
                          letterSpacing: "0",
                        }}
                      >
                        User Role
                      </Label>
                      <CombinedInput
                        label="Role"
                        placeholder={"Role"}
                        value={currRole}
                        icon={"bi-chevron-down"}
                        select
                        options={
                          orgRoles.isSuccess
                            ? getOptions(orgRoles.data.rolesByOrgId)
                            : []
                        }
                        disable={account}
                        optionHelperText="Change"
                        onChange={(val) => {
                          setCurrRole(val);
                          setChangedRole(true);
                        }}
                        style={{ backgroundColor: "white" }}
                        shadow
                      ></CombinedInput>
                      {changedRole && (
                        <div style={{ display: "flex", gap: ".5em" }}>
                          <Button
                            shadow
                            seafoam
                            onClick={() => {
                              setCurrRole(getRoles());
                              setChangedRole(false);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button shadow onClick={() => handleChangeRole()}>
                            {saveWords}
                          </Button>
                        </div>
                      )}
                    </div>

                    <div className={styles.editImage} ref={ref}>
                      <Label
                        style={{
                          fontWeight: "700",
                          padding: "0em",
                          margin: "0em",
                          letterSpacing: "0",
                        }}
                      >
                        Connected Accounts
                      </Label>
                      {getUser.data?.user.linkedAccounts.map((account) => (
                        <div key={account?.id} className={styles.account}>
                          {account.accountType === "Salesforce" && (
                            <CombinedInput
                              select
                              icon={"bi-three-dots"}
                              label={"Account"}
                              value={{
                                value: "salesforece",
                                label: (
                                  <span
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: ".5em",
                                    }}
                                  >
                                    <img src={salesforce} height={30}></img>
                                    Salesforce
                                  </span>
                                ),
                              }}
                              onIconClick={() => setAccountMenu(account?.id)}
                              style={{ backgroundColor: "white" }}
                              shadow
                            />
                          )}
                          {accountMenu === account?.id && (
                            <div className={styles.settingsBox}>
                              <div
                                className={styles.menuItem}
                                onClick={() => removeLinkedAccount(account?.id)}
                              >
                                <i className="bi-file-minus"> </i>Unlink Account
                              </div>
                            </div>
                          )}
                        </div>
                      ))}

                      <div className={styles.link}>
                        <i className="bi-plus"></i>
                        <a onClick={() => setLinkAccount(true)}>
                          Link New Account
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className={styles.pageSection}>
                    <div className={styles.editImage}>
                      <Label
                        style={{
                          fontWeight: "700",
                          padding: "0em",
                          margin: "0em",
                          letterSpacing: "0",
                        }}
                      >
                        User Details
                      </Label>

                      <UserFields
                        user={getUser.data?.user}
                        account={account}
                        refetch={getUser.refetch}
                        setEditPswd={setEditPswd}
                        setAdd={setAdd}
                        add={add}
                      ></UserFields>
                    </div>
                  </div>
                </FlexRow>
              )}

              {active === 1 && (
                <div
                  className={styles.pageSection}
                  style={{ width: "100%", maxWidth: "664px" }}
                >
                  <div
                    className={styles.editImage}
                    style={{ width: "100%", maxWidth: "664px" }}
                  >
                    <Label
                      style={{
                        fontWeight: "700",
                        padding: "0em",
                        margin: "0em",
                        letterSpacing: "0",
                      }}
                    >
                      User Email Signatures
                    </Label>
                    <UserSigs
                      user={getUser.data?.user}
                      setActiveSig={setActiveSig}
                    ></UserSigs>
                  </div>
                </div>
              )}
              {active === 3 && (
                <UserActivity
                  user={getUser.data?.user}
                  orgId={organizationId}
                ></UserActivity>
              )}
            </div>
          </>
        )}
      </>
    );
  }

  return (
    <>
      {popout && (
        <ReactModal
          show={show}
          onClose={() => setShow(false)}
          modalStyle={{
            borderRadius: "1em",
            height: "fit-content",
            width: "95%",
            height: "100%",
            maxWidth: "1200px",
            backgroundColor: "#f9fbfc",
          }}
          dark
        >
          {childrenElements()}
        </ReactModal>
      )}
      {activeSig && (
        <SigBuilder
          orig={activeSig}
          onClose={() => setActiveSig(null)}
          refetch={getUser.refetch}
          isDefault={getUser.data?.user?.activeDistConfigId === activeSig?.id}
          makeDefault={makeDefault}
          userId={userId}
        ></SigBuilder>
      )}
      {!popout && childrenElements()}
    </>
  );
}

export default UserProfile2;

function UserFields({ user, account, refetch, setEditPswd, setAdd, add }) {
  // const orgFields = useFetchCurrOrgsFields();
  const updateUser = useUpdateUserById();
  const [first, setFirst] = useState(user?.firstName);
  const [last, setLast] = useState(user?.lastName);
  const [email, setEmail] = useState(user?.email);
  const [createNew, setCreateNew] = useState(false);
  const [saveWords, setSaveWords] = useState("Save Changes");
  const orgFields = useFetchCurrOrgsFields();

  const [change, setChange] = useState(false);

  const initFields = () => {
    if (user?.customField) {
      return JSON.parse(user.customField);
    }
    return {};
  };

  const [userFields, setUserFields] = useState(initFields());

  function saveUserFields() {
    setSaveWords(
      <>
        Saving <Loading width={20} height={20}></Loading>
      </>
    );
    if (user) {
      let finalFields = {};
      let fields = Object.keys(userFields);
      for (let field of fields) {
        if (userFields[field] && userFields[field] != "") {
          finalFields[field] = userFields[field];
        }
      }

      updateUser.mutate(
        {
          data: {
            customField: JSON.stringify(finalFields),
            firstName: first,
            lastName: last,
            email: email,
          },
          id: user.id,
        },
        {
          onSuccess: (data) => {
            setChange(false);
            refetch();
            setSaveWords("Save Changes");
            setUserFields(finalFields);
          },
        }
      );
    }
  }

  // function changeFieldSimple(name, value) {
  //   let copy = { ...userFields };
  //   if (user) {
  //     copy[name] = value;
  //   }
  //   setUserFields(copy);
  // }
  function changeField(name, options) {
    let copy = { ...userFields };
    if (user) {
      let field = orgFields.data?.getCurrOrgFields.find((f) => f.name === name);
      if (field?.delimiter) {
        copy[name] = "";
        for (let option of options) {
          if (copy[name]) {
            copy[name] += field.delimiter;
          }
          copy[name] += option.value;
        }
      } else {
        copy[name] = options.value;
      }
    }
    setUserFields(copy);
  }
  function getLeft() {
    let left = [];
    let fields = orgFields.data?.getCurrOrgFields;
    if (fields) {
      for (let one of fields) {
        if (!(one.name in userFields)) {
          let caps =
            one.displayName.charAt(0).toUpperCase() + one.displayName.slice(1);
          left.push({ name: one.name, displayName: caps });
        }
      }
    }
    return left;
  }

  function addField(field) {
    let copy = { ...userFields };
    copy[field] = "";
    setUserFields(copy);
    setAdd(false);
    setChange(true);
  }

  function handleNewField(field) {
    if (field?.name) {
      addField(field.name);
    }
    setCreateNew(false);
    if (refetch) {
      refetch();
    }
  }

  return (
    <>
      {orgFields.isSuccess && add && (
        <AddField
          onClickOut={() => setAdd(false)}
          onAdd={addField}
          options={getLeft()}
          onCreateNew={() => {
            setCreateNew(true);
            // setChange(true);
            setAdd(false);
          }}
        />
      )}
      {createNew && (
        <EditSingleCustomField
          brandNew
          onClose={() => setCreateNew(false)}
          onSaved={handleNewField}
        />
      )}
      <CombinedInput
        label="First Name"
        placeholder={"First Name"}
        value={first}
        onChange={(val) => {
          setFirst(val);
          setChange(true);
        }}
        style={{ backgroundColor: "white" }}
        shadow
      ></CombinedInput>
      <CombinedInput
        label="Last Name"
        placeholder={"Last Name"}
        value={last}
        onChange={(val) => {
          setLast(val);
          setChange(true);
        }}
        style={{ backgroundColor: "white" }}
        shadow
      ></CombinedInput>
      <CombinedInput
        label="Email"
        placeholder={"Email"}
        value={email}
        onChange={(val) => {
          setEmail(val);
          setChange(true);
        }}
        style={{ backgroundColor: "white" }}
        shadow
      ></CombinedInput>
      {account && (
        <CombinedInput
          label="Password"
          placeholder={"Password"}
          value={"*************"}
          icon="bi-pencil-square"
          onIconClick={() => setEditPswd(true)}
          disableInputOnly
          style={{ backgroundColor: "white" }}
          shadow
        ></CombinedInput>
      )}
      {/* {Object.keys(userFields).map((field) => (
        <CombinedInput
          label={field}
          placeholder={field}
          value={userFields[field]}
          onChange={(val) => {
            changeField(field, val);
            setChange(true);
          }}
        ></CombinedInput>

      ))} */}
      {orgFields.isSuccess &&
        Object.keys(userFields).map((key, i) => {
          let field = orgFields.data?.getCurrOrgFields.find(
            (f) => f.name === key
          );
          if (!field) {
            field = { name: key, properties: [], displayName: key };
          }
          let chosen = null;

          let them = userFields[key];
          let name = key;
          let caps = key;
          let multiple = false;

          name = field?.displayName ? field.displayName : field.name;
          caps = name;

          if (them) {
            if (user && field?.delimiter) {
              // debugger;
              // them = them?.split(field?.delimiter ? field.delimiter : undefined);
              chosen = them?.map((prop) => {
                return {
                  value: prop,
                  label: prop,
                };
              });
            } else if (user) {
              chosen = {
                value: them,
                label: them,
              };
            } else {
              chosen = "";
            }
          }
          multiple = user ? (field?.delimiter ? true : false) : true;

          return (
            <React.Fragment key={i}>
              {(userFields[key] || userFields[key] === "") && (
                <div className={styles.editImage}>
                  <CombinedInput
                    label={caps}
                    value={chosen}
                    onChange={(val) => {
                      changeField(key, val);
                      setChange(true);
                    }}
                    options={
                      field
                        ? field.properties.map((prop) => {
                            return {
                              value: prop,
                              label: prop,
                            };
                          })
                        : []
                    }
                    selectMultiple={multiple}
                    select={!multiple}
                    search
                    style={{ backgroundColor: "white" }}
                    shadow
                  ></CombinedInput>
                </div>
              )}
            </React.Fragment>
          );
        })}
      {change && (
        <div style={{ display: "flex", gap: ".5em" }}>
          <Button
            shadow
            seafoam
            onClick={() => {
              setUserFields(initFields());
              setFirst(user?.firstName);
              setLast(user?.lastName);
              setEmail(user?.email);
              setChange(false);
            }}
          >
            Cancel
          </Button>
          <Button shadow onClick={() => saveUserFields()}>
            {saveWords}
          </Button>
        </div>
      )}
      <div className={styles.link}>
        <i className="bi-plus"></i>
        <a onClick={() => setAdd(true)}>Add Field</a>
      </div>
    </>
  );
}

function UserSigs({ user, setActiveSig }) {
  return (
    <>
      {user?.distConfig &&
        user?.distConfig.map((sig, i) => (
          <CombinedInput
            key={i}
            label={sig?.aliasName}
            placeholder={"signature"}
            value={sig?.fromAddress}
            icon={"bi-pencil-square"}
            disableInputOnly
            onIconClick={() => setActiveSig(sig)}
            style={{ backgroundColor: "white" }}
            shadow
          ></CombinedInput>
        ))}
      <Button
        className={styles.link}
        onClick={() => {
          setActiveSig({
            signature: "",
            aliasName: "",
            fromAddress: "",
            type: "signature",
            userId: user?.id,
          });
        }}
        shadow
      >
        <i className="bi-plus"></i>
        Add Signature
      </Button>
    </>
  );
}
{
  /* <div className={styles.settingSection}>
  <div className={styles.headerHolder2}>
    <div style={{ maxWidth: "450px" }}>
      <div className={styles.header2}>Email Signatures</div>
      <div className={styles.description2}>
        This users email signatures. A user can have an infinite amount of
        signatures, but can only have one active at a time.
      </div>
    </div>
    <Button
      shadow
      onClick={() => {
        setActiveSig({
          signature: "",
          aliasName: "",
          fromAddress: "",
          type: "signature",
        });
        setShowSig(true);
      }}
    >
      Create Signature
    </Button>
  </div>

  <UserSignatures
    userId={user.id}
    show={showSig}
    setShow={setShowSig}
    sigs={user.distConfig}
    active={activeSig}
    setActive={setActiveSig}
    activeDistConfigId={user.activeDistConfigId}
  ></UserSignatures>
</div>; */
}
