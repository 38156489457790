import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import {
  SelectField,
  TextFieldSimple as TextField,
  TextFieldSimple,
} from "components/inputs";
import React, { useState } from "react";
import styles from "../SettingsAccordion.module.scss";
import { Label } from "components/layouts/Label/Label";
import { DoughnutType, PieType } from "../../QuestionChart";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import { splitting } from "../SettingsAccordion";
import { SegLabelEditor } from "./Axes";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import NumberInputWithSlider from "components/NumberInputWithSlider/NumberInputWithSlider";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import { fonts, iconshapes } from "assets/functions/Variables";
import ColorPickerContainer from "components/inputs/input_fields/ColorPicker/ColorPickerContainer";

//custom accordian
export const LegendSettings = ({
  viz,
  title,
  changeSettingsField,
  visible,
  setVisible,
  labels,
}) => {
  // const [visibility, setVisibility] = useState(false);

  // const toggleVisibility = () => {
  //   setVisibility((v) => !v);
  // };

  const positionOptions = [
    { label: "Top", value: "top" },
    {
      label: "Left",
      value: "left",
    },
    {
      label: "Bottom",
      value: "bottom",
    },
    {
      label: "Right",
      value: "right",
    },
  ];

  function capitalize(title) {
    return title.charAt(0).toUpperCase() + title.slice(1);
  }

  function generateLegendTitle() {
    if (viz.type === DoughnutType || viz.type === PieType) {
      if (viz.pivotString && viz.pivotString !== "nps") {
        return capitalize(viz.pivotString);
      } else {
        return "Choices";
      }
    }

    if (viz.designSettings.split) {
      return capitalize(viz.designSettings.split);
    }
    return "Choices";
  }

  function handleTitleToggle(val) {
    if (val) {
      let title = generateLegendTitle();
      changeSettingsField("legendTitle", title);
    }
    changeSettingsField("hasLegendTitle", val);
  }

  return (
    <>
      <div
        key={"legend"}
        className={`${styles.header} ${visible ? styles.headervisible : ""}`}
        onClick={!visible ? setVisible : undefined}
      >
        {/* {title} */}
        <FlexRow onClick={visible ? setVisible : undefined} style={{ cursor: "pointer" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
            {title} <i style={{ fontSize: "1.2em" }} className="bi bi-map"></i>
          </div>

          <span className={styles.accordionicon}>
            <i className="bi bi-caret-left-fill"></i>
          </span>
        </FlexRow>

        {visible && (
          <>
            <div className={styles.setting2} style={{ paddingLeft: "5px" }}>
              <ToggleSwitch
                startChecked={viz.designSettings.hasLegend}
                handleCheck={(val) => changeSettingsField("hasLegend", val)}
              ></ToggleSwitch>
              <Label
                style={{
                  width: "fit-content",
                  fontSize: ".9em",
                }}
              >
                Legend
              </Label>
            </div>
            {viz.designSettings.hasLegend && (
              <>
                {!splitting(viz) && (
                  <div
                    style={{
                      // display: "flex",
                      // flexDirection: "row",
                      // justifyContent: "center",
                      paddingLeft: "10px",
                      margin: "5px 0px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      <div className={styles.setting2}>
                        <Checkbox
                          checked={viz.designSettings.legendLabel}
                          onChange={(e) =>
                            changeSettingsField("legendLabel", e.target.checked)
                          }
                        />
                        <Label
                          style={{
                            width: "fit-content",
                            fontSize: ".9em",
                            paddingLeft: 0,
                          }}
                        >
                          Label
                        </Label>
                      </div>
                      <div
                        className={styles.setting2}
                        // style={{ width: "fit-content" }}
                      >
                        <Checkbox
                          checked={viz.designSettings.legendValue}
                          onChange={(e) =>
                            changeSettingsField("legendValue", e.target.checked)
                          }
                        />
                        <Label
                          style={{
                            width: "fit-content",
                            fontSize: ".9em",
                            paddingLeft: 0,
                          }}
                        >
                          {viz.designSettings.byPercent ? "Value (%)" : "Value"}
                        </Label>
                      </div>
                      {!viz.designSettings.byPercent && (
                        <div
                          className={styles.setting2}
                          // style={{ width: "fit-content" }}
                        >
                          <Checkbox
                            checked={viz.designSettings.legendPercent}
                            onChange={(e) =>
                              changeSettingsField(
                                "legendPercent",
                                e.target.checked
                              )
                            }
                          />
                          <Label
                            style={{
                              width: "fit-content",
                              fontSize: ".9em",
                              paddingLeft: 0,
                            }}
                          >
                            %
                          </Label>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {/* {labels && (
                  <div
                    className={styles.setting}
                    style={{ paddingLeft: "5px", alignItems: "flex-start" }}
                  >
                    <Label
                      style={{
                        fontSize: ".9em",
                        paddingLeft: "0px",
                        paddingBottom: "5px",
                      }}
                    >
                      Labels
                    </Label>

                    <SegLabelEditor
                      viz={viz}
                      changeSettingsField={changeSettingsField}
                      labels={labels}
                    />
                  </div>
                )} */}

                <CombinedInput
                  select
                  label="Legend Position"
                  value={positionOptions.find(
                    (opt) => opt.value === viz.designSettings.legendPosition
                  )}
                  onChange={(option) =>
                    changeSettingsField("legendPosition", option.value)
                  }
                  options={positionOptions}
                  icon={"bi-chevron-down"}
                />
                {/* <div
                  className={styles.setting2}
                  style={{ paddingLeft: "5px", gap: "10px" }}
                >
                  <div>
                    <SelectField
                      value={positionOptions.find(
                        (opt) => opt.value === viz.designSettings.legendPosition
                      )}
                      onChange={(option) =>
                        changeSettingsField("legendPosition", option.value)
                      }
                      options={positionOptions}
                    ></SelectField>
                  </div>
                  <Label
                    style={{
                      fontWeight: "400",
                      width: "fit-content",
                      fontSize: ".8em",
                      whiteSpace: "nowrap",
                      padding: "0em",
                      margin: "0em",
                    }}
                  >
                    Position
                  </Label>
                </div> */}

                {/* <div className={styles.setting}>
                  <Label
                    italics
                    style={{
                      fontWeight: "500",
                      width: "fit-content",
                      fontSize: ".9em",
                    }}
                  >
                    Use Points
                  </Label>
                  <ToggleSwitch
                    startChecked={viz.designSettings.legendPointStyle}
                    handleCheck={(val) =>
                      changeSettingsField("legendPointStyle", val)
                    }
                  ></ToggleSwitch>
                </div> */}
                <ColorPickerContainer
                  defaultColor={
                    viz?.designSettings?.legendFontColor
                      ? viz?.designSettings?.legendFontColor
                      : "#000000"
                  }
                  onChange={(val) =>
                    changeSettingsField("legendFontColor", val)
                  }
                  textColors
                  title="Legend Font Color"
                  style={{
                    backgroundColor: "transparent",
                    padding: "0",
                    margin: "0",
                  }}
                ></ColorPickerContainer>
                <div className={styles.setting2} style={{ paddingLeft: "5px" }}>
                  <ToggleSwitch
                    startChecked={viz?.designSettings?.legendWidth}
                    handleCheck={(val) =>
                      changeSettingsField(
                        "legendWidth",
                        val ? "180px" : false
                      )
                    }
                  ></ToggleSwitch>
                  <Label
                    // italics
                    style={{
                      fontSize: ".9em",
                      width: "fit-content",
                    }}
                  >
                    Manual Width
                  </Label>
                </div>

                {viz?.designSettings?.legendWidth && (
                  <NumberInputWithSlider
                    val={viz?.designSettings?.legendWidth}
                    onChange={(val) => changeSettingsField("legendWidth", val)}
                    min={50}
                    max={1000}
                    step={1}
                    title="Legend Width"
                  />
                )}

                <NumberInputWithSlider
                  val={viz?.designSettings?.legendSpacing}
                  onChange={(val) => changeSettingsField("legendSpacing", val)}
                  min={3}
                  max={40}
                  step={1}
                  title="Spacing"
                />

                <NumberInputWithSlider
                  val={viz?.designSettings?.legendTextFontSize}
                  onChange={(val) =>
                    changeSettingsField("legendTextFontSize", val)
                  }
                  min={3}
                  max={40}
                  step={1}
                  title="Text Font Size"
                />
                <CombinedInput
                  select
                  label="Text Font"
                  options={fonts}
                  value={
                    viz?.designSettings?.legendTextFont
                      ? fonts.find(
                          (v) => v.value === viz?.designSettings?.legendTextFont
                        )
                      : fonts.find((v) => v.value === "Poppins, sans-serif")
                  }
                  onChange={(v) =>
                    changeSettingsField("legendTextFont", v.value)
                  }
                  icon={"bi-chevron-down"}
                />
                <CombinedInput
                  select
                  label="Point Shape"
                  options={iconshapes}
                  value={
                    viz?.designSettings?.legendTextShape
                      ? iconshapes.find(
                          (v) =>
                            v.value === viz?.designSettings?.legendTextShape
                        )
                      : iconshapes.find((v) => v.value === "bi-circle-fill")
                  }
                  onChange={(v) =>
                    changeSettingsField("legendTextShape", v.value)
                  }
                  icon={"bi-chevron-down"}
                />
                <NumberInputWithSlider
                  val={viz?.designSettings?.legendTextShapeSize}
                  onChange={(val) =>
                    changeSettingsField("legendTextShapeSize", val)
                  }
                  min={3}
                  max={40}
                  step={1}
                  title="Point Size"
                />
                <div className={styles.setting2} style={{ paddingLeft: "5px" }}>
                  <ToggleSwitch
                    startChecked={viz.designSettings.hasLegendTitle}
                    handleCheck={handleTitleToggle}
                  ></ToggleSwitch>
                  <Label
                    // italics
                    style={{
                      fontSize: ".9em",
                      width: "fit-content",
                    }}
                  >
                    Legend Title
                  </Label>
                </div>
                {viz.designSettings.hasLegendTitle && (
                  <>
                    <TextFieldSimple
                      value={viz.designSettings.legendTitle}
                      onChange={(val) =>
                        changeSettingsField("legendTitle", val)
                      }
                      style={{ height: "30px", fontSize: "1em" }}
                      label="Title Text"
                    ></TextFieldSimple>
                    <NumberInputWithSlider
                      val={viz.designSettings.legendFontSize}
                      onChange={(val) =>
                        changeSettingsField("legendFontSize", val)
                      }
                      min={3}
                      max={40}
                      step={1}
                      title="Title Font Size"
                    />
                    <ColorPickerContainer
                      defaultColor={
                        viz?.designSettings?.legendTitleColor
                          ? viz?.designSettings?.legendTitleColor
                          : "#000000"
                      }
                      onChange={(val) =>
                        changeSettingsField("legendTitleColor", val)
                      }
                      textColors
                      title="Title Font Color"
                      style={{
                        backgroundColor: "transparent",
                        padding: "0",
                        margin: "0",
                      }}
                    ></ColorPickerContainer>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
