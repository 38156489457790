// External
import React, { useEffect, useRef, useState } from "react";

// Internal
import styles from "./SelectFieldCustom.module.scss";
import { Label } from "components/layouts/Label/Label";
import Icon from "components/Icon/Icon";

export const SelectFieldCustom = ({
  options,
  value,
  icon,
  values,
  selectMultiple,
  searchable,
  onChange,
  newOptionText,
  onNewOptionClick,
  placeholder,
  label,
  disable,
  containerStyle,
  itemStyle,
  onItemClick,
  searchString,
  setSearchString,
  className,
  style,
  dropdownStyle,
}) => {
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState(false);
  const [chosenValues, setChosenValues] = useState(values ? values : []);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShow(false);
      if (searchable) {
        setSearch(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (values) {
      setChosenValues(values);
    }
  }, [values]);

  const handleChange = (option) => {
    if (!selectMultiple) {
      if (option.value !== value.value) {
        //Checking value.value, but displaying just 'value' below
        if (onChange) {
          onChange(option);
        }
        if (searchable) {
          setSearch(false);
        }
      }
    } else {
      setSearch(false);
      let newList = [...chosenValues];
      newList.push(option);
      setChosenValues(newList);
      if (onChange) {
        onChange(option);
      }
    }
    setShow(false);
  };

  function searchOptions(userInput) {
    //this will be replaced with a search to the database  i.e. searchFunction
    setSearchString(userInput);
  }

  function handleRemoveOption(item, e) {
    //remove chosen option
    e.stopPropagation();
    if (onChange) {
      onChange(item, true);
    }
    let newValues = [...chosenValues];
    let index = newValues.indexOf(item);
    newValues.splice(index, 1);
    setChosenValues(newValues);
  }

  function handleOnSearchClick(e) {
    if (!search || !show) {
      setShow(true);
      setSearch(true);
    }
  }

  function commaList(array) {
    return array.map((item) => (
      <div key={item.id} className={styles.chosenOption} style={itemStyle}>
        <span onClick={onItemClick ? () => onItemClick(item) : undefined}>
          {item?.name ? item?.name : item?.label}{" "}
        </span>
        <i className="bi bi-x" onClick={(e) => handleRemoveOption(item, e)}></i>
      </div>
    ));
  }

  function getHeight(num) {
    if (num <= 6) {
      return num * 40 + "px";
    } else {
      return "";
    }
  }

  return (
    <div className={styles.selectContainer} ref={ref}>
      {label && (
        <Label style={{ textTransform: "None", padding: ".2rem 0" }}>
          {label}
        </Label>
      )}
      {searchable && (
        <div
          className={`${styles.searchContainer} ${disable && styles.disabled}`}
          style={containerStyle}
          onClick={!search && selectMultiple ? handleOnSearchClick : ""}
        >
          {!search && selectMultiple && (
            <div
              className={styles.activeSearchOption}
              // onClick={chosenValues.length > 0 ? null : handleOnSearchClick}
            >
              {icon}
              {chosenValues.length > 0 ? (
                commaList(chosenValues)
              ) : (
                <div className={styles.placeholder}>{placeholder}</div>
              )}
            </div>
          )}
          {!search && !selectMultiple && (
            <div className={styles.activeSearchOption}>
              {icon}
              {value ? value : placeholder}
            </div>
          )}
          {search && (
            <input
              // ref={(input) => { searchInput = input; }}
              type="text"
              onChange={(e) => searchOptions(e.target.value)}
              className={styles.searchInput}
              placeholder={placeholder}
              onClick={() => setSearch(true)}
              autoFocus
              disabled={disable}
              value={searchString}
              style={style}
            ></input>
          )}
          <div className={styles.iconContainer} onClick={handleOnSearchClick}>
            <i className="bi bi-plus"></i>
            <i
              className="bi bi-chevron-down"
              onClick={() => {
                setShow(!show);
                setSearch(!search);
              }}
            ></i>
          </div>
        </div>
      )}
      {!searchable && (
        <div
          className={`${styles.select} ${
            disable && styles.disabled
          } ${className}`}
          style={containerStyle}
          onClick={() => setShow(!show)}
        >
          {!selectMultiple && (
            <div className={styles.activeOption}>
              {icon}
              {value && value?.name && (
                <>{options.find((o) => o.value === value.value)?.name}</>
              )}
              {value && value?.label && (
                <>{options.find((o) => o.value === value.value)?.label}</>
              )}
              {value && !value?.label && !value?.name && <>{value}</>}
              {!value && placeholder}
            </div>
          )}
          {selectMultiple && (
            <div className={styles.activeOption}>
              {icon}
              {chosenValues
                ? commaList(chosenValues)
                : options.find((o) => o.value === value).name}
            </div>
          )}

          <i className="bi bi-chevron-down"></i>
        </div>
      )}

      {show && !disable && (
        <div
          className={!searchable ? styles.dropdown : styles.searchableDropdown}
          style={{ height: getHeight(options?.length), ...dropdownStyle }}
        >
          {newOptionText && (
            <div className={styles.newOptionText} onClick={onNewOptionClick}>
              {newOptionText}...
            </div>
            // <div className={styles.newOptionText}><Button gray>{newOptionText}</Button></div>
            // <div className={styles.newOptionText}><Button white>{newOptionText}</Button></div>
          )}

          {options.map((option, index) => (
            <div key={index}>
              {"value" in option &&
                !chosenValues.find((v) => v.value === option.value) && (
                  <div
                    className={`${styles.option}`}
                    onClick={() => handleChange(option)}
                  >
                    {option.name
                      ? option.name
                      : option.label
                      ? option.label
                      : option.value
                      ? option.value
                      : ""}
                    {(value === option || value === option.value) && (
                      <Icon
                        blue
                        iconName={"check"}
                        style={{ fontSize: "1.3rem" }}
                      ></Icon>
                    )}
                  </div>
                )}
              {!("value" in option) &&
                !chosenValues.find((v) => v.id === option.id) && (
                  <div
                    className={`${
                      value === option ? styles.optionActive : styles.option
                    }`}
                    onClick={() => handleChange(option)}
                  >
                    {option.name
                      ? option.name
                      : option.label
                      ? option.label
                      : option.value
                      ? option.value
                      : ""}
                  </div>
                )}
            </div>
          ))}
          {options.length == 0 && (
            <div className={styles.option}>No Results...</div>
          )}
        </div>
      )}
    </div>
  );
};
