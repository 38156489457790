/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2024 Reaction Data Inc. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: brodyspencer

*/
import React, { useEffect, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styles from "./ChartContainer.module.scss";
import Visualization from "../Charts/Visualization";

export function ChartContainer({
  chart,
  id,
  width,
  data,
  setData,
  setUpDataCounter,
  deleteFunction,
  canEdit,
  canMove,
  custom_fields,
  externalFilter,
  refetch,
  dragOverlay,
  showSpace,
  onPullWidth,
  onDonePulling,
  setUpWidths,
  preview,
  update,
  height,
  onCopy,
  onCopyToPage,
  pages,
  opening,
  newSpotUp,
  newSpotDown,
  deleting,
  active,
  setCurrViz,
  editing,
  setEditing,
  setVisibleItems,
  visibleItems,
  setLabels,
  showDrill,
  setShowDrill
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: id });

  // if (transition) {
  //   console.log(transform);
  // }

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: "transform .5s ease",
    opacity: dragOverlay ? 0.5 : 1,
    border: isDragging ? "2px solid rgb(21, 188, 199, .2)" : undefined,
    // backgroundColor: isDragging ? '#A4C6D0' : undefined,
    // width: !vizInd ? "100px" : vizInd == 1 ? "105px" : vizInd == 2 ? "95px" : undefined,
  };

  const [initialX, setInitialX] = useState(0);
  const [pulling, setPulling] = useState(false);

  const onMouseDown = (e) => {
    setPulling(true);
    setInitialX(e.clientX);
    setUpWidths();
  };

  const onPull = (e) => {
    if (!pulling) return;

    let deltaX = e.clientX - initialX;
    // setInitialX(e.clientX);
    onPullWidth(deltaX, () => {
      setInitialX(e.clientX);
    });
  };

  function donePulling() {
    if (!pulling) return;

    setPulling(false);
    setInitialX(0);
    onDonePulling();
  }

  const [tails, setCoin] = useState(Math.random() < 1 / 3);
  const [reset, setReset] = useState(false);
  useEffect(() => {
    if (active) {
      setReset(true);
    }
  }, [active]);

  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  return (
    <>
      <div
        className={`${styles.chartContainer} ${
          newSpotDown ? styles.newSpotDown : ""
        } `}
        ref={setNodeRef}
        style={{ ...style, width: width }}
        {...attributes}
        id={id + "Container"}
        onClick={(e) => {
          e.stopPropagation();
          if (editing && !active) {
            setCurrViz(chart);
            setVisibleItems(null);
          }
        }}
      >
        {chart && !reset && (
          <Visualization
            viz={chart}
            data={data}
            setData={setData}
            setUpDataCounter={setUpDataCounter}
            canDelete={canEdit}
            canSave={canEdit}
            canEdit={canEdit}
            onCopy={onCopy}
            onCopyToPage={onCopyToPage}
            deleteFunction={(id) => deleteFunction(id)}
            custom_fields={custom_fields}
            externalFilter={externalFilter}
            refetch={refetch}
            preview={preview}
            // thumbnail={preview}
            update={update}
            height={height}
            width={width}
            isDragging={isDragging}
            pages={pages}
            idAddOn={dragOverlay ? "overlay" : undefined}
            active={active}
            setCurrViz={setCurrViz}
            editing={editing}
            setEditing={setEditing}
            setVisibleItems={setVisibleItems}
            visibleItems={visibleItems}
            setLabelInfo={active ? setLabels : undefined}
            showDrill={showDrill}
            setShowDrill={setShowDrill}
          />
        )}
        {canMove && window.outerWidth > 900 && editing && (
          <div
            className={styles.grip}
            {...listeners}
            // style={{ opacity: active ? "1" : ""}}
          >
            {" "}
            <i className="bi bi-grip-horizontal"></i>
          </div>
        )}

        {opening && (
          <>
            {tails ? (
              <>
                <div
                  className={`${styles.shutter} ${styles.leftShutter}`}
                ></div>
                <div
                  className={`${styles.shutter} ${styles.rightShutter}`}
                ></div>
              </>
            ) : (
              <>
                <div className={`${styles.window} ${styles.top}`}></div>
                <div className={`${styles.window} ${styles.bottom}`}></div>
              </>
            )}
          </>
        )}
        {newSpotUp && (
          <div className={styles.newSpotUp}>
            <div className={styles.hood}>
              <div className={styles.topOfHood}></div>
            </div>
          </div>
        )}
        {deleting && (
          <>
            {tails ? (
              <>
                <div
                  className={`${styles.curtains} ${styles.leftCurtain}`}
                ></div>
                <div
                  className={`${styles.curtains} ${styles.rightCurtain}`}
                ></div>
              </>
            ) : (
              <>
                <div className={`${styles.blinds} ${styles.topBlind}`}></div>
                <div className={`${styles.blinds} ${styles.bottomBlind}`}></div>
              </>
            )}
          </>
        )}
      </div>

      {showSpace && (
        <>
          <div className={styles.spacer}>
            <div
              className={`${!editing && styles.dragBarEmpty} ${
                editing && styles.dragBar
              }`}
              onMouseDown={onMouseDown}
              onMouseUp={donePulling}
              onMouseMove={onPull}
              onMouseLeave={donePulling}
            >
              <div className={styles.indicator}>
                <i className="bi bi-chevron-bar-expand"></i>
              </div>
              {pulling && (
                <>
                  <div className={`${styles.pullPadding} ${styles.left}`}></div>
                  <div
                    className={`${styles.pullPadding} ${styles.right}`}
                  ></div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export function EmptyRow({ id, top }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    width: "100%",
    height: top ? "30px" : "400px",
  };

  return (
    <div
      className={styles.emptyRow}
      style={style}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    ></div>
  );
}
