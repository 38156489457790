import { TextFieldSimple } from "components/inputs";
import styles from "./TextSettings.module.scss";
import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";
import { Label } from "components/layouts/Label/Label";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { useCSVReader } from "react-papaparse";
import { useImportBucketAnswers } from "api/resources/projects/answers";
import { shortId } from "components/tables/EditableTable/utils";
import { useState } from "react";
import Button from "components/Button/Button";
import { Loading } from "components/Loading/Loading";
import NumberInputWithSlider from "components/NumberInputWithSlider/NumberInputWithSlider";

export function TextSettings({
  question,
  saveQuestion,
  dependents,
  setUpLogicResolver,
}) {
  function handlePlaceholderChange(placeholder) {
    // let newQuestion = { ...question };
    question.textQuestion.placeholder = placeholder;
    saveQuestion(question);
  }

  function changeCharLimit(limit) {
    if (isNaN(limit)) {
      return;
    }

    if (dependents) {
      for (let dependent of dependents.dependencies) {
        if (dependent?.keepLimitAbove > limit) {
          setUpLogicResolver("lower the limit on", "edit", question, () => {
            question.textQuestion.maxLength = limit;
            saveQuestion(question);
          });

          let curr = question.textQuestion.maxLength;
          question.textQuestion.maxLength = limit;
          saveQuestion(question);
          setTimeout(() => {
            // Let the number input go back
            question.textQuestion.maxLength = curr;
            saveQuestion(question);
          }, 500);
          return;
        }
      }
    }

    question.textQuestion.maxLength = limit;
    saveQuestion(question);
  }

  function handleSetBuckets(val) {
    question.hasBuckets = val;
    saveQuestion(question);
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "10px",
      }}
    >
      {!question.matrixQuestion && (
        <>
          <div
            className={styles.textField}
            style={{ width: "100%", fontSize: ".85em" }}
          >
            <TextFieldSimple
              value={question.textQuestion?.placeholder}
              placeholder="Placeholder"
              onSave={handlePlaceholderChange}
              label="Placeholder"
            />
          </div>

          <NumberInputWithSlider
            val={question.textQuestion?.maxLength}
            onChange={changeCharLimit}
            min={10}
            max={10000}
            step={1}
            title={"Character Limit"}
          />
        </>
      )}

      {/* <div className={styles.bucketDivider}>
        <Label
          style={{
            fontWeight: "700",
            fontSize: ".9em",
            paddingLeft: "0em",
            width: "fit-content",
            // textTransform: "none",
          }}
        >
          Buckets
        </Label>
        <ToggleSwitch
          startChecked={question.hasBuckets}
          handleCheck={(val) => handleSetBuckets(val)}
        ></ToggleSwitch>
      </div>
      {question.hasBuckets && (
        <AnswerBuckets
          initbuckets={
            typeof question?.buckets === "string"
              ? JSON.parse(question?.buckets)
              : question?.buckets
          }
          question={question}
          saveQuestion={saveQuestion}
        ></AnswerBuckets>
      )} */}
    </div>
  );
}

function AnswerBuckets({ initbuckets, question, saveQuestion }) {
  const [buckets, setBuckets] = useState(initbuckets ? initbuckets : []);
  const [data, setData] = useState();
  const [delim, setDelim] = useState("");
  const [uploadPhrase, setUploadPhrase] = useState("Upload Bucket Results");

  const { CSVReader } = useCSVReader();
  const uploadResults = useImportBucketAnswers();

  function updateBuckets(copy) {
    setBuckets(copy);
    question.buckets = JSON.stringify(copy);
    saveQuestion(question);
  }

  function handleAddBucket() {
    let temp = [...buckets];
    temp.push({ name: "", properties: [], id: shortId() });
    updateBuckets(temp);
  }

  function handleAddProperty(index, propIndex) {
    let temp = [...buckets];
    if (propIndex) {
      temp[index].properties.splice(propIndex + 1, 0, "");
    } else {
      temp[index].properties.push("");
    }

    updateBuckets(temp);
  }

  function handleDeleteProperty(index, propIndex) {
    let temp = [...buckets];
    temp[index].properties.splice(propIndex, 1);
    updateBuckets(temp);
  }

  function handleDeleteBucket(index) {
    let temp = [...buckets];
    temp.splice(index, 1);
    updateBuckets(temp);
  }

  function handleBucketName(index, val) {
    let temp = [...buckets];
    temp[index].name = val;
    updateBuckets(temp);
  }
  function handlePropertyName(index, propIndex, val) {
    let temp = [...buckets];
    temp[index].properties[propIndex] = val;
    updateBuckets(temp);
  }

  function handleUploadResults() {
    uploadResults.mutate(
      {
        data: JSON.stringify(data),
        questionId: question.id,
        delimiter: delim,
      },
      {
        onSuccess: () => {
          setUploadPhrase(
            <>
              {" "}
              Uploaded <i className="bi bi-check-lg"></i>
            </>
          );
          setTimeout(() => setUploadPhrase("Upload Browse Results"), 1500);
        },
      }
    );
    setUploadPhrase(
      <>
        Uploading <Loading height={20} width={20} />
      </>
    );
  }

  return (
    <div className={styles.buckets}>
      {buckets.map((bucket, bucketIndex) => (
        <div>
          <div className={styles.bucket}>
            <div style={{ width: "100%" }}>
              <TextFieldSimple
                value={bucket.name}
                placeholder="New Bucket"
                onSave={(val) => handleBucketName(bucketIndex, val)}
                label="Bucket Name"
              />
            </div>
            <div
              className={styles.deleteButton}
              onClick={() => handleDeleteBucket(bucketIndex)}
              style={{ paddingBottom: ".2em" }}
            >
              <i className="bi bi-dash-circle"></i>
            </div>
          </div>
          {bucket.properties.map((property, i) => (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "100%" }}>
                  <TextFieldSimple
                    value={property}
                    placeholder="New Property"
                    onEnter={() => handleAddProperty(bucketIndex, i)}
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      borderRadius: "0em",
                      cursor: "text",
                      marginLeft: "2em",
                      marginRight: "1em",
                      // width: "100%",
                    }}
                    onSave={(val) => handlePropertyName(bucketIndex, i, val)}
                  />
                </div>
                <div
                  className={styles.deleteButton}
                  onClick={() => handleDeleteProperty(bucketIndex, i)}
                >
                  <i className="bi bi-dash-circle"></i>
                </div>
              </div>
              {i === bucket.properties.length - 1 && (
                <div
                  className={styles.addprop}
                  onClick={() => handleAddProperty(bucketIndex)}
                >
                  <i className="bi bi-plus"></i>Property
                </div>
              )}
            </div>
          ))}
          {bucket.properties.length === 0 && (
            <div
              className={styles.addprop}
              onClick={() => handleAddProperty(bucketIndex)}
            >
              <i className="bi bi-plus"></i>Property
            </div>
          )}
        </div>
      ))}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: ".5em",
          gap: "15px",
        }}
      >
        <Button gray height={30} onClick={handleAddBucket}>
          + Add Bucket
        </Button>
        {question.answer.length > 0 && (
          <CSVReader
            config={{ header: true }}
            onUploadAccepted={(results) => {
              setData(results.data);
            }}
          >
            {({
              getRootProps,
              acceptedFile,
              ProgressBar,
              getRemoveFileProps,
            }) => (
              <>
                {!acceptedFile && (
                  <Button type="button" {...getRootProps()} height={30}>
                    Browse Bucket Results
                  </Button>
                )}
                {acceptedFile && (
                  <>
                    <div style={{ width: "100px" }}>
                      <TextFieldSimple
                        label={"Delimeter"}
                        value={delim}
                        onChange={(val) => setDelim(val)}
                      />
                    </div>
                    <div>{acceptedFile && acceptedFile.name}</div>

                    <Button
                      type="button"
                      height={30}
                      onClick={handleUploadResults}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        {uploadPhrase}
                      </div>
                    </Button>
                  </>
                )}
              </>
            )}
          </CSVReader>
        )}
      </div>
    </div>
  );
}
