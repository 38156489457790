import styles from "./Triggers.module.scss";
import { useEffect, useRef, useState } from "react";
import { SelectField, TextFieldSimple } from "components/inputs";
import Button from "components/Button/Button";
import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";
import { TriggerMessage } from "./TriggerMessage";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { Label } from "components/layouts/Label/Label";
import { useFetchCurrOrgsFields } from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import {
  useGetUserById,
  useSearchUsers,
} from "api/resources/organization/users";

export default function Triggers({ question, saveQuestion, close }) {
  const fetchCustomFields = useFetchCurrOrgsFields();

  const genOperators = [
    { value: "answer", label: "answer" },
    { value: "do not answer", label: "do not answer" },
  ];

  const scaleOperators = [
    { value: "between", label: "answer between" },
    { value: "below", label: "answer below" },
    { value: "above", label: "answer above" },
  ];

  const choiceSingleOperators = [];

  const choiceManyOperators = [];

  const freeResponseOperators = [
    { value: "contains", label: "contains" },
    { value: "does not contain", label: "does not contain" },
    { value: "is longer than", label: "is longer than" },
    { value: "is shorter than", label: "is shorter than" },
  ];

  const [edited, setEdited] = useState(false);

  const [triggers, setTriggers] = useState(
    question?.triggers ? JSON.parse(JSON.stringify(question?.triggers)) : []
  );

  const ref = useRef(null);

  // function clickOut(event) {
  //   if (ref.current && !ref.current.contains(event.target)) {
  //     console.log("out");
  //     close();
  //   } else {
  //     console.log("in");
  //   }
  // }

  // useEffect(() => {
  //   setTimeout(() => {
  //     document.addEventListener("click", clickOut, true);
  //   }, 1000);
  //   return () => {
  //     document.removeEventListener("click", clickOut, true);
  //   };
  // }, []);

  function updateTriggers(newTriggers) {
    setTriggers(newTriggers);
    setEdited(true);
  }

  function addTrigger() {
    let copy = [...triggers];
    copy.push({
      op: "",
      condition: "",
      message:
        "{recipient name}, {participant name} answered {answer}, you should follow up with them",
      sendEmail: true,
      users: [],
      emails: [],
      usersWithSame: [],
    });
    updateTriggers(copy);
  }

  function deleteTrigger(ind) {
    let copy = [...triggers];
    copy.splice(ind, 1);
    updateTriggers(copy);
  }

  function getRelavantOperators() {
    if (question.scaleQuestion) {
      return [...genOperators, ...scaleOperators];
    } else if (question.textQuestion) {
      return freeResponseOperators;
    } else if (question.choiceQuestion) {
      if (question.choiceQuestion.isMultiSelect) {
        return [...genOperators, ...choiceManyOperators];
      } else {
        return [...genOperators, ...choiceSingleOperators];
      }
    }
    return [];
  }

  function getAnswerOptions() {
    if (question.choiceQuestion) {
      let choiceQ = question.choiceQuestion;
      let choices = choiceQ.choices.map((choice) => {
        return { value: choice, label: choice };
      });
      if (choiceQ.hasOtherOption) {
        choices.push({
          value: choiceQ.otherOptionLabel,
          label: choiceQ.otherOptionLabel,
        });
      }
      return choices;
    }

    if (question.scaleQuestion) {
      let scale = question.scaleQuestion;
      let options = [];
      for (let i = scale.min; i < scale.max + 1; i += scale.step) {
        options.push({ value: i, label: i });
      }
      return options;
    }
    return [];
  }

  function changeOperator(ind, val) {
    let copy = [...triggers];
    copy[ind].op = val;
    copy[ind].condition = "";
    updateTriggers(copy);
  }

  function changeOption(ind, val) {
    let copy = [...triggers];
    copy[ind].option = val;
    updateTriggers(copy);
  }

  function canSelectMultiple(trigger) {
    if (trigger.op) {
      let op = trigger.op;
      return op === "answer" || op === "do not answer" || op === "between";
    }
    return false;
  }

  function changeCondition(ind, chosen) {
    let copy = [...triggers];

    if (canSelectMultiple(copy[ind])) {
      if (!chosen.length) {
        copy[ind].condition = null;
      } else {
        if (copy[ind].op === "between" && chosen.length == 3) {
          let last2 = chosen.slice(1);
          copy[ind].condition = last2.map((c) => c.value);
        } else {
          copy[ind].condition = chosen.map((c) => c.value);
        }
      }
    } else {
      copy[ind].condition = chosen.value;
    }

    updateTriggers(copy);
  }

  function changeTextCondition(ind, val) {
    let copy = [...triggers];
    copy[ind].condition = val;
    updateTriggers(copy);
  }

  function addUser(ind, userId) {
    let copy = [...triggers];
    copy[ind].users.push(userId);
    updateTriggers(copy);
  }

  function removeUser(ind, userId) {
    let copy = [...triggers];
    let i = copy[ind].users.indexOf(userId);
    if (i > -1) {
      copy[ind].users.splice(i, 1);
      updateTriggers(copy);
    }
  }

  function addCustomField(ind, id) {
    let copy = [...triggers];
    copy[ind].usersWithSame.push(id);
    updateTriggers(copy);
  }

  function removeCustomField(ind, id) {
    let copy = [...triggers];
    let fieldInd = copy[ind].usersWithSame.indexOf(id);
    if (fieldInd > -1) {
      copy[ind].usersWithSame.splice(fieldInd, 1);
      updateTriggers(copy);
    }
  }

  function addEmail(ind, val) {
    let copy = [...triggers];
    copy[ind].emails.push(val);
    updateTriggers(copy);
  }

  function removeEmail(ind, email) {
    let copy = [...triggers];
    let i = copy[ind].emails.indexOf(email);
    if (i >= 0) {
      copy[ind].emails.splice(i, 1);
      updateTriggers(copy);
    }
  }

  function editMessage(ind, val) {
    let copy = [...triggers];
    copy[ind].message = val;
    updateTriggers(copy);
  }

  function toggleEmail(ind, val) {
    let copy = [...triggers];
    copy[ind].sendEmail = val;
    updateTriggers(copy);
  }

  function showSave() {
    for (let trigger of triggers) {
      if (question.type === "Matrix" && !trigger.option) {
        return false;
      }
      let ready =
        trigger.op &&
        trigger.condition &&
        (trigger.users.length ||
          trigger.emails.length ||
          trigger.usersWithSame.length);
      if (!ready) {
        return false;
      }
    }

    return edited;
  }

  function save() {
    let copy = { ...question };
    copy.triggers = triggers;
    saveQuestion(copy);
    setEdited(false);
  }


  return (
    <div className={styles.settingsContainer} ref={ref}>
      {/* <div className={styles.close} onClick={close}>
        <i className="bi bi-x-lg"></i>
      </div> */}
      <div className={styles.questionName}>
        Alerting logic for "{question?.questionText}"
      </div>

      {fetchCustomFields.isError && <div>Error</div>}
      {fetchCustomFields.isLoading && <Loading></Loading>}
      {fetchCustomFields.isSuccess && (
        <>
          {triggers.map((trigger, ind) => (
            <Trigger trigger={trigger} ind={ind} />
          ))}
          <div className={styles.addBox}>
            <Button height={30} white onClick={addTrigger} shadow>
              <div style={{ display: "flex", gap: "5px" }}>
                <i className="bi bi-plus-lg"></i> Alert
              </div>
            </Button>
            {showSave() && (
              <Button height={30} onClick={() => save()} shadow>
                Save
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );

  function Trigger({ trigger, ind }) {
    const [showPeople, setShowPeople] = useState(false);
    const [showFields, setShowFields] = useState(false);
    const [showEmailEntry, setShowEmailEntry] = useState(false);

    function getChosenAnswerOptions() {
      if (trigger?.condition || trigger.condition === 0) {
        if (typeof trigger.condition === "object") {
          return trigger.condition.map((val) => {
            return { value: val, label: val };
          });
        } else {
          return { value: trigger.condition, label: trigger.condition };
        }
      }
      return null;
    }

    function beginEmailToggle(val) {
      // Allows for the smooth transition in the toggle before re-rendering
      setTimeout(() => {
        toggleEmail(ind, val);
      }, 300);
    }

    function getLeftFields() {
      let left = [];
      for (let field of fetchCustomFields.data.getCurrOrgFields) {
        if (!trigger.usersWithSame.includes(field.id)) {
          left.push({ value: field.id, label: field.name });
        }
      }
      return left;
    }

    function saveEmail(val) {
      addEmail(ind, val);
      setShowEmailEntry(false);
    }

    function showNotify() {
      if (trigger.op && trigger.condition) {
        if (question.scaleQuestion && trigger.op === "between") {
          if (trigger.condition.length == 2) {
            return true;
          }
        } else {
          return true;
        }
      }
      return false;
    }

    return (
      <div className={styles.trigger}>
        <i
          className={`bi bi-trash ${styles.trash}`}
          onClick={() => deleteTrigger(ind)}
        ></i>

        <div className={styles.configure}>
          {question.type === "Matrix" && (
            <div className={styles.opAndCondition}>
              <div>For</div>
              <div style={{ flexGrow: 1 }}>
                <SelectField
                  options={question.matrixQuestion.options.map((opt) => {
                    return { value: opt, label: opt };
                  })}
                  value={question.matrixQuestion.options
                    .map((opt) => {
                      return { value: opt, label: opt };
                    })
                    .find((opt) => trigger.option === opt.value)}
                  onChange={(opt) => changeOption(ind, opt.value)}
                ></SelectField>
              </div>
            </div>
          )}
          <div className={styles.opAndCondition}>
            <div>{`If ${question.textQuestion ? "their answer" : "they"}`}</div>
            <div style={{ flexGrow: 1 }}>
              <SelectField
                options={getRelavantOperators()}
                value={getRelavantOperators().find(
                  (type) => trigger.op === type.value
                )}
                onChange={(option) => changeOperator(ind, option.value)}
              ></SelectField>
            </div>

            {trigger.op && (
              <>
                {!question.textQuestion && (
                  <div style={{ flexGrow: 1 }}>
                    <SelectField
                      options={getAnswerOptions()}
                      value={getChosenAnswerOptions()}
                      onChange={(chosen) => changeCondition(ind, chosen)}
                      selectMultiple={canSelectMultiple(trigger)}
                    ></SelectField>
                  </div>
                )}

                {question.textQuestion &&
                  (trigger.op === "contains" ||
                    trigger.op === "does not contain") && (
                    <div style={{ flexGrow: 1 }}>
                      <TextFieldSimple
                        // style={{ flexGrow: 1 }}
                        // label={"What to look for"}
                        placeholder="Enter text"
                        onSave={(val) => changeTextCondition(ind, val)}
                        onEnter={(val) => changeTextCondition(ind, val)}
                        value={trigger?.condition}
                      />
                    </div>
                  )}

                {question.textQuestion &&
                  (trigger.op === "is longer than" ||
                    trigger.op === "is shorter than") && (
                    <div
                      style={{
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        gap: ".5em",
                      }}
                    >
                      <NumberInput
                        startNumber={
                          trigger.condition ? trigger.condition : 100
                        }
                        min={0}
                        max={2000}
                        onSave={(val) => changeTextCondition(ind, val)}
                      />
                      <div className={styles.label}>Characters </div>
                    </div>
                  )}
              </>
            )}
          </div>

          {showNotify() && (
            <>
              <div className={styles.notifyWho}>
                <div>Notify</div>
                <div className={styles.who}>
                  <div className={styles.whoRow}>
                    <div className={styles.whoHeader}>People:</div>
                    <div className={styles.users}>
                      {trigger.users.map((userId) => (
                        <div className={`${styles.pill} `}>
                          <div className={`${styles.pillName} ${styles.user}`}>
                            <UserName userId={userId} />
                          </div>
                          <div
                            className={`${styles.deletePill} ${styles.deleteUser}`}
                            onClick={() => removeUser(ind, userId)}
                          >
                            <i className="bi bi-x"></i>
                          </div>
                        </div>
                      ))}

                      <div className={styles.anchor}>
                        {showPeople && (
                          <UserList
                            close={() => setShowPeople(false)}
                            onAdd={(userId) => addUser(ind, userId)}
                            trigger={trigger}
                          />
                        )}
                        <div
                          className={styles.plus}
                          onClick={() => setShowPeople(true)}
                          style={
                            showPeople ? { visibility: "hidden" } : undefined
                          }
                        >
                          +
                        </div>
                      </div>
                    </div>
                  </div>

                  {trigger?.sendEmail && (
                    <div className={styles.whoRow}>
                      <div className={styles.whoHeader}>Emails:</div>
                      <div className={styles.emails}>
                        {trigger.emails.map((email) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <div className={styles.email}>{email}</div>
                            <div
                              onClick={() => removeEmail(ind, email)}
                              style={{ cursor: "pointer" }}
                            >
                              <i className="bi bi-x"></i>
                            </div>
                          </div>
                        ))}

                        <div className={styles.anchor}>
                          {showEmailEntry && (
                            <EmailEntry
                              onSave={saveEmail}
                              close={() => setShowEmailEntry(false)}
                            />
                          )}
                          <div
                            className={styles.plus}
                            onClick={() => setShowEmailEntry(true)}
                            style={
                              showEmailEntry
                                ? { visibility: "hidden" }
                                : undefined
                            }
                          >
                            +
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className={styles.whoRow}>
                    <div className={`${styles.whoHeader} ${styles.matching}`}>
                      Corresponding:
                      <div className={styles.matchingInfo}>
                        <i className="bi bi-info-square"></i>
                        <div className={styles.matchingTooltip}>
                          People who have the same ___ as the participant
                        </div>
                      </div>
                    </div>
                    <div className={styles.customFields}>
                      {trigger.usersWithSame.map((fieldId) => (
                        <div className={styles.pill}>
                          <div
                            className={`${styles.pillName} ${styles.customField}`}
                          >
                            {
                              fetchCustomFields.data.getCurrOrgFields.find(
                                (f) => f.id === fieldId
                              )?.name
                            }
                          </div>
                          <div
                            className={`${styles.deletePill} ${styles.deleteField}`}
                            onClick={() => removeCustomField(ind, fieldId)}
                          >
                            <i className="bi bi-x"></i>
                          </div>
                        </div>
                      ))}

                      <div className={styles.anchor}>
                        <div
                          className={styles.plus}
                          onClick={() => setShowFields(true)}
                          style={
                            showFields || !getLeftFields().length
                              ? { visibility: "hidden" }
                              : undefined
                          }
                        >
                          +
                        </div>
                        {showFields && (
                          <DropDown
                            options={getLeftFields()}
                            onPick={(id) => addCustomField(ind, id)}
                            close={() => setShowFields(false)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {(trigger.emails.length > 0 ||
                trigger.users.length > 0 ||
                trigger.usersWithSame.length > 0) && (
                <>
                  <div className={styles.withMessage}>
                    <div>Message</div>
                    <TriggerMessage
                      trigger={trigger}
                      onEdit={(val) => editMessage(ind, val)}
                      custom_fields={fetchCustomFields.data.getCurrOrgFields}
                    ></TriggerMessage>
                  </div>
                  <div className={styles.emailToggle}>
                    <ToggleSwitch
                      startChecked={trigger?.sendEmail}
                      handleCheck={beginEmailToggle}
                      id={"email"}
                    ></ToggleSwitch>
                    <Label
                      style={{ width: "fit-content" }}
                      tooltipText={
                        "Would you like to send them an email as well?"
                      }
                      labelIcon={
                        <i
                          className="bi bi-info-square"
                          style={{ fontSize: ".8em" }}
                        ></i>
                      }
                    >
                      Send Email
                    </Label>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

function UserName({ userId }) {
  const fetchUser = useGetUserById(userId);

  return (
    <>
      {fetchUser.isSuccess && (
        <>
          {`${
            fetchUser.data.getUserById.prefix
              ? fetchUser.data.getUserById.prefix
              : ""
          } ${fetchUser.data.getUserById.firstName} ${
            fetchUser.data.getUserById.lastName
          }`}
        </>
      )}
    </>
  );
}

function UserList({ onAdd, close, trigger }) {
  const ref = useRef();
  const [search, setSearch] = useState("");

  const searchResults = useSearchUsers(search);

  function clickOut(event) {
    if (ref.current && !ref.current?.contains(event.target)) {
      document.removeEventListener("click", clickOut, true);
      close();
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOut, true);
    return () => {
      document.removeEventListener("click", clickOut, true);
    };
  }, []);

  return (
    <div className={styles.userList} ref={ref}>
      <div className={styles.userSearch}>
        <TextFieldSimple
          placeholder={"Search people..."}
          onChange={(val) => setSearch(val)}
          value={search}
          customStyles={`${styles.textField} ${styles.textFieldLighterBlue}`}
        />
      </div>

      {search && (
        <>
          {searchResults.isLoading && <Loading height={40} width={40} />}
          {searchResults.isError && (
            <div className={styles.userResults}>
              <div className={styles.option}>Error</div>
            </div>
          )}
          {searchResults.isSuccess && searchResults.data.searchUser && (
            <div className={styles.userResults}>
              {searchResults.data.searchUser.map((user) => {
                if (!trigger.users.includes(user.id))
                  return (
                    <div
                      className={styles.option}
                      onClick={() => onAdd(user.id)}
                    >
                      {`${user.prefix ? user.prefix : ""} ${user.firstName} ${
                        user.lastName
                      }`}
                    </div>
                  );
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
}

function DropDown({ options, onPick, close }) {
  const ref = useRef();

  function clickOut(event) {
    console.log("click");
    if (ref.current && !ref.current?.contains(event.target)) {
      document.removeEventListener("click", clickOut, true);
      close();
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOut, true);
    return () => {
      document.removeEventListener("click", clickOut, true);
    };
  }, []);

  return (
    <div className={styles.dropDown} ref={ref}>
      {options.map((opt) => (
        <div className={styles.option} onClick={() => onPick(opt.value)}>
          {opt.label}
        </div>
      ))}
    </div>
  );
}

function EmailEntry({ onSave, close }) {
  const [valid, setValid] = useState(true);

  const ref = useRef();

  function clickOut(event) {
    if (ref.current && !ref.current?.contains(event.target)) {
      document.removeEventListener("click", clickOut, true);
      close();
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOut, true);
    return () => {
      document.removeEventListener("click", clickOut, true);
    };
  }, []);

  function validEmailTest(val) {
    if (!val.includes(" ")) {
      let atInd = val.indexOf("@");
      if (atInd >= 1) {
        //can't start with @ either
        let substring = val.substring(atInd);
        let dotInd = substring.indexOf(".");
        if (dotInd >= 2) {
          // can't go @.
          let last = substring.substring(dotInd + 1);
          if (last.length) {
            setValid(true);
            return true;
          }
        }
      }
    }
    setValid(false);
    return false;
  }

  function checkVal(val) {
    if (validEmailTest(val)) {
      onSave(val.toLowerCase());
    }
  }

  return (
    // <div className={styles.anchor}>
    <div className={styles.emailEntry} ref={ref}>
      <TextFieldSimple
        placeholder={"Enter email..."}
        onSave={checkVal}
        onEnter={checkVal}
        customStyles={`${styles.textField} ${!valid ? styles.invalid : ""}`}
        valid={valid}
        onChange={!valid ? validEmailTest : null}
      />
    </div>
    // </div>
  );
}
