// External
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

// Internal
import styles from "./SurveyBuild.module.scss";
import { Loading } from "components/Loading/Loading";
import { useParams } from "react-router-dom";
import { useGetQsAndSectionsForSurveyBuilder } from "api/resources/projects/questions";
import {
  useFetchProjectAndImage,
  useFetchProjectGql,
  useFetchProjectNumPages,
} from "api/resources/projects/projects";
import { QuestionConstruction } from "components/QuestionConstruction/QuestionConstruction";
import { ErrorPage } from "pages/error_page/ErrorPage";
import { routeVariants } from "assets/functions/Variables";
import Modal from "components/ReactModal/ReactModal";

export const SurveyBuild = ({
  roles,
  organization,
  direction,
  setBeforeNavAway,
}) => {
  const { id } = useParams();
  const [maxPage, setMaxPage] = useState(0); //maximum page
  const [show, setShow] = useState(false);
  const [pages, setPages] = useState([]);

  const [currPage, setCurrPage] = useState(1);
  const [settings, setSettings] = useState();

  const [allQuestions, setAllQuestions] = useState();
  // const [justQuestions, setJustQuestions] = useState();
  const [questions, setQuestions] = useState();
  const [fullscreen, setFullscreen] = useState(false);
  const [seeAll, setSeeAll] = useState(true);

  const fetchQsAndSections = useGetQsAndSectionsForSurveyBuilder();
  const fetchNumPages = useFetchProjectNumPages();
  const fetchDesignSettings = useFetchProjectAndImage();
  const fetchProjectQuery = useFetchProjectGql(id);
  const [showLoading, setShowLoading] = useState(false);

  function setQsAndSections(questions, sections, newPageNum) {
    for (let i = 0; i < sections.length; i++) {
      if (typeof sections[i].sectionSettings === "string") {
        sections[i].sectionSettings = JSON.parse(sections[i].sectionSettings);
      }
      if (
        sections[i]?.conditionGroups &&
        typeof sections[i].conditionGroups === "string"
      ) {
        sections[i].conditionGroups = JSON.parse(sections[i].conditionGroups);
      }
    }

    for (let i = 0; i < questions.length; i++) {
      if (
        questions[i]?.conditionGroups &&
        typeof questions[i].conditionGroups === "string"
      ) {
        questions[i].conditionGroups = JSON.parse(questions[i].conditionGroups);
      }
      if (questions[i].triggers && typeof questions[i].triggers === "string") {
        questions[i].triggers = JSON.parse(questions[i].triggers);
      }
      if (
        questions[i].matrixQuestion &&
        typeof questions[i].matrixQuestion.settings === "string"
      ) {
        questions[i].matrixQuestion.settings = JSON.parse(
          questions[i].matrixQuestion.settings
        );
      }
    }

    let all = [...questions, ...sections].toSorted((a, b) => {
      if (a.pageNumber === b.pageNumber) {
        return a.pageOrderIndex > b.pageOrderIndex ? 1 : -1;
      } else {
        return a.pageNumber > b.pageNumber ? 1 : -1;
      }
    });

    if (seeAll) {
      let pages2 = [];
      for (let i = 0; i < maxPage; i++) {
        pages2.push([]);
      }

      for (let q of all) {
        if (pages2[q?.pageNumber - 1]) {
          pages2[q?.pageNumber - 1].push(q);
        }
      }

      setPages(pages2);
    }

    // let questionsOnly = [...questions].toSorted((a, b) => {
    //   if (a.pageNumber === b.pageNumber) {
    //     return a.pageOrderIndex > b.pageOrderIndex ? 1 : -1;
    //   } else {
    //     return a.pageNumber > b.pageNumber ? 1 : -1;
    //   }
    // });

    setAllQuestions(all);
    // setJustQuestions(questionsOnly);

    if (newPageNum) {
      setCurrPage(newPageNum);
    }
    let currentPage = newPageNum ? newPageNum : currPage;

    let thisPage = [];
    for (let q of all) {
      if (q.pageNumber === currentPage) {
        thisPage.push(q);
      }
    }
    setQuestions(thisPage);
    setShowLoading(false);
  }

  function fetchDSettings() {
    fetchDesignSettings.mutate(
      {
        id: id,
      },
      {
        onSuccess: (data) => {
          let dSettings = JSON.parse(
            data.getProjectAndImageUrl?.project.designSettings
          );
          setSettings(dSettings);
        },
      }
    );
  }

  // Optional newPageNum to set to once queried
  function fetchQs(newPageNum) {
    fetchQsAndSections.mutate(
      {
        projectId: id,
      },
      {
        onSuccess: (data) => {
          let qs = data.QsAndSectionsInSurvey.questions;
          let sections = data.QsAndSectionsInSurvey.sections;

          setQsAndSections(qs, sections, newPageNum);
        },
      }
    );
  }

  function getNumPages() {
    fetchNumPages.mutate(
      {
        projectId: id,
      },
      {
        onSuccess: (data) => {
          setMaxPage(data.projectNumPages);
        },
      }
    );
  }

  useEffect(() => {
    fetchQs();
  }, [maxPage]);

  useEffect(() => {
    // fetchQs();
    getNumPages();
    fetchDSettings();
  }, [seeAll]);

  function onPageChange(number) {
    setCurrPage(number);
    let thisPage = [];
    for (let q of allQuestions) {
      if (q.pageNumber === number) {
        thisPage.push(q);
      }
    }
    setQuestions(thisPage);
  }

  return (
    <motion.div
      variants={routeVariants}
      custom={{ direction, animate: true }}
      initial="enter"
      animate="center"
      exit="exit"
      className={styles.page}
    >
      {(!questions ||
        !allQuestions ||
        !settings ||
        fetchNumPages.isLoading ||
        fetchProjectQuery.isLoading) && <Loading></Loading>}
      {fetchQsAndSections.isError && (
        <ErrorPage
          error={fetchQsAndSections.error}
          backup="fetchQsAndSections in SurveyBuild"
        />
      )}
      {fetchNumPages.isError && (
        <ErrorPage
          error={fetchNumPages.error}
          backup="fetchNumPages in SurveyBuild"
        />
      )}
      {fetchDesignSettings.isError && (
        <ErrorPage
          error={fetchDesignSettings.error}
          backup="fetchDesignSetings in SurveyBuild"
        />
      )}
      {fetchProjectQuery.isError && (
        <ErrorPage
          error={fetchDesignSettings.error}
          backup="fetchProjectQuery in SurveyBuild"
        />
      )}

      {questions &&
        allQuestions &&
        settings &&
        fetchNumPages.isSuccess &&
        fetchProjectQuery.isSuccess &&
        !fullscreen && (
          <QuestionConstruction
            initFinishedMessage={fetchProjectQuery.data.survey?.finishedMessage}
            maxPage={maxPage}
            setMaxPage={setMaxPage}
            designSettings={settings}
            setDesignSettings={setSettings}
            status={fetchProjectQuery.data.survey.status}
            survey={fetchProjectQuery.data.survey}
            roles={roles}
            show={show}
            setShow={setShow}
            currPage={currPage}
            setCurrPage={onPageChange}
            questions={questions}
            setQuestions={setQuestions}
            allQuestions={allQuestions}
            setAllQuestions={setAllQuestions}
            refetchQs={fetchQs}
            refetchDSettings={fetchDSettings}
            showLoading={showLoading}
            setShowLoading={setShowLoading}
            projectId={id}
            projectOrgId={
              fetchQsAndSections.data?.QsAndSectionsInSurvey?.organizationId
            }
            orgId={organization.id}
            setBeforeNavAway={setBeforeNavAway}
            setFullscreen={setFullscreen}
            fullscreen={fullscreen}
            seeAll={seeAll}
            setSeeAll={setSeeAll}
            pages={pages}
            setPages={setPages}
          ></QuestionConstruction>
        )}
      {questions &&
        allQuestions &&
        settings &&
        fetchNumPages.isSuccess &&
        fetchProjectQuery.isSuccess &&
        fullscreen && (
          <Modal
            dark
            show={fullscreen}
            modalStyle={{
              height: "100%",
              width: "100%",
              maxWidth: "100%",
              maxHeight: "100%",
            }}
          >
            <QuestionConstruction
              initFinishedMessage={
                fetchProjectQuery.data.survey?.finishedMessage
              }
              maxPage={maxPage}
              setMaxPage={setMaxPage}
              designSettings={settings}
              setDesignSettings={setSettings}
              status={fetchProjectQuery.data.survey.status}
              survey={fetchProjectQuery.data.survey}
              roles={roles}
              show={show}
              setShow={setShow}
              currPage={currPage}
              setCurrPage={onPageChange}
              questions={questions}
              setQuestions={setQuestions}
              allQuestions={allQuestions}
              setAllQuestions={setAllQuestions}
              refetchQs={fetchQs}
              refetchDSettings={fetchDSettings}
              showLoading={showLoading}
              setShowLoading={setShowLoading}
              projectId={id}
              projectOrgId={
                fetchQsAndSections.data?.QsAndSectionsInSurvey?.organizationId
              }
              orgId={organization.id}
              setBeforeNavAway={setBeforeNavAway}
              setFullscreen={setFullscreen}
              fullscreen={fullscreen}
              seeAll={seeAll}
              setSeeAll={setSeeAll}
              pages={pages}
              setPages={setPages}
            ></QuestionConstruction>
          </Modal>
        )}
    </motion.div>
  );
};
