import { useState, useRef } from "react";
import styles from "./ToFrom.module.scss";
import { OneOrTheOther } from "components/inputs/input_fields/OneOrTheOther/OneOrTheOther";
import { useFetchMyOrg } from "api/resources/organization/organization";
import { OrgSignatures } from "pages/organization/organization_details/OrganizationDetails";

export function From({
  chosen,
  options,
  onChange,
  disable,
  shadow,
  dynamic,
  toggleDynamic,
  conditions,
  changeConditions,
}) {
  const [showOptions, setShowOptions] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchOptions, setSearchOptions] = useState();
  const [showManage, setShowManage] = useState("");

  const selectRef = useRef(null);
  const searchRef = useRef(null);
  const switchRef = useRef(null);

  function toggleDropDown(e) {
    if (showOptions) {
      if (
        (!searchRef.current || !searchRef.current.contains(e.target)) &&
        (!switchRef.current || !switchRef.current.contains(e.target))
      ) {
        setShowOptions(false);
      }
    } else {
      setShowOptions(true);
      document.addEventListener("click", handleClickOutside, true);
    }
  }

  const handleClickOutside = (event) => {
    if (!selectRef.current || !selectRef.current.contains(event.target)) {
      setShowOptions(false);
      searchRef.current = false;
      document.removeEventListener("click", handleClickOutside, true);
      search("");
    }
  };

  function search(userInput) {
    if (userInput) {
      let search = userInput?.toLowerCase();
      let results = [];
      for (let option of options) {
        if (
          option.aliasName?.toLowerCase()?.includes(search) ||
          option.fromAddress?.toLowerCase()?.includes(search)
        ) {
          results.push(option);
        }
      }
      setSearchOptions(results);
    } else {
      setSearchOptions(null);
    }
    setSearchValue(userInput);
  }

  function getBackColor() {
    if (showOptions) {
      return "white";
    }
    return "";
  }

  function onOptionClick(option) {
    onChange(option.id);
    setShowOptions(false);
    search("");
  }

  function proper(field) {
    if (field === "firstName") {
      return "first name";
    }
    if (field === "lastName") {
      return "last name";
    }
    return field;
  }

  const chosenFrom = chosen ? options.find((o) => o.id === chosen) : null;

  return (
    <>
      <div className={`${styles.container} `} ref={selectRef}>
        <div
          className={`${styles.select}  ${
            showOptions || shadow ? styles.shadow : ""
          }`}
          onClick={toggleDropDown}
          style={{
            pointerEvents: disable ? "none" : "",
            backgroundColor: getBackColor(),
          }}
        >
          <div className={styles.text}>
            <div className={`${styles.label6} ${styles.fromLabel} `}>
              Send From
            </div>

            <div className={styles.selected}>
              {!dynamic && chosenFrom && (
                <div className={styles.chosenFrom}>
                  <div
                    className={styles.chosenText}
                  >{`${chosenFrom.fromAddress}`}</div>
                </div>
              )}

              {dynamic && (
                <div className={styles.chosenDynamic}>
                  If contact's{" "}
                  <span className={styles.conditionField}>
                    {proper(conditions.fieldName)}
                  </span>{" "}
                  {conditions.condition} Alias'{" "}
                  <span className={styles.conditionField}>
                    {proper(conditions.aliasField)}
                  </span>
                </div>
              )}
            </div>

            {showOptions && !dynamic && (
              <div
                style={{
                  display: "flex",
                  gap: ".5em",
                  alignItems: "center",
                  paddingTop: "3px",
                }}
              >
                <input
                  className={`${styles.input}`}
                  value={searchValue}
                  placeholder={"Search..."}
                  onChange={(e) => search(e.target.value)}
                  ref={searchRef}
                  onClick={
                    !showOptions ? () => setShowOptions(true) : undefined
                  }
                />
              </div>
            )}
          </div>
          <i className={`${styles.icon} bi bi-chevron-down`}></i>
        </div>

        {showOptions && (
          <div className={styles.fromDropdown}>
            {(dynamic || !searchValue) && (
              <div className={`${styles.makeDynamic}`}>
                <OneOrTheOther
                  checked={dynamic}
                  onCheck={toggleDynamic}
                  left="Basic"
                  right="Dynamic"
                  activeColor={"#6793a5"}
                />
              </div>
            )}

            {dynamic && (
              <DynamicConditions
                signatureList={options}
                conditions={conditions}
                onChange={changeConditions}
              ></DynamicConditions>
            )}

            {!dynamic && (
              <>
                {!searchValue && (
                  <div
                    className={`${styles.option} ${styles.manageAudiences}`}
                    onClick={() => setShowManage(true)}
                    style={{
                      height: "fit-content",
                      padding: " 8px 0px 3px",
                      marginBottom: "2px",
                    }}
                  >
                    <div className={styles.manage}>
                      Manage Signatures <i className="bi bi-gear"></i>
                    </div>
                  </div>
                )}

                {(searchOptions ? searchOptions : options).map((option, i) => (
                  <div
                    key={i}
                    className={styles.aliasOption}
                    onClick={() => onOptionClick(option)}
                  >
                    {`<${option.aliasName}> ${option.fromAddress}`}
                    {chosen === option.id && (
                      <div className={styles.checked}>
                        <i className="bi-check"></i>
                      </div>
                    )}
                  </div>
                ))}
              </>
            )}
          </div>
        )}
      </div>

      {showManage && <OrgSignatures onClose={() => setShowManage(false)} />}
    </>
  );
}

function DynamicConditions({ signatureList, conditions, onChange }) {
  const organizationQuery = useFetchMyOrg();

  function changeField(field, val) {
    let copy = { ...conditions };
    copy[field] = val;
    onChange(copy);
  }

  return (
    <div className={styles.dynamicContainer}>
      <div className={styles.conditionRow}>
        If contact's{" "}
        {organizationQuery.isSuccess && (
          <select
            className={styles.dynamicSelect}
            value={conditions?.fieldName}
            onChange={(e) => changeField("fieldName", e.target.value)}
          >
            {organizationQuery?.data?.organization?.custom_field?.map(
              (field, i) => (
                <option key={i} value={field?.name}>
                  {field?.name}
                </option>
              )
            )}
            {/* <option value="firstName">First Name</option>
            <option value="lastName">Last Name</option>
            <option value="email">Email</option> */}
          </select>
        )}
      </div>
      <div className={styles.conditionRow} style={{ paddingLeft: "10px" }}>
        <select
          className={styles.dynamicSelect}
          value={conditions?.condition}
          onChange={(e) => changeField("condition", e.target.value)}
        >
          <option value="equals">Equals</option>
          {/* <option value="doesnotequal">Does Not Equal</option> */}
          <option value="contains">Contains</option>
          {/* <option value="doesnotcontain">Does Not Contain</option> */}
        </select>{" "}
      </div>
      <div className={styles.conditionRow}>
        Alias'{" "}
        <select
          className={styles.dynamicSelect}
          value={conditions?.aliasField}
          onChange={(e) => changeField("aliasField", e.target.value)}
        >
          {/* `<option value="firstNamelastName">First and Last Name</option> */}
          <option value="firstName">First Name</option>
          <option value="lastName">Last Name</option>
          <option value="email">Email</option>
        </select>
      </div>
      <div className={styles.blueText}>
        Send from User Alias that fulfills the above condition
      </div>
      <div className={styles.else}>
        If contact fails condition. Who should they get the email from?
      </div>
      <select
        className={styles.dynamicSelect}
        value={conditions?.elseSigId}
        onChange={(e) => changeField("elseSigId", e.target.value)}
        style={{ width: "100%" }}
      >
        {signatureList &&
          signatureList.map((signature) => (
            <option
              className={styles.sigContainer}
              key={signature.id}
              value={signature.id}
            >
              {`<${signature?.aliasName}>`} {signature?.fromAddress}
            </option>
          ))}
        <option className={styles.sigContainer} key={"None"} value={"None"}>
          {"do_not_reply@reactiondata.com"}
        </option>
      </select>
    </div>
  );
}
