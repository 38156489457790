import { useState } from "react";

//Internal
import styles from "./ConfigureBox.module.scss";
import { SettingsAccordion } from "./SettingsAccordion";
import { MetricType, ValueType } from "../QuestionChart";
import {
  useFetchProjectsByIdsGql,
  useGetStatChartProjects,
} from "api/resources/projects/projects";

import { combinedProjIds, combinedQs } from "../Visualization";

export default function ConfigureBox({
  visualizationCopy,
  setVisualizationCopy,
  lockData,
  newChart,
  custom_fields,
  externalFilter,
  visibleItems,
  setVisibleItems,
  labels,
}) {
  const [hide, setHide] = useState(false);

  const getProjects =
    visualizationCopy.dataMethod === "survey"
      ? useGetStatChartProjects(visualizationCopy)
      : useFetchProjectsByIdsGql({
          projectIds: combinedProjIds(visualizationCopy),
        });

  const score =
    visualizationCopy.type === MetricType ||
    visualizationCopy.type === ValueType;

  const [dataPage, setDataPage] = useState(
    score ? false : newChart || !labels || labels?.length === 0 ? true : false
  );

  const [reDraw, setReDraw] = useState(0);

  function setUpDataPage(val) {
    setDataPage(val);
    setReDraw(reDraw + 1);
  }

  function hasOneProject() {
    if (visualizationCopy.projectIdsArray.length) {
      return true;
    }
    for (let id in visualizationCopy.designSettings.dynamics) {
      if (
        visualizationCopy.designSettings.dynamics[id].timeFrame !== "selection"
      ) {
        return true;
      } else if (
        visualizationCopy.designSettings.dynamics[id].selection.length
      ) {
        return true;
      }
    }
    return false;
  }

  const showSave = score
    ? visualizationCopy.designSettings.metrics.length > 0
    : visualizationCopy.dataMethod === "survey"
    ? hasOneProject()
    : combinedQs(visualizationCopy).length > 0;

  function onFilterChange(filters) {
    let newViz = { ...visualizationCopy };
    newViz.filters = JSON.stringify(filters);
    let count = 0;
    for (let prop in filters) {
      if (prop === "surveys") {
        count += filters[prop].length;
      } else if (prop === "answers") {
        count += filters[prop].length;
      } else if (prop !== "orAnswers") {
        count += filters[prop].properties.length;
      }
    }
    newViz.designSettings.showFilterSubtitle = count < 4;
    setVisualizationCopy(newViz);
  }

  const [chartData, setChartData] = useState();

  const [dataCounter, setDataCounter] = useState(0);

  function setUpChartData(val) {
    if (val) {
      setDataCounter(dataCounter + 1);
    } else {
      setChartData(null);
      setDataCounter(0);
    }
  }

  return (
    <>
      {!hide && (
        <div className={`${styles.configBox} `}>
          <div className={styles.formContainer}>
            <SettingsAccordion
              viz={visualizationCopy}
              updateViz={setVisualizationCopy}
              labels={labels}
              lockData={lockData}
              projects={getProjects.isSuccess ? getProjects.data?.survey : []}
              custom_fields={custom_fields}
              onFilterChange={onFilterChange}
              externalFilter={externalFilter}
              newChart={newChart}
              dataPage={dataPage}
              setDataPage={setUpDataPage}
              chartData={chartData}
              setChartData={setUpChartData}
              hasData={showSave}
              visibleItems={visibleItems}
              setVisible={setVisibleItems}
            />
            <div className={styles.hide} onClick={() => setHide(true)}>
              Hide Settings
            </div>
          </div>
        </div>
      )}
      {hide && (
        <div onClick={() => setHide(false)} className={styles.show}>
          <i className="bi bi-pencil"></i>
        </div>
      )}
    </>
  );
}
