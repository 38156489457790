import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";
import { SelectField, TextField } from "components/inputs";
import React, { useState, useEffect } from "react";
import styles from "../SettingsAccordion.module.scss";
import {
  TableType,
  High2Low,
  Low2High,
  NoSort,
  NumUp,
  NumDown,
  AtoZ,
  ZtoA,
  MetricType,
  ValueType,
} from "../../QuestionChart";
import { Label } from "components/layouts/Label/Label";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import Button from "components/Button/Button";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import TextEditorTry from "components/inputs/input_fields/TextEditorTry/TextEditorTry";
import { combinedQs } from "../../Visualization";
import FlexRow from "components/layouts/FlexRow/FlexRow";

//custom accordian
export const TextBoxSettings = ({
  viz,
  title,
  changeSettingsField,
  labels,
  visible,
  setVisible,
}) => {

  function updateBox(index, field, val) {
    let boxes = [...viz.designSettings.textboxes];
    boxes[index][field] = val;
    changeSettingsField("textboxes", boxes);
  }

  function addBox() {
    if (!viz.designSettings.textboxes) {
      let boxes = [];
      boxes.push({
        top: "10%",
        left: "95%",
        text: "Text Box",
        color: "gray",
        backgroundColor: "white",
        valuetype: "static",
        stickRight: false,
        stickLeft: false,
      });
      changeSettingsField("textboxes", boxes);
    } else {
      let boxes = [...viz.designSettings.textboxes];
      boxes.push({
        top: "10%",
        left: "95%",
        text: "Text Box",
        color: "gray",
        backgroundColor: "white",
        valuetype: "static",
        stickRight: false,
        stickLeft: false,
      });
      changeSettingsField("textboxes", boxes);
    }
  }

  function deleteBox(index) {
    let boxes = [...viz.designSettings.textboxes];
    boxes.splice(index, 1);
    changeSettingsField("textboxes", boxes);
  }

  const vizQs = combinedQs(viz);

  function allScale() {
    if (vizQs && vizQs.length) {
      for (let q of vizQs) {
        if (!q.scaleQuestion) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  function couldBeNPS() {
    for (let q of vizQs) {
      if (!(q.scaleQuestion.min === 0 && q.scaleQuestion.max === 10)) {
        return false;
      }
    }
    return true;
  }

  // {
  //   code: "npsScore",
  //   icon: "bookmark-check",
  //   title: "NPS Score",
  // },
  // {
  //   code: "numberOfComments",
  //   icon: "chat-text",
  //   title: "Number of Comments",
  // },

  const metricOptions = [
    {
      label: "Response Rate",
      value: "responseRate",
    },
    {
      label: "Responses",
      value: "responseCount",
    },
    {
      label: "Audience Size",
      value: "audienceSize",
    },
  ];

  function getOptions() {
    let options = [];

    if (viz.type === MetricType || viz.type === ValueType) {
      options = [...metricOptions];
    } else {
      if (allScale()) {
        if (couldBeNPS()) {
          options.push({ value: "nps", label: "NPS" });
        }
        options.push({
          value: "average",
          label: "Average Value",
        });
      }
      options.push({ value: "answercount", label: "Answer Count" });
    }

    options.push({ value: "static", label: "Static Value" });
    return options;
  }

  return (
    <>
      <div
        key={"textboxes"}
        className={`${styles.header} ${visible ? styles.headervisible : ""}`}
        onClick={!visible ? setVisible : undefined}
      >
        {/* {title} */}
        <FlexRow onClick={visible ? setVisible : undefined} style={{ cursor: "pointer" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
            {title}{" "}
            <i
              style={{ fontSize: "1.2em" }}
              // className="bi bi-input-cursor-text"
              className="bi bi-textarea-t"
            ></i>
          </div>

          <span className={styles.accordionicon}>
            <i className="bi bi-caret-left-fill"></i>
          </span>
        </FlexRow>
        {visible && <TextBoxes boxes={viz.designSettings.textboxes} />}{" "}
      </div>
    </>
  );

  function TextBoxes() {
    function getValue(box) {
      let val = getOptions().find((type) => box.valuetype === type.value);
      if (val) {
        return val;
      } else {
        return { value: "static", label: "Static Value" };
      }
    }

    return (
      <>
        {viz.designSettings.textboxes &&
          viz.designSettings.textboxes.map((box, index) => (
            <div className={styles.settingContainer2}>
              {/* {console.log(box)} */}
              <Label
                // italics
                style={{
                  fontWeight: "700",
                  fontSize: ".9em",
                  paddingLeft: "0px",
                }}
              >
                Box {index + 1}{" "}
                <i
                  className="bi bi-dash-circle"
                  style={{ cursor: "pointer", color: "#FF8878" }}
                  onClick={() => deleteBox(index)}
                ></i>
              </Label>
              <div className={styles.settingwithlabel}>
                <Label
                  // italics
                  style={{
                    fontWeight: "400",
                    width: "fit-content",
                    fontSize: ".8em",
                    whiteSpace: "nowrap",
                    padding: "0em",
                    margin: "0em",
                  }}
                >
                  Text Box Content
                </Label>
                <div>
                  <SelectField
                    options={getOptions()}
                    value={getValue(box)}
                    onChange={(typeOption) =>
                      updateBox(index, "valuetype", typeOption.value)
                    }
                  ></SelectField>
                </div>
              </div>

              {box.valuetype === "static" && (
                <div style={{ width: "100%", marginBottom: "5px" }}>
                  <TextEditorTry
                    // initState={getState()}
                    onSave={(text, state) => {
                      updateBox(index, "state", state);
                      updateBox(index, "text", text);
                    }}
                    border
                    encoding={box.state}
                    editable
                    placeholder="Enter text..."
                    showToolbar
                    defaultStyle={{
                      fontSize: "9pt",
                      // margin: "1em"
                    }}
                    style={{ paddingBottom: "1em" }}
                  />
                </div>
              )}

              <div className={styles.settingContainer2}>
                <div className={styles.setting2}>
                  <NumberInput
                    startNumber={box.left}
                    handleNumberChange={(val) => updateBox(index, "left", val)}
                    min={0}
                    max={100}
                  ></NumberInput>
                  <Label
                    style={{
                      fontWeight: "400",
                      width: "fit-content",
                      fontSize: ".8em",
                      whiteSpace: "nowrap",
                      padding: "0em",
                      margin: "0em",
                    }}
                  >
                    Horizontal (X) Position
                  </Label>
                </div>

                <div className={styles.setting2}>
                  <NumberInput
                    startNumber={box.width}
                    handleNumberChange={(val) => updateBox(index, "width", val)}
                    min={0}
                    max={500}
                    step={10}
                  ></NumberInput>
                  <Label
                    style={{
                      fontWeight: "400",
                      width: "fit-content",
                      fontSize: ".8em",
                      whiteSpace: "nowrap",
                      padding: "0em",
                      margin: "0em",
                    }}
                  >
                    Width
                  </Label>
                </div>
                <div className={styles.setting2}>
                  <NumberInput
                    startNumber={box.top}
                    handleNumberChange={(val) => updateBox(index, "top", val)}
                    min={0}
                    max={100}
                  ></NumberInput>
                  <Label
                    style={{
                      fontWeight: "400",
                      width: "fit-content",
                      fontSize: ".8em",
                      whiteSpace: "nowrap",
                      padding: "0em",
                      margin: "0em",
                    }}
                  >
                    Vertical (Y) Position
                  </Label>
                </div>
                <div className={styles.setting2}>
                  <NumberInput
                    startNumber={box.padding}
                    handleNumberChange={(val) =>
                      updateBox(index, "padding", val)
                    }
                    min={0}
                    max={100}
                  ></NumberInput>
                  <Label
                    style={{
                      fontWeight: "400",
                      width: "fit-content",
                      fontSize: ".8em",
                      whiteSpace: "nowrap",
                      padding: "0em",
                      margin: "0em",
                    }}
                  >
                    Box Padding
                  </Label>
                </div>
                {!box.stickLeft && (
                  <div className={styles.setting2}>
                    <div
                      style={{
                        // border: "2px solid gray",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // width: "50px",
                        height: "fit-content",
                        borderRadius: "7.5px",
                        marginLeft: "10px",
                      }}
                    >
                      <ToggleSwitch
                        startChecked={box.stickRight}
                        handleCheck={(val) =>
                          updateBox(index, "stickRight", val)
                        }
                      />
                    </div>
                    <Label
                      style={{
                        fontWeight: "400",
                        width: "fit-content",
                        fontSize: ".8em",
                        whiteSpace: "nowrap",
                        padding: "0em",
                        margin: "0em",
                      }}
                    >
                      Stick Right
                    </Label>
                  </div>
                )}
                {box.stickRight && (
                  <div className={styles.setting2}>
                    <NumberInput
                      startNumber={box.marginRight ? box.marginRight : 45}
                      handleNumberChange={(val) =>
                        updateBox(index, "marginRight", val)
                      }
                      min={0}
                      max={100}
                    ></NumberInput>
                    <Label
                      italics
                      style={{
                        fontWeight: "400",
                        width: "fit-content",
                        fontSize: ".8em",
                        whiteSpace: "nowrap",
                        padding: "0em",
                        margin: "0em",
                      }}
                    >
                      Margin Right
                    </Label>
                  </div>
                )}
                {!box.stickRight && (
                  <div className={styles.setting2}>
                    <div
                      style={{
                        // border: "2px solid gray",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // width: "50px",
                        height: "fit-content",
                        borderRadius: "7.5px",
                        marginLeft: "10px",
                      }}
                    >
                      <ToggleSwitch
                        startChecked={box.stickLeft}
                        handleCheck={(val) =>
                          updateBox(index, "stickLeft", val)
                        }
                      />
                    </div>
                    <Label
                      italics
                      style={{
                        fontWeight: "400",
                        width: "fit-content",
                        fontSize: ".8em",
                        whiteSpace: "nowrap",
                        padding: "0em",
                        margin: "0em",
                      }}
                    >
                      Stick Left
                    </Label>
                  </div>
                )}
                {box.stickLeft && (
                  <div className={styles.setting2}>
                    <NumberInput
                      startNumber={box.marginLeft ? box.marginLeft : 45}
                      handleNumberChange={(val) =>
                        updateBox(index, "marginLeft", val)
                      }
                      min={0}
                      max={100}
                    ></NumberInput>
                    <Label
                      style={{
                        fontWeight: "400",
                        width: "fit-content",
                        fontSize: ".8em",
                        whiteSpace: "nowrap",
                        padding: "0em",
                        margin: "0em",
                      }}
                    >
                      Margin Left
                    </Label>
                  </div>
                )}

                <div className={styles.setting2}>
                  <div
                    style={{
                      // border: "2px solid gray",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      // width: "50px",
                      height: "fit-content",
                      borderRadius: "7.5px",
                      marginLeft: "10px",
                    }}
                  >
                    <ToggleSwitch
                      startChecked={box.hasBackground}
                      handleCheck={(val) =>
                        updateBox(index, "hasBackground", val)
                      }
                    />
                  </div>
                  <Label
                    style={{
                      fontWeight: "400",
                      width: "fit-content",
                      fontSize: ".8em",
                      whiteSpace: "nowrap",
                      padding: "0em",
                      margin: "0em",
                    }}
                  >
                    Has Background
                  </Label>
                </div>
                {box.hasBackground && (
                  <div className={styles.setting2}>
                    <div
                      style={{
                        border: "2px solid gray",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // width: "50px",
                        height: "fit-content",
                        borderRadius: "7.5px",
                        marginLeft: "26px",
                      }}
                    >
                      <ColorPicker
                        defaultColor={box.backgroundColor}
                        onChange={(val) =>
                          updateBox(index, "backgroundColor", val)
                        }
                      />
                    </div>
                    <Label
                      style={{
                        fontWeight: "400",
                        width: "fit-content",
                        fontSize: ".8em",
                        whiteSpace: "nowrap",
                        padding: "0em",
                        margin: "0em",
                      }}
                    >
                      Background Color
                    </Label>
                  </div>
                )}
                <div className={styles.setting2}>
                  <div
                    style={{
                      // border: "2px solid gray",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      // width: "50px",
                      height: "fit-content",
                      borderRadius: "7.5px",
                      marginLeft: "10px",
                    }}
                  >
                    <ToggleSwitch
                      startChecked={box.boxShadow}
                      handleCheck={(val) => updateBox(index, "boxShadow", val)}
                    />
                  </div>
                  <Label
                    style={{
                      fontWeight: "400",
                      width: "fit-content",
                      fontSize: ".8em",
                      whiteSpace: "nowrap",
                      padding: "0em",
                      margin: "0em",
                    }}
                  >
                    Box Shadow
                  </Label>
                </div>
              </div>
            </div>
          ))}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button height={25} gray onClick={addBox}>
            + Text Box
          </Button>
        </div>
      </>
    );
  }
};
